/* Options:
Date: 2024-09-23 11:02:09
Version: 6.40
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://localhost:58990

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/



export class NodeParameterMap
{
    [key:string] : NodeParameter;

    public constructor(init?: Partial<NodeParameterMap>) { (Object as any).assign(this, init); }
}


export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IGet
{
}

export interface IPost
{
}

export interface IHasSessionId
{
    sessionId: string;
}

export interface IHasBearerToken
{
    bearerToken: string;
}

export interface IDelete
{
}

export interface IPatch
{
}

export class BillingItem
{
    public baseCost: number;
    public rawUnitMultiplier: number;
    public unitCost: number;
    public allowance: number;

    public constructor(init?: Partial<BillingItem>) { (Object as any).assign(this, init); }
}

export class BillingSettings
{
    public base: BillingItem;
    public localNumbers: BillingItem;
    public tollFreeNumbers: BillingItem;
    public inboundVoiceCalls: BillingItem;
    public outboundVoiceCalls: BillingItem;
    public inboundFaxes: BillingItem;
    public outboundFaxes: BillingItem;
    public inboundSmsMessages: BillingItem;
    public outboundSmsMessages: BillingItem;

    public constructor(init?: Partial<BillingSettings>) { (Object as any).assign(this, init); }
}

export enum SortOrders
{
    Ascend = 'Ascend',
    Descend = 'Descend',
}

export class ListRequest<T> implements IGet
{
    /**
    * The page of data to retrieve
    */
    // @ApiMember(Description="The page of data to retrieve")
    public page: number;

    /**
    * If you want all objects to be returned. This should be used with care
    */
    // @ApiMember(Description="If you want all objects to be returned. This should be used with care")
    public all: boolean;

    /**
    * The number per page to retrieve
    */
    // @ApiMember(Description="The number per page to retrieve")
    public countPerPage: number;

    /**
    * Specific IDs
    */
    // @ApiMember(Description="Specific IDs")
    public specificIds: string[];

    /**
    * Specify a sort field
    */
    // @ApiMember(Description="Specify a sort field")
    public sortField: string;

    /**
    * Specify a sort order
    */
    // @ApiMember(Description="Specify a sort order")
    public sortOrder: SortOrders;

    /**
    * Disables total / page counts - improves performance. Returns only data. If there is no more data, Items will be empty array
    */
    // @ApiMember(Description="Disables total / page counts - improves performance. Returns only data. If there is no more data, Items will be empty array")
    public simplifiedPaging: boolean;

    public constructor(init?: Partial<ListRequest<T>>) { (Object as any).assign(this, init); }
}

export enum ActionUrlHttpMethods
{
    GET = 'GET',
    POST = 'POST',
}

export class Struct
{

    public constructor(init?: Partial<Struct>) { (Object as any).assign(this, init); }
}

export class Value
{
    public boolValue?: boolean;
    public stringValue: string;
    public numberValue?: number;
    public listValue: Struct[];
    public structValue: Struct;

    public constructor(init?: Partial<Value>) { (Object as any).assign(this, init); }
}

export enum FileTypes
{
    Upload = 'Upload',
    VoiceMessage = 'VoiceMessage',
    CallRecording = 'CallRecording',
    Fax = 'Fax',
    Attachment = 'Attachment',
    FaxOutgoing = 'FaxOutgoing',
}

export enum DeviceTypes
{
    Web = 'Web',
    iOS = 'iOS',
    Android = 'Android',
}

export class AppSettings
{
    public enablePhoneNumberManagement: boolean;
    public enableDeviceManagement: boolean;
    public enableDialer: boolean;
    public enableCallHistory: boolean;
    public enableAssistants: boolean;
    public showFileNameInMessageCenter: boolean;
    public chakraTheme: string;
    public customCss: string;
    public pageTitle: string;
    public stringMappings: string;
    public logoutUrl: string;
    public portMyNumberUrl: string;

    public constructor(init?: Partial<AppSettings>) { (Object as any).assign(this, init); }
}

export enum UserModes
{
    SoftPhone = 'SoftPhone',
    Sip = 'Sip',
    Flow = 'Flow',
    DataOnly = 'DataOnly',
    ThirdParty = 'ThirdParty',
}

export enum EndpointFlowSchedules
{
    Always = 'Always',
    Scheduled = 'Scheduled',
    Simple = 'Simple',
}

export class ScheduledFlow
{
    public stateName: string;
    public flowId: string;
    public flowParams: Struct;

    public constructor(init?: Partial<ScheduledFlow>) { (Object as any).assign(this, init); }
}

export enum TwilioSipRegions
{
    NorthAmericaVirginia = 'NorthAmericaVirginia',
    NorthAmericaOregon = 'NorthAmericaOregon',
    EuropeIreland = 'EuropeIreland',
    EuropeFrankfurt = 'EuropeFrankfurt',
    AsiaPacificSingapore = 'AsiaPacificSingapore',
    AsiaPacificTokyo = 'AsiaPacificTokyo',
    AsiaPacificSydney = 'AsiaPacificSydney',
    SouthAmericaSanPaolo = 'SouthAmericaSanPaolo',
}

export enum UserManagerRoles
{
    None = 'None',
    Manager = 'Manager',
    VoicemailAndCallHistory = 'VoicemailAndCallHistory',
    Custom = 'Custom',
}

export enum DashboardPermissions
{
    ViewFiles = 'ViewFiles',
    ViewNotifications = 'ViewNotifications',
    ViewSessions = 'ViewSessions',
    ViewEndpoints = 'ViewEndpoints',
    ViewReports = 'ViewReports',
    ViewCustomers = 'ViewCustomers',
    ViewFlows = 'ViewFlows',
}

export enum UserDataFieldModes
{
    Hidden = 'Hidden',
    ReadOnly = 'ReadOnly',
    ReadWrite = 'ReadWrite',
}

export enum CustomerVisibility
{
    None = 'None',
    CurrentCustomer = 'CurrentCustomer',
    CurrentAndChildCustomers = 'CurrentAndChildCustomers',
}

export class EndpointActionUrl
{
    public id: string;
    public url: string;
    public method: ActionUrlHttpMethods;

    public constructor(init?: Partial<EndpointActionUrl>) { (Object as any).assign(this, init); }
}

export enum ListUpdateModes
{
    Replace = 'Replace',
    AddOrUpdate = 'AddOrUpdate',
    Remove = 'Remove',
}

export class EndpointContact
{
    public id: string;
    public displayName: string;
    public address: string;

    public constructor(init?: Partial<EndpointContact>) { (Object as any).assign(this, init); }
}

export enum ThirdPartyPhoneSystemTypes
{
    Demo = 'Demo',
    Sip = 'Sip',
}

export enum TransportTypes
{
    UDP = 'UDP',
    TLS = 'TLS',
    TCP = 'TCP',
    PERS = 'PERS',
}

export enum AudioCodecTypes
{
    PCMU = 0,
    GSM = 3,
    PCMA = 8,
    G722 = 9,
    G729 = 18,
    ILBC = 97,
    AMR = 98,
    AMRWB = 99,
    SPEEX = 100,
    DTMF = 101,
    SPEEXWB = 102,
    ISACWB = 103,
    ISACSWB = 104,
    OPUS = 105,
    G7221 = 121,
    NONE = -1,
}

export enum DtmfMethods
{
    RFC2833 = 'RFC2833',
    INFO = 'INFO',
}

export class ThirdPartySipAccountSettings
{
    public number: string;
    public agent: string;
    public authName: string;
    public userName: string;
    public displayName: string;
    public password: string;
    public userDomain: string;
    public registrationExpires: number;
    public transportType: TransportTypes;
    public localIP: string;
    public localPort: number;
    public sipServer: string;
    public sipServerPort: number;
    public outboundServer: string;
    public outboundServerPort: number;
    public stunServer: string;
    public stunPort: number;
    public audioPlaybackDeviceName: string;
    public audioRecordingDeviceName: string;
    public audioCodecs: AudioCodecTypes[];
    public dtmfMethod: DtmfMethods;

    public constructor(init?: Partial<ThirdPartySipAccountSettings>) { (Object as any).assign(this, init); }
}

export class ThirdPartySipSettings
{
    public accounts: ThirdPartySipAccountSettings[];

    public constructor(init?: Partial<ThirdPartySipSettings>) { (Object as any).assign(this, init); }
}

export class ThirdPartyDemoSettings
{
    public extension: string;

    public constructor(init?: Partial<ThirdPartyDemoSettings>) { (Object as any).assign(this, init); }
}

export class ThirdPartyPhoneSystemSettings
{
    public type: ThirdPartyPhoneSystemTypes;
    public sipSettings: ThirdPartySipSettings;
    public demoSettings: ThirdPartyDemoSettings;

    public constructor(init?: Partial<ThirdPartyPhoneSystemSettings>) { (Object as any).assign(this, init); }
}

export enum AssistantTunings
{
    Accuracy = 'Accuracy',
    Speed = 'Speed',
}

export class AssistantWord
{
    public word: string;
    public pronounced: string;

    public constructor(init?: Partial<AssistantWord>) { (Object as any).assign(this, init); }
}

export class AssistantLink
{
    public url: string;
    public description: string;

    public constructor(init?: Partial<AssistantLink>) { (Object as any).assign(this, init); }
}

export enum AssistantTransferTypes
{
    Blind = 'Blind',
    Supervised = 'Supervised',
    MessagesOnly = 'MessagesOnly',
}

export class AssistantTakeMessageField
{
    public name: string;
    public description: string;
    public required: boolean;

    public constructor(init?: Partial<AssistantTakeMessageField>) { (Object as any).assign(this, init); }
}

export class AssistantContact
{
    public name: string;
    public phoneNumber: string;
    public transferType: AssistantTransferTypes;
    public about: string;
    public emailAddress: string;
    public takeMessageFields: AssistantTakeMessageField[];

    public constructor(init?: Partial<AssistantContact>) { (Object as any).assign(this, init); }
}

export class AssistantIntegration
{
    public uri: string;
    public httpMethod: string;
    public authToken: string;

    public constructor(init?: Partial<AssistantIntegration>) { (Object as any).assign(this, init); }
}

export class AssistantSettings
{
    public companyName: string;
    public greeting: string;
    public companyInformation: string;
    public customPrompt: string;
    public voice: string;
    public voiceStyle: string;
    public tuning: AssistantTunings;
    public words: AssistantWord[];
    public links: AssistantLink[];
    public contacts: AssistantContact[];
    public integrations: AssistantIntegration[];

    public constructor(init?: Partial<AssistantSettings>) { (Object as any).assign(this, init); }
}

export enum EndpointTypes
{
    PhoneNumber = 'PhoneNumber',
    User = 'User',
    FaxNumber = 'FaxNumber',
    EmailAddress = 'EmailAddress',
    Unused_1 = 'Unused_1',
    Unused_2 = 'Unused_2',
    Unused_3 = 'Unused_3',
    Unused_4 = 'Unused_4',
    Unused_5 = 'Unused_5',
    Team = 'Team',
    Assistant = 'Assistant',
}

export enum ValueTypes
{
    NotSpecified = 'NotSpecified',
    String = 'String',
    Boolean = 'Boolean',
    Number = 'Number',
    List = 'List',
    Struct = 'Struct',
    Transition = 'Transition',
    Custom = 'Custom',
    Date = 'Date',
    AudioFile = 'AudioFile',
    TimeZoneId = 'TimeZoneId',
    PhoneNumber = 'PhoneNumber',
    User = 'User',
    Endpoint = 'Endpoint',
    Time = 'Time',
    File = 'File',
    FaxNumber = 'FaxNumber',
    EmailAccount = 'EmailAccount',
    Customer = 'Customer',
    Flow = 'Flow',
    Team = 'Team',
    FlowReference = 'FlowReference',
    Integration = 'Integration',
    Assistant = 'Assistant',
}

export enum FlowRoles
{
    UI = 'UI',
    Reference = 'Reference',
    PhoneNumberRouting = 'PhoneNumberRouting',
    UserDialOut = 'UserDialOut',
    FaxNumberRouting = 'FaxNumberRouting',
}

export enum FlowNodeCategories
{
    General = 'General',
    Voice = 'Voice',
    Logic = 'Logic',
    DateAndTime = 'DateAndTime',
    Audio = 'Audio',
    Messaging = 'Messaging',
    Assistant = 'Assistant',
    Flows = 'Flows',
    Fax = 'Fax',
    Network = 'Network',
    Cookies = 'Cookies',
    CallCenter = 'CallCenter',
}

export enum UIHints
{
    None = 'None',
    LargeText = 'LargeText',
    InlineForm = 'InlineForm',
    Password = 'Password',
    InlineStruct = 'InlineStruct',
}

export enum DataFieldUniqueness
{
    NotUnique = 'NotUnique',
    Unique = 'Unique',
    UniqueToCustomer = 'UniqueToCustomer',
}

export class DataField
{
    public id: string;
    public name: string;
    public type: ValueTypes;
    public uiHint: UIHints;
    public uiTab: string;
    public isAsync: boolean;
    public disableBinding: boolean;
    public structType: DataType;
    public listType: DataType;
    public description: string;
    public possibleValues: string[];
    public isOutput: boolean;
    public customFieldValuesUrl: string;
    public defaultValue: Value;
    public transitionNameFormat: string;
    public uniqueness: DataFieldUniqueness;
    public voiceOnly: boolean;
    public conditionalVisibilityField: string;
    public conditionalVisibilityValue: string;
    public noEvalTemplate: boolean;
    public userMode: UserDataFieldModes;
    public anyValueType: boolean;

    public constructor(init?: Partial<DataField>) { (Object as any).assign(this, init); }
}

export class DataType
{
    public typeName: string;
    public fields: DataField[];

    public constructor(init?: Partial<DataType>) { (Object as any).assign(this, init); }
}

export enum FlowChannels
{
    Voice = 'Voice',
    Chat = 'Chat',
    Fax = 'Fax',
}

export class FlowNodeSpec
{
    /**
    * The name of this node
    */
    // @ApiMember(Description="The name of this node")
    public name: string;

    /**
    * A description of this node
    */
    // @ApiMember(Description="A description of this node")
    public description: string;

    /**
    * Icon class for this node (FontAwesome)
    */
    // @ApiMember(Description="Icon class for this node (FontAwesome)")
    public iconClass: string;

    /**
    * The type name for this node
    */
    // @ApiMember(Description="The type name for this node")
    public typeName: string;

    /**
    * The category this node should be grouped under
    */
    // @ApiMember(Description="The category this node should be grouped under")
    public category: FlowNodeCategories;

    /**
    * The URL where this node is located
    */
    // @ApiMember(Description="The URL where this node is located")
    public url: string;

    /**
    * The data type for this node
    */
    // @ApiMember(Description="The data type for this node")
    public dataType: DataType;

    /**
    * URL for documentation for this node
    */
    // @ApiMember(Description="URL for documentation for this node")
    public documentationUrl: string;

    /**
    * The channels that this node is restricted to
    */
    // @ApiMember(Description="The channels that this node is restricted to")
    public restrictToChannels: FlowChannels[];

    public constructor(init?: Partial<FlowNodeSpec>) { (Object as any).assign(this, init); }
}

export class FlowNodeUI
{
    /**
    * The X position of the node
    */
    // @ApiMember(Description="The X position of the node")
    public x: number;

    /**
    * The Y position of the node
    */
    // @ApiMember(Description="The Y position of the node")
    public y: number;

    /**
    * Notes for this node
    */
    // @ApiMember(Description="Notes for this node")
    public notes: string;

    public constructor(init?: Partial<FlowNodeUI>) { (Object as any).assign(this, init); }
}

export class FlowNode
{
    /**
    * The ID of this node. Must be unique within the flow but can be anything
    */
    // @ApiMember(Description="The ID of this node. Must be unique within the flow but can be anything")
    public id: string;

    /**
    * Is this the starting node for the flow. Only one node can have this set
    */
    // @ApiMember(Description="Is this the starting node for the flow. Only one node can have this set")
    public isStartNode: boolean;

    /**
    * The name of the node, descriptive to be used as a reminder in the GUI
    */
    // @ApiMember(Description="The name of the node, descriptive to be used as a reminder in the GUI")
    public name: string;

    /**
    * The specification for the node
    */
    // @ApiMember(Description="The specification for the node")
    public spec: FlowNodeSpec;

    /**
    * The UI data for the node
    */
    // @ApiMember(Description="The UI data for the node")
    public ui: FlowNodeUI;

    /**
    * The data for this node. These will be POST'd to the endpoint when it is called.
    */
    // @ApiMember(Description="The data for this node. These will be POST'd to the endpoint when it is called.")
    public parameters: NodeParameterMap;

    public constructor(init?: Partial<FlowNode>) { (Object as any).assign(this, init); }
}

export class FlowExit
{
    /**
    * The unique ID for this exit
    */
    // @ApiMember(Description="The unique ID for this exit")
    public id: string;

    /**
    * The name of this exit
    */
    // @ApiMember(Description="The name of this exit")
    public name: string;

    /**
    * The UI for the exit
    */
    // @ApiMember(Description="The UI for the exit")
    public ui: FlowNodeUI;

    public constructor(init?: Partial<FlowExit>) { (Object as any).assign(this, init); }
}

export class FlowParameter extends DataField
{
    public isPublic: boolean;
    public isKnob: boolean;

    public constructor(init?: Partial<FlowParameter>) { super(init); (Object as any).assign(this, init); }
}

export class FlowUI
{
    public selectedNode: string;
    public canvasX: number;
    public canvasY: number;
    public canvasZoom: number;

    public constructor(init?: Partial<FlowUI>) { (Object as any).assign(this, init); }
}

export class TwilioRequestBase implements IPost
{
    public callSid: string;
    public accountSid: string;
    public from: string;
    public to: string;
    public callStatus: string;
    public apiVersion: string;
    public direction: string;
    public forwardedFrom: string;
    public callerName: string;
    public parentCallSid: string;
    public stirVerstat: string;
    public called: string;
    public calledZip: string;
    public calledCity: string;
    public calledState: string;
    public calledCountry: string;
    public caller: string;
    public callerCity: string;
    public callerState: string;
    public callerZip: string;
    public callerCountry: string;
    public fromCity: string;
    public fromState: string;
    public fromZip: string;
    public fromCountry: string;
    public toCity: string;
    public toState: string;
    public toZip: string;
    public toCountry: string;
    public messageSid: string;
    public smsId: string;
    public messagingServiceSid: string;
    public body: string;
    public numMedia: number;
    public mediaContentType0: string;
    public mediaUrl0: string;
    public mediaContentType1: string;
    public mediaUrl1: string;
    public mediaContentType2: string;
    public mediaUrl2: string;
    public index: number;
    public instanceSid: string;
    public eventType: string;
    public channelSid: string;
    public attributes: string;
    public clientIdentity: string;
    public mediaContentType: string;
    public mediaSid: string;
    public identity: string;
    public digits: string;
    public speechResult: string;
    public confidence: number;
    public callDuration: number;
    public recordingUrl: string;
    public recordingSid: string;
    public recordingDuration: number;
    public recordingStatus: string;
    public transcriptionSid: string;
    public transcriptionText: string;
    public transcriptionUrl: string;
    public transcriptionStatus: string;
    public dialCallStatus: string;
    public dialCallSid: string;
    public dialCallDuration: number;
    public dialBridged: boolean;
    public queueResult: string;
    public queueSid: string;
    public queueTime: number;
    public conferenceSid: string;
    public friendlyName: string;
    public statusCallbackEvent: string;
    public muted: boolean;
    public hold: boolean;
    public endConferenceOnExit: boolean;
    public startConferenceOnEnter: boolean;
    public eventName: string;
    public assistantSid: string;
    public dialogueSid: string;
    public userIdentifier: string;
    public currentInput: string;
    public currentTask: string;
    public dialoguePayloadUrl: string;
    public memory: string;
    public channel: string;
    public faxSid: string;
    public faxStatus: string;
    public numPages: number;
    public mediaUrl: string;
    public errorCode: string;
    public errorMessage: string;

    public constructor(init?: Partial<TwilioRequestBase>) { (Object as any).assign(this, init); }
}

export enum IntegrationTypes
{
    HostedSuite = 'HostedSuite',
    OfficeRnd = 'OfficeRnd',
    Zoho = 'Zoho',
}

export enum NotificationTypes
{
    Email = 'Email',
    Sms = 'Sms',
    Push = 'Push',
    IncomingCall = 'IncomingCall',
    OutgoingCall = 'OutgoingCall',
}

export enum PushNotificationTypes
{
    SessionDisconnected = 'SessionDisconnected',
    SessionInvite = 'SessionInvite',
    ChatMessage = 'ChatMessage',
    SessionInactivated = 'SessionInactivated',
    AgentStateChanged = 'AgentStateChanged',
    SessionHeld = 'SessionHeld',
    SessionAnswered = 'SessionAnswered',
    ActiveSessionsChanged = 'ActiveSessionsChanged',
    Ping = 'Ping',
    SessionScreenChanged = 'SessionScreenChanged',
    Toast = 'Toast',
    SessionInfoChanged = 'SessionInfoChanged',
    MessageReceived = 'MessageReceived',
    SessionHolding = 'SessionHolding',
}

export enum AgentStates
{
    Unknown = 'Unknown',
    Ready = 'Ready',
    NotReady = 'NotReady',
    LoggedOut = 'LoggedOut',
    WrapUp = 'WrapUp',
    Outgoing = 'Outgoing',
    Other = 'Other',
}

export enum AgentStateReasons
{
    Unknown = 'Unknown',
    SetByUser = 'SetByUser',
    MissedCall = 'MissedCall',
    SetBySystem = 'SetBySystem',
}

export class PushNotification
{
    public type: PushNotificationTypes;
    public channel: FlowChannels;
    public endpointId: string;
    public endpointUserName: string;
    public sessionId: string;
    public sender: string;
    public title: string;
    public body: string;
    public badge?: number;
    public attachmentUri: string;
    public attachmentContentType: string;
    public agentState: AgentStates;
    public agentStateReason: AgentStateReasons;

    public constructor(init?: Partial<PushNotification>) { (Object as any).assign(this, init); }
}

export enum ValueSources
{
    Value = 'Value',
    Flow = 'Flow',
    System = 'System',
    Customer = 'Customer',
    Session = 'Session',
    Endpoint = 'Endpoint',
    Expression = 'Expression',
    User = 'User',
}

export class NodeParameter
{
    public id: string;
    public type: ValueTypes;
    public source: ValueSources;
    public isAsync: boolean;
    public referenceId: string;
    public value: Value;
    public noEvalTemplate: boolean;
    public listParameters: NodeParameterMap[];
    public structParameters: NodeParameterMap;
    public isOutput: boolean;
    public expression: string;
    public listType: DataType;

    public constructor(init?: Partial<NodeParameter>) { (Object as any).assign(this, init); }
}

export class HostedSuiteCompletedFormField
{
    public name: string;
    public values: string[];

    public constructor(init?: Partial<HostedSuiteCompletedFormField>) { (Object as any).assign(this, init); }
}

export class HostedSuiteCompletedForm
{
    public id: string;
    public name: string;
    public dateCreated: string;
    public dateCompleted: string;
    public formId: string;
    public formName: string;
    public clientId: string;
    public clientName: string;
    public contactId: string;
    public contactName: string;
    public contactsIds: string[];
    public contactsNames: string[];
    public emailSubject: string;
    public callerNumber: string;
    public fields: HostedSuiteCompletedFormField[];

    public constructor(init?: Partial<HostedSuiteCompletedForm>) { (Object as any).assign(this, init); }
}

export class ReportRequest implements IPost
{
    /**
    * The account ID this report should be run for
    */
    // @ApiMember(Description="The account ID this report should be run for")
    public accountId: string;

    /**
    * The email address to notify after the report is run
    */
    // @ApiMember(Description="The email address to notify after the report is run")
    public emailAddressToNotify: string;

    public constructor(init?: Partial<ReportRequest>) { (Object as any).assign(this, init); }
}

export enum SessionDirections
{
    Incoming = 'Incoming',
    Outgoing = 'Outgoing',
}

export enum SessionCallState
{
    Disconnected = 'Disconnected',
    Ringing = 'Ringing',
    Connected = 'Connected',
    Hold = 'Hold',
    Passive = 'Passive',
}

export enum SessionHoldReasons
{
    None = 'None',
    Transferring = 'Transferring',
}

export enum SessionQueueStates
{
    None = 'None',
    Queued = 'Queued',
    Ringing = 'Ringing',
    Connected = 'Connected',
    Hold = 'Hold',
    Disconnected = 'Disconnected',
}

export class EmailAccount
{
    public id: string;
    public server: string;
    public userName: string;
    public port: number;
    public emailAddress: string;
    public displayName: string;
    public password: string;

    public constructor(init?: Partial<EmailAccount>) { (Object as any).assign(this, init); }
}

export class SystemSettingsField extends DataField
{
    public value: Value;

    public constructor(init?: Partial<SystemSettingsField>) { super(init); (Object as any).assign(this, init); }
}

export class CustomerDataField extends DataField
{
    public showInSearch: boolean;

    public constructor(init?: Partial<CustomerDataField>) { super(init); (Object as any).assign(this, init); }
}

export class EndpointDataField extends DataField
{
    public showInSearch: boolean;
    public showInLists: boolean;
    public endpointType?: EndpointTypes;
    public isCallerId: boolean;
    public isKnob: boolean;

    public constructor(init?: Partial<EndpointDataField>) { super(init); (Object as any).assign(this, init); }
}

export enum TagColors
{
    Magenta = 'Magenta',
    Red = 'Red',
    Volcano = 'Volcano',
    Orange = 'Orange',
    Gold = 'Gold',
    Lime = 'Lime',
    Green = 'Green',
    Cyan = 'Cyan',
    Blue = 'Blue',
    GeekBlue = 'GeekBlue',
    Purple = 'Purple',
}

export class Tag
{
    public id: string;
    public name: string;
    public color: TagColors;

    public constructor(init?: Partial<Tag>) { (Object as any).assign(this, init); }
}

export class EntityInfo
{
    /**
    * The ID of the object
    */
    // @ApiMember(Description="The ID of the object")
    public id: string;

    /**
    * The date the object was created
    */
    // @ApiMember(Description="The date the object was created")
    public dateCreated: string;

    /**
    * The date the object was last modified
    */
    // @ApiMember(Description="The date the object was last modified")
    public dateLastModified: string;

    /**
    * The user that created this object
    */
    // @ApiMember(Description="The user that created this object")
    public createdBy: string;

    /**
    * The user that last modified this object
    */
    // @ApiMember(Description="The user that last modified this object")
    public lastModifiedBy: string;

    public constructor(init?: Partial<EntityInfo>) { (Object as any).assign(this, init); }
}

export class CustomerBreadcrumb
{
    public id: string;
    public name: string;

    public constructor(init?: Partial<CustomerBreadcrumb>) { (Object as any).assign(this, init); }
}

export class LogEntryInfo extends EntityInfo
{
    /**
    * The account ID this endpoint is associated with
    */
    // @ApiMember(Description="The account ID this endpoint is associated with")
    public accountId: string;

    /**
    * The name of the account this endpoint is associated with
    */
    // @ApiMember(Description="The name of the account this endpoint is associated with")
    public accountName: string;

    /**
    * The ID of the customer this endpoint is associated with
    */
    // @ApiMember(Description="The ID of the customer this endpoint is associated with")
    public customerId: string;

    /**
    * The name of the customer this endpoint is associated with
    */
    // @ApiMember(Description="The name of the customer this endpoint is associated with")
    public customerName: string;

    /**
    * The breadcrumb to the customer for this endpoint
    */
    // @ApiMember(Description="The breadcrumb to the customer for this endpoint")
    public customerBreadcrumb: CustomerBreadcrumb[];

    public userName: string;
    public description: string;

    public constructor(init?: Partial<LogEntryInfo>) { super(init); (Object as any).assign(this, init); }
}

export enum QueueWaitMusicModes
{
    Audio = 'Audio',
    Text = 'Text',
}

export enum SayVoices
{
    Man = 'Man',
    Woman = 'Woman',
    Polly_Mads_Danish_Male = 'Polly_Mads_Danish_Male',
    Polly_Naja_Danish_Female = 'Polly_Naja_Danish_Female',
    Polly_Lotte_Dutch_Female = 'Polly_Lotte_Dutch_Female',
    Polly_Ruben_Dutch_Male = 'Polly_Ruben_Dutch_Male',
    Polly_Nicole_Australian_Female = 'Polly_Nicole_Australian_Female',
    Polly_Russell_Australian_Male = 'Polly_Russell_Australian_Male',
    Polly_Amy_British_Female = 'Polly_Amy_British_Female',
    Polly_Brian_British_Male = 'Polly_Brian_British_Male',
    Polly_Emma_British_Female = 'Polly_Emma_British_Female',
    Polly_Raveena_Indian_Female = 'Polly_Raveena_Indian_Female',
    Polly_Ivy_US_Female = 'Polly_Ivy_US_Female',
    Polly_Joanna_US_Female = 'Polly_Joanna_US_Female',
    Polly_Joey_US_Male = 'Polly_Joey_US_Male',
    Polly_Justin_US_Male = 'Polly_Justin_US_Male',
    Polly_Kendra_US_Female = 'Polly_Kendra_US_Female',
    Polly_Kimberly_US_Female = 'Polly_Kimberly_US_Female',
    Polly_Matthew_US_Male = 'Polly_Matthew_US_Male',
    Polly_Salli_US_Female = 'Polly_Salli_US_Female',
    Polly_Geraint_Welsh_Male = 'Polly_Geraint_Welsh_Male',
    Polly_Celine_French_Female = 'Polly_Celine_French_Female',
    Polly_Mathieu_French_Male = 'Polly_Mathieu_French_Male',
    Polly_Chantal_French_Canadian_Female = 'Polly_Chantal_French_Canadian_Female',
    Polly_Hans_German_Male = 'Polly_Hans_German_Male',
    Polly_Marlene_German_Female = 'Polly_Marlene_German_Female',
    Polly_Vicki_German_Female = 'Polly_Vicki_German_Female',
    Polly_Dora_Iceland_Female = 'Polly_Dora_Iceland_Female',
    Polly_Karl_Iceland_Male = 'Polly_Karl_Iceland_Male',
    Polly_Carla_Italian_Female = 'Polly_Carla_Italian_Female',
    Polly_Giorgio_Italian_Male = 'Polly_Giorgio_Italian_Male',
    Polly_Mizuki_Japanese_Female = 'Polly_Mizuki_Japanese_Female',
    Polly_Takumi_Japanese_Male = 'Polly_Takumi_Japanese_Male',
    Polly_Liv_Norwegian_Female = 'Polly_Liv_Norwegian_Female',
    Polly_Jacek_Polish_Male = 'Polly_Jacek_Polish_Male',
    Polly_Jan_Polish_Female = 'Polly_Jan_Polish_Female',
    Polly_Ewa_Polish_Female = 'Polly_Ewa_Polish_Female',
    Polly_Maja_Polish_Female = 'Polly_Maja_Polish_Female',
    Polly_Ricardo_Portuguese_Male = 'Polly_Ricardo_Portuguese_Male',
    Polly_Vitoria_Portuguese_Female = 'Polly_Vitoria_Portuguese_Female',
    Polly_Cristiano_Portuguese_Male = 'Polly_Cristiano_Portuguese_Male',
    Polly_Inese_Portuguese_Female = 'Polly_Inese_Portuguese_Female',
    Polly_Carmen_Romanian_Female = 'Polly_Carmen_Romanian_Female',
    Polly_Maxim_Russian_Male = 'Polly_Maxim_Russian_Male',
    Polly_Tatyana_Russian_Female = 'Polly_Tatyana_Russian_Female',
    Polly_Conchita_Spanish_Female = 'Polly_Conchita_Spanish_Female',
    Polly_Enrique_Spanish_Male = 'Polly_Enrique_Spanish_Male',
    Polly_Miguel_Latin_American_Male = 'Polly_Miguel_Latin_American_Male',
    Polly_Penelope_Latina_American_Female = 'Polly_Penelope_Latina_American_Female',
    Polly_Astrid_Swedish_Female = 'Polly_Astrid_Swedish_Female',
    Polly_Filiz_Turkish_Male = 'Polly_Filiz_Turkish_Male',
    Polly_Gwyneth_Welsh_Female = 'Polly_Gwyneth_Welsh_Female',
}

export class QueueWaitMusic
{
    public mode: QueueWaitMusicModes;
    public audioFile: string;
    public text: string;
    public textVoice: SayVoices;
    public numTimes: number;

    public constructor(init?: Partial<QueueWaitMusic>) { (Object as any).assign(this, init); }
}

export enum AppContactTypes
{
    User = 'User',
    Team = 'Team',
    Contact = 'Contact',
}

export class AppContact
{
    public displayName: string;
    public address: string;
    public type: AppContactTypes;
    public customerName: string;
    public canCall: boolean;
    public canChat: boolean;

    public constructor(init?: Partial<AppContact>) { (Object as any).assign(this, init); }
}

export enum SettingsObjectTypes
{
    Endpoint = 'Endpoint',
    Customer = 'Customer',
}

export class SettingsPossibleValue
{
    public displayName: string;
    public value: Value;

    public constructor(init?: Partial<SettingsPossibleValue>) { (Object as any).assign(this, init); }
}

export class SettingsField
{
    public name: string;
    public displayName: string;
    public type: ValueTypes;
    public value: Value;
    public readOnly: boolean;
    public possibleValues: SettingsPossibleValue[];
    public dataField: DataField;

    public constructor(init?: Partial<SettingsField>) { (Object as any).assign(this, init); }
}

// @Flags()
export enum CacheControl
{
    None = 0,
    Public = 1,
    Private = 2,
    MustRevalidate = 4,
    NoCache = 8,
    NoStore = 16,
    NoTransform = 32,
    ProxyRevalidate = 64,
}

export interface IContentTypeWriter
{
}

export class BillingCustomerInfo
{
    public customerId: string;
    public internationalCosts: number;
    public hasCallRecording: boolean;

    public constructor(init?: Partial<BillingCustomerInfo>) { (Object as any).assign(this, init); }
}

export class IntegrationData
{
    public thirdPartyId: string;

    public constructor(init?: Partial<IntegrationData>) { (Object as any).assign(this, init); }
}

export class Dictionary<T> { [Key: string]: T; }

export class EntityIntegrationData extends Dictionary<IntegrationData>
{

    public constructor(init?: Partial<EntityIntegrationData>) { super(); (Object as any).assign(this, init); }
}

export enum SimpleSchedulingRuleTypes
{
    Always = 'Always',
    CustomerState = 'CustomerState',
    Time = 'Time',
}

export enum DayOfWeek
{
    Sunday = 'Sunday',
    Monday = 'Monday',
    Tuesday = 'Tuesday',
    Wednesday = 'Wednesday',
    Thursday = 'Thursday',
    Friday = 'Friday',
    Saturday = 'Saturday',
}

export class ScheduleDay
{
    public offset: number;
    public dayOfWeek: DayOfWeek;

    public constructor(init?: Partial<ScheduleDay>) { (Object as any).assign(this, init); }
}

export enum SchedulingRuleFrequency
{
    None = 'None',
    Secondly = 'Secondly',
    Minutely = 'Minutely',
    Hourly = 'Hourly',
    Daily = 'Daily',
    Weekly = 'Weekly',
    Monthly = 'Monthly',
    Yearly = 'Yearly',
}

export class SchedulingRule
{
    public id: string;
    public name: string;
    public priority: number;
    public state: string;
    public source: string;
    public condition: string;
    public simpleRuleType: SimpleSchedulingRuleTypes;
    public customerState: string;
    public flowId: string;
    public flowParams: Struct;
    public isAllDay: boolean;
    public startDate: string;
    public startTime: string;
    public endTime: string;
    public bySetPosition: number[];
    public byMonth: number[];
    public byWeekNo: number[];
    public byYearDay: number[];
    public byMonthDay: number[];
    public byDay: ScheduleDay[];
    public byHour: number[];
    public byMinute: number[];
    public interval: number;
    public count: number;
    public untilDate: string;
    public frequency: SchedulingRuleFrequency;

    public constructor(init?: Partial<SchedulingRule>) { (Object as any).assign(this, init); }
}

export class CallCenterDashboardAgent
{
    public id: string;
    public name: string;
    public queues: string[];
    public agentState: string;
    public callState: string;
    public signInTime: string;
    public signInDuration: string;
    public percentAvailable: string;
    public avgBusyIn: string;
    public avgWrapUp: string;
    public dateOfLastCall: string;

    public constructor(init?: Partial<CallCenterDashboardAgent>) { (Object as any).assign(this, init); }
}

export class CallCenterDashboardQueue
{
    public name: string;
    public numAgents: number;
    public numCallsQueued: number;
    public longestWaitingCall: string;
    public expectedWaitTime: string;
    public expectedHoldTime: string;
    public averageSpeedOfAnswer: string;
    public idleAgents: number;
    public unavailableAgents: number;

    public constructor(init?: Partial<CallCenterDashboardQueue>) { (Object as any).assign(this, init); }
}

export class CallCenterDashboardCall
{
    public id: string;
    public customer: string;
    public queue: string;
    public agent: string;
    public state: string;
    public duration: string;
    public hold: string;
    public extraInfo: string;

    public constructor(init?: Partial<CallCenterDashboardCall>) { (Object as any).assign(this, init); }
}

export class AlertInfo
{
    public sid: string;
    public text: string;
    public dateGenerated: string;
    public errorCode: string;
    public logLevel: string;
    public moreInfo: string;
    public requestVariables: string;
    public requestUrl: string;
    public requestMethod: string;

    public constructor(init?: Partial<AlertInfo>) { (Object as any).assign(this, init); }
}

export class CallInfo
{
    public accountSid: string;
    public annotation: string;
    public answeredBy: string;
    public callerName: string;
    public dateCreated: string;
    public dateUpdated: string;
    public direction: string;
    public duration: number;
    public endTime: string;
    public forwardedFrom: string;
    public from: string;
    public fromFormatted: string;
    public parentCallSid: string;
    public phoneNumberSid: string;
    public price: number;
    public priceUnit: string;
    public sid: string;
    public startTime: string;
    public status: string;
    public to: string;
    public toFormatted: string;
    public uri: string;

    public constructor(init?: Partial<CallInfo>) { (Object as any).assign(this, init); }
}

export class UsageRecordInfo
{
    public accountSid: string;
    public category: string;
    public count: number;
    public countUnit: string;
    public description: string;
    public endDate: string;
    public price: number;
    public priceUnit: string;
    public startDate: string;
    public usage: string;
    public usageUnit: string;
    public uri: string;

    public constructor(init?: Partial<UsageRecordInfo>) { (Object as any).assign(this, init); }
}

export class FindDuplicateEntry
{
    public fieldValue: string;
    public endpoints: string[];

    public constructor(init?: Partial<FindDuplicateEntry>) { (Object as any).assign(this, init); }
}

export class PhoneNumberInfo
{
    public phoneNumber: string;

    public constructor(init?: Partial<PhoneNumberInfo>) { (Object as any).assign(this, init); }
}

export class AutoCompleteValue
{
    /**
    * Display name
    */
    // @ApiMember(Description="Display name")
    public displayName: string;

    /**
    * Value
    */
    // @ApiMember(Description="Value")
    public value: string;

    public constructor(init?: Partial<AutoCompleteValue>) { (Object as any).assign(this, init); }
}

export class FieldValue
{
    public displayName: string;
    public value: string;

    public constructor(init?: Partial<FieldValue>) { (Object as any).assign(this, init); }
}

export class GetParameterField
{
    public fieldName: string;
    public valueType: ValueTypes;
    public field: DataField;

    public constructor(init?: Partial<GetParameterField>) { (Object as any).assign(this, init); }
}

export class CrmCustomerInfo
{
    public id: string;
    public displayName: string;

    public constructor(init?: Partial<CrmCustomerInfo>) { (Object as any).assign(this, init); }
}

export class CrmContactInfo
{
    public id: string;
    public displayName: string;

    public constructor(init?: Partial<CrmContactInfo>) { (Object as any).assign(this, init); }
}

export enum IntegrationStatuses
{
    NotConfigured = 'NotConfigured',
    Error = 'Error',
    OK = 'OK',
}

export enum IntegrationFeatures
{
    CrmSync = 'CrmSync',
    OAuth2 = 'OAuth2',
}

export enum MessageDirections
{
    Incoming = 'Incoming',
    Outgoing = 'Outgoing',
}

export class MessageInfo
{
    public id: string;
    public accountId: string;
    public customerId: string;
    public endpointId: string;
    public endpointDisplayName: string;
    public date: string;
    public direction: MessageDirections;
    public otherAddress: string;
    public sender: string;
    public text: string;
    public isUnread: boolean;

    public constructor(init?: Partial<MessageInfo>) { (Object as any).assign(this, init); }
}

export class ConversationInfo
{
    public id: string;
    public endpointId: string;
    public otherAddress: string;
    public mostRecentMessage: MessageInfo;

    public constructor(init?: Partial<ConversationInfo>) { (Object as any).assign(this, init); }
}

export class NotificationRecipientInfo
{
    /**
    * The address of the recipient
    */
    // @ApiMember(Description="The address of the recipient")
    public address: string;

    /**
    * Extra info about the recipient e.g. CC, BCC
    */
    // @ApiMember(Description="Extra info about the recipient e.g. CC, BCC")
    public extra: string;

    /**
    * The name of the recipient
    */
    // @ApiMember(Description="The name of the recipient")
    public name: string;

    public constructor(init?: Partial<NotificationRecipientInfo>) { (Object as any).assign(this, init); }
}

export class AppHomeCallMetric
{
    public metricKey: string;
    public total: number;
    public average: number;

    public constructor(init?: Partial<AppHomeCallMetric>) { (Object as any).assign(this, init); }
}

export class AppSearchPhoneNumberInfo
{
    public phoneNumber: string;

    public constructor(init?: Partial<AppSearchPhoneNumberInfo>) { (Object as any).assign(this, init); }
}

export class AvailableCountryInfo
{
    public code: string;
    public englishName: string;

    public constructor(init?: Partial<AvailableCountryInfo>) { (Object as any).assign(this, init); }
}

export class SystemTimeZoneInfo
{
    public id: string;
    public displayName: string;

    public constructor(init?: Partial<SystemTimeZoneInfo>) { (Object as any).assign(this, init); }
}

export enum ReportStatuses
{
    Queued = 'Queued',
    Running = 'Running',
    Completed = 'Completed',
    Error = 'Error',
}

export enum PresenceStatuses
{
    Free = 'Free',
    OnPhone = 'OnPhone',
}

export enum SessionDialState
{
    None = 'None',
    Active = 'Active',
}

export class SessionLogInfo
{
    public date: string;
    public message: string;

    public constructor(init?: Partial<SessionLogInfo>) { (Object as any).assign(this, init); }
}

export enum SessionMemberCallState
{
    None = 'None',
    Ringing = 'Ringing',
    Connected = 'Connected',
    Hold = 'Hold',
}

export enum SessionMemberRoles
{
    None = 'None',
    Caller = 'Caller',
    Agent = 'Agent',
    Transfer = 'Transfer',
}

export class SessionMemberInfo
{
    public identity: string;
    public endpointId: string;
    public displayName: string;
    public isOriginalMember: boolean;
    public avatarUrl: string;
    public applicationData: { [index: string]: string; };
    public callState: SessionMemberCallState;
    public role: SessionMemberRoles;
    public callSid: string;
    public muted: boolean;

    public constructor(init?: Partial<SessionMemberInfo>) { (Object as any).assign(this, init); }
}

export class ChatTranscriptMessage
{
    public date: string;
    public from: string;
    public body: string;
    public mediaUri: string;
    public mediaContentType: string;

    public constructor(init?: Partial<ChatTranscriptMessage>) { (Object as any).assign(this, init); }
}

export class DialingPermissionInfo
{
    public isoCode: string;
    public name: string;
    public lowRiskNumbersEnabled: boolean;
    public highRiskSpecialNumbersEnabled: boolean;
    public highRiskTollfraudNumbersEnabled: boolean;

    public constructor(init?: Partial<DialingPermissionInfo>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public fieldName: string;

    // @DataMember(Order=3)
    public message: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export class RegenerateAccountTokensResponse
{

    public constructor(init?: Partial<RegenerateAccountTokensResponse>) { (Object as any).assign(this, init); }
}

export class CheckAccountResponse
{
    public message: string;

    public constructor(init?: Partial<CheckAccountResponse>) { (Object as any).assign(this, init); }
}

export class AccountInfo extends EntityInfo
{
    /**
    * The name of this account
    */
    // @ApiMember(Description="The name of this account")
    public name: string;

    /**
    * The ID of this account's parent
    */
    // @ApiMember(Description="The ID of this account's parent")
    public parentAccountId: string;

    /**
    * The twilio account SID
    */
    // @ApiMember(Description="The twilio account SID")
    public twilioAccountSid: string;

    /**
    * The ancestors of this account. Useful for breadcrumbs
    */
    // @ApiMember(Description="The ancestors of this account. Useful for breadcrumbs")
    public ancestorIds: string[];

    /**
    * The max number of phone numbers this account can have
    */
    // @ApiMember(Description="The max number of phone numbers this account can have")
    public maxPhoneNumbers: number;

    /**
    * This account is BYOA
    */
    // @ApiMember(Description="This account is BYOA")
    public isBYOA: boolean;

    /**
    * TrustHub Profile Sid
    */
    // @ApiMember(Description="TrustHub Profile Sid")
    public trustHubProfileSid: string;

    /**
    * The ID of the logo file
    */
    // @ApiMember(Description="The ID of the logo file")
    public logoId: string;

    /**
    * The URI of the logo file
    */
    // @ApiMember(Description="The URI of the logo file")
    public logoUri: string;

    /**
    * The billing settings for this account
    */
    // @ApiMember(Description="The billing settings for this account")
    public billingSettings: BillingSettings;

    public constructor(init?: Partial<AccountInfo>) { super(init); (Object as any).assign(this, init); }
}

export class ListResponse<AccountInfo>
{
    /**
    * The items
    */
    // @ApiMember(Description="The items")
    public items: AccountInfo[];

    /**
    * The total number of items
    */
    // @ApiMember(Description="The total number of items")
    public totalCount: number;

    /**
    * The total number of pages
    */
    // @ApiMember(Description="The total number of pages")
    public totalPages: number;

    /**
    * Are there more pages of items? Used with simplified paging
    */
    // @ApiMember(Description="Are there more pages of items? Used with simplified paging")
    public hasMorePages: boolean;

    public constructor(init?: Partial<ListResponse<AccountInfo>>) { (Object as any).assign(this, init); }
}

export class GetMyContactsResponse
{
    public contacts: AppContact[];

    public constructor(init?: Partial<GetMyContactsResponse>) { (Object as any).assign(this, init); }
}

export class FileInfo extends EntityInfo
{
    /**
    * The type of file this is
    */
    // @ApiMember(Description="The type of file this is")
    public type: FileTypes;

    /**
    * The account ID this file is associated with
    */
    // @ApiMember(Description="The account ID this file is associated with")
    public accountId: string;

    /**
    * The name of the account this file is associated with
    */
    // @ApiMember(Description="The name of the account this file is associated with")
    public accountName: string;

    /**
    * The ID of the customer this file is associated with
    */
    // @ApiMember(Description="The ID of the customer this file is associated with")
    public customerId: string;

    /**
    * The name of the customer this file is associated with
    */
    // @ApiMember(Description="The name of the customer this file is associated with")
    public customerName: string;

    /**
    * The breadcrumb to the customer for this file
    */
    // @ApiMember(Description="The breadcrumb to the customer for this file")
    public customerBreadcrumb: CustomerBreadcrumb[];

    /**
    * The ID of the user this file is assocaited with
    */
    // @ApiMember(Description="The ID of the user this file is assocaited with")
    public userId: string;

    /**
    * The name of the user this file is associated with
    */
    // @ApiMember(Description="The name of the user this file is associated with")
    public userName: string;

    /**
    * The original file name for the file
    */
    // @ApiMember(Description="The original file name for the file")
    public fileName: string;

    /**
    * The URI of the file
    */
    // @ApiMember(Description="The URI of the file")
    public uri: string;

    /**
    * The Content type of the file
    */
    // @ApiMember(Description="The Content type of the file")
    public contentType: string;

    /**
    * The size of the file
    */
    // @ApiMember(Description="The size of the file")
    public contentLength: number;

    /**
    * The Twilio ID of the recording
    */
    // @ApiMember(Description="The Twilio ID of the recording")
    public recordingSid: string;

    /**
    * The duration of the recording in seconds
    */
    // @ApiMember(Description="The duration of the recording in seconds")
    public recordingDuration: number;

    /**
    * Who is the recording from?
    */
    // @ApiMember(Description="Who is the recording from?")
    public recordingFrom: string;

    /**
    * Transcription (if available)
    */
    // @ApiMember(Description="Transcription (if available)")
    public transcription: string;

    /**
    * From Address (e.g. caller ID) for incoming calls
    */
    // @ApiMember(Description="From Address (e.g. caller ID) for incoming calls")
    public fromAddress: string;

    /**
    * To Address (e.g. dialed number) for outgoing calls
    */
    // @ApiMember(Description="To Address (e.g. dialed number) for outgoing calls")
    public toAddress: string;

    public constructor(init?: Partial<FileInfo>) { super(init); (Object as any).assign(this, init); }
}

export class GetFileInfoResponse
{
    public uri: string;

    public constructor(init?: Partial<GetFileInfoResponse>) { (Object as any).assign(this, init); }
}

export class SettingsObject
{
    public id: string;
    public name: string;
    public description: string;
    public readOnly: boolean;
    public type: SettingsObjectTypes;
    public endpointType?: EndpointTypes;
    public fields: SettingsField[];

    public constructor(init?: Partial<SettingsObject>) { (Object as any).assign(this, init); }
}

export class ListSettingsObjectsResponse
{
    public settingsObjects: SettingsObject[];

    public constructor(init?: Partial<ListSettingsObjectsResponse>) { (Object as any).assign(this, init); }
}

export class GetMyFilesResponse
{
    public files: FileInfo[];

    public constructor(init?: Partial<GetMyFilesResponse>) { (Object as any).assign(this, init); }
}

export class DeviceTokenInfo
{
    public deviceType: DeviceTypes;
    public token: string;

    public constructor(init?: Partial<DeviceTokenInfo>) { (Object as any).assign(this, init); }
}

export class AppUserInfo
{
    /**
    * The user's first name
    */
    // @ApiMember(Description="The user's first name")
    public firstName: string;

    /**
    * The user's last name
    */
    // @ApiMember(Description="The user's last name")
    public lastName: string;

    /**
    * Shortcut to the user's full name
    */
    // @ApiMember(Description="Shortcut to the user's full name")
    public name: string;

    /**
    * The URL to the user's avatar
    */
    // @ApiMember(Description="The URL to the user's avatar")
    public avatarUrl: string;

    public constructor(init?: Partial<AppUserInfo>) { (Object as any).assign(this, init); }
}

export class AppConfig
{
    /**
    * The ID of this endpoint
    */
    // @ApiMember(Description="The ID of this endpoint")
    public endpointId: string;

    /**
    * The ID of the account
    */
    // @ApiMember(Description="The ID of the account")
    public accountId: string;

    /**
    * The customer ID associated with this user
    */
    // @ApiMember(Description="The customer ID associated with this user")
    public customerId: string;

    /**
    * The access token for use with Twilio Voice
    */
    // @ApiMember(Description="The access token for use with Twilio Voice")
    public accessToken: string;

    /**
    * The access token's identity
    */
    // @ApiMember(Description="The access token's identity")
    public identity: string;

    /**
    * The email address of the user
    */
    // @ApiMember(Description="The email address of the user")
    public emailAddress: string;

    /**
    * The user's information
    */
    // @ApiMember(Description="The user's information")
    public userInfo: AppUserInfo;

    /**
    * The agent state (for call center users)
    */
    // @ApiMember(Description="The agent state (for call center users)")
    public agentState: AgentStates;

    /**
    * The agent state reason
    */
    // @ApiMember(Description="The agent state reason")
    public agentStateReason: AgentStateReasons;

    /**
    * The tabs for the app
    */
    // @ApiMember(Description="The tabs for the app")
    public tabs: Object[];

    /**
    * The app settings
    */
    // @ApiMember(Description="The app settings")
    public appSettings: AppSettings;

    /**
    * The phone settings for third party connectivity
    */
    // @ApiMember(Description="The phone settings for third party connectivity")
    public thirdPartyPhoneSystemSettings: ThirdPartyPhoneSystemSettings;

    public constructor(init?: Partial<AppConfig>) { (Object as any).assign(this, init); }
}

export class RequestAuthTokenResult
{
    public authToken: string;

    public constructor(init?: Partial<RequestAuthTokenResult>) { (Object as any).assign(this, init); }
}

export class GetTwilioTokenResponse
{
    /**
    * The twilio token to use
    */
    // @ApiMember(Description="The twilio token to use")
    public token: string;

    public constructor(init?: Partial<GetTwilioTokenResponse>) { (Object as any).assign(this, init); }
}

export class UserInfo
{
    public id: string;
    public isAuthenticated: boolean;
    public firstName: string;
    public lastName: string;
    public name: string;
    public avatarUrl: string;
    public emailAddress: string;
    public roles: string[];
    public accountIds: string[];
    public accountNames: string[];
    public dashboardPermissions: DashboardPermissions[];

    public constructor(init?: Partial<UserInfo>) { (Object as any).assign(this, init); }
}

export class BillingInfo
{
    public customers: BillingCustomerInfo[];

    public constructor(init?: Partial<BillingInfo>) { (Object as any).assign(this, init); }
}

export class Schedule
{
    public timeZoneId: string;
    public inherit: boolean;
    public forceClosed: boolean;
    public rules: SchedulingRule[];
    public defaultState: string;

    public constructor(init?: Partial<Schedule>) { (Object as any).assign(this, init); }
}

export class CustomerInfo extends EntityInfo
{
    /**
    * The ID of the account associated with this customer
    */
    // @ApiMember(Description="The ID of the account associated with this customer")
    public accountId: string;

    /**
    * The parent customer ID for this customer
    */
    // @ApiMember(Description="The parent customer ID for this customer")
    public parentCustomerId: string;

    /**
    * The breadcrumb to this customer
    */
    // @ApiMember(Description="The breadcrumb to this customer")
    public breadcrumb: CustomerBreadcrumb[];

    /**
    * The name of the account associated with this customer
    */
    // @ApiMember(Description="The name of the account associated with this customer")
    public accountName: string;

    /**
    * Is this customer staging or production?
    */
    // @ApiMember(Description="Is this customer staging or production?")
    public isStaging: boolean;

    /**
    * The name of the company
    */
    // @ApiMember(Description="The name of the company")
    public name: string;

    /**
    * The reference ID for this company
    */
    // @ApiMember(Description="The reference ID for this company")
    public referenceId: string;

    /**
    * This customer's data values
    */
    // @ApiMember(Description="This customer's data values")
    public data: Struct;

    /**
    * The list of tags for this customer
    */
    // @ApiMember(Description="The list of tags for this customer")
    public tags: Tag[];

    /**
    * This customer's schedule
    */
    // @ApiMember(Description="This customer's schedule")
    public schedule: Schedule;

    /**
    * Integration data for this customer
    */
    // @ApiMember(Description="Integration data for this customer")
    public integrationData: EntityIntegrationData;

    /**
    * Override this customer's billing settings? Otherwise inherits from parent
    */
    // @ApiMember(Description="Override this customer's billing settings? Otherwise inherits from parent")
    public overrideBillingSettings: boolean;

    /**
    * Billing settings for this customer
    */
    // @ApiMember(Description="Billing settings for this customer")
    public billingSettings: BillingSettings;

    /**
    * Should this customer override the parent customer's app settings
    */
    // @ApiMember(Description="Should this customer override the parent customer's app settings")
    public overrideAppSettings: boolean;

    /**
    * App / Portal settings for this customer
    */
    // @ApiMember(Description="App / Portal settings for this customer")
    public appSettings: AppSettings;

    public constructor(init?: Partial<CustomerInfo>) { super(init); (Object as any).assign(this, init); }
}

export class TestScheduleResponse
{
    public stateName: string;
    public timeZoneId: string;

    public constructor(init?: Partial<TestScheduleResponse>) { (Object as any).assign(this, init); }
}

export class CallCenterDashboard
{
    public agents: CallCenterDashboardAgent[];
    public queues: CallCenterDashboardQueue[];
    public calls: CallCenterDashboardCall[];

    public constructor(init?: Partial<CallCenterDashboard>) { (Object as any).assign(this, init); }
}

export class GetActiveCallsResponse
{
    public calls: CallInfo[];

    public constructor(init?: Partial<GetActiveCallsResponse>) { (Object as any).assign(this, init); }
}

export class EndpointInfo extends EntityInfo
{
    /**
    * The account ID this endpoint is associated with
    */
    // @ApiMember(Description="The account ID this endpoint is associated with")
    public accountId: string;

    /**
    * The name of the account this endpoint is associated with
    */
    // @ApiMember(Description="The name of the account this endpoint is associated with")
    public accountName: string;

    /**
    * The ID of the customer this endpoint is associated with
    */
    // @ApiMember(Description="The ID of the customer this endpoint is associated with")
    public customerId: string;

    /**
    * The name of the customer this endpoint is associated with
    */
    // @ApiMember(Description="The name of the customer this endpoint is associated with")
    public customerName: string;

    /**
    * The third party reference ID for the endpoint
    */
    // @ApiMember(Description="The third party reference ID for the endpoint")
    public referenceId: string;

    /**
    * The breadcrumb to the customer for this endpoint
    */
    // @ApiMember(Description="The breadcrumb to the customer for this endpoint")
    public customerBreadcrumb: CustomerBreadcrumb[];

    /**
    * The display name of the endpoint
    */
    // @ApiMember(Description="The display name of the endpoint")
    public displayName: string;

    /**
    * The type of endpoint
    */
    // @ApiMember(Description="The type of endpoint")
    public type: EndpointTypes;

    /**
    * Extra info for this endpoint (typically to show in grid)
    */
    // @ApiMember(Description="Extra info for this endpoint (typically to show in grid)")
    public extraInformation: string;

    /**
    * The ID of the flow to use for voice
    */
    // @ApiMember(Description="The ID of the flow to use for voice")
    public flowId: string;

    /**
    * The name of the flow to use for voice
    */
    // @ApiMember(Description="The name of the flow to use for voice")
    public flowName: string;

    /**
    * The params for the voice flow
    */
    // @ApiMember(Description="The params for the voice flow")
    public flowParams: Struct;

    /**
    * Whether to use a single flow always or use scheduled flow system
    */
    // @ApiMember(Description="Whether to use a single flow always or use scheduled flow system")
    public flowSchedule: EndpointFlowSchedules;

    /**
    * This endpoint's schedule
    */
    // @ApiMember(Description="This endpoint's schedule")
    public schedule: Schedule;

    /**
    * The list of scheduled flows when using scheduling
    */
    // @ApiMember(Description="The list of scheduled flows when using scheduling")
    public scheduledFlows: ScheduledFlow[];

    /**
    * Disable SMS
    */
    // @ApiMember(Description="Disable SMS")
    public disableSms: boolean;

    /**
    * Set this to true to prevent Evo Voice from overriding the 10DLC / SMS settings for this number
    */
    // @ApiMember(Description="Set this to true to prevent Evo Voice from overriding the 10DLC / SMS settings for this number")
    public useExternal10DlcCampaign: boolean;

    /**
    * Is this a virtual phone number?
    */
    // @ApiMember(Description="Is this a virtual phone number?")
    public isVirtualPhoneNumber: boolean;

    /**
    * Is caller ID verified for this virtual number?
    */
    // @ApiMember(Description="Is caller ID verified for this virtual number?")
    public isCallerIdVerified: boolean;

    /**
    * The verification code for this number
    */
    // @ApiMember(Description="The verification code for this number")
    public callerIdVerificationCode: string;

    /**
    * The phone number
    */
    // @ApiMember(Description="The phone number")
    public phoneNumber: string;

    /**
    * The Sid of the phone number
    */
    // @ApiMember(Description="The Sid of the phone number")
    public phoneNumberSid: string;

    /**
    * The caller ID Name (CNAM) for the phone number
    */
    // @ApiMember(Description="The caller ID Name (CNAM) for the phone number")
    public callerIdName: string;

    /**
    * The address SID associated with the phone number
    */
    // @ApiMember(Description="The address SID associated with the phone number")
    public addressSid: string;

    /**
    * Do not touch this phone number - for BYOA accounts
    */
    // @ApiMember(Description="Do not touch this phone number - for BYOA accounts")
    public doNotTouchPhoneNumber: boolean;

    /**
    * Is this number enrolled in a 10DLC messaging service campaign
    */
    // @ApiMember(Description="Is this number enrolled in a 10DLC messaging service campaign")
    public isEnrolledIn10DlcService: boolean;

    /**
    * Whether we look up caller ID or not
    */
    // @ApiMember(Description="Whether we look up caller ID or not")
    public enableCallerIdLookup: boolean;

    /**
    * The email address of the user
    */
    // @ApiMember(Description="The email address of the user")
    public userEmailAddress: string;

    /**
    * The Twilio Region for the SIP endpoint
    */
    // @ApiMember(Description="The Twilio Region for the SIP endpoint")
    public sipRegion: TwilioSipRegions;

    /**
    * The Twilio Sid of the credentials for Sip
    */
    // @ApiMember(Description="The Twilio Sid of the credentials for Sip")
    public sipCredentialSid: string;

    /**
    * The Twilio SIP user name
    */
    // @ApiMember(Description="The Twilio SIP user name")
    public sipUserName: string;

    /**
    * The Twilio SIP password
    */
    // @ApiMember(Description="The Twilio SIP password")
    public sipPassword: string;

    /**
    * The SIP domain
    */
    // @ApiMember(Description="The SIP domain")
    public sipDomain: string;

    /**
    * Is emergency calling enabled on this number?
    */
    // @ApiMember(Description="Is emergency calling enabled on this number?")
    public enableEmergencyCalling: boolean;

    /**
    * The SID of the emergency address for this number
    */
    // @ApiMember(Description="The SID of the emergency address for this number")
    public emergencyAddressSid: string;

    /**
    * The ID of the phone number to use for emergency dialing
    */
    // @ApiMember(Description="The ID of the phone number to use for emergency dialing")
    public emergencyPhoneNumberId: string;

    /**
    * The current agent state of this user endpoint
    */
    // @ApiMember(Description="The current agent state of this user endpoint")
    public agentState: AgentStates;

    /**
    * The current agent state reason of this user endpoint
    */
    // @ApiMember(Description="The current agent state reason of this user endpoint")
    public agentStateReason: AgentStateReasons;

    /**
    * The mode for this user
    */
    // @ApiMember(Description="The mode for this user")
    public userMode: UserModes;

    /**
    * The ID of the file to use for voicemail greeting
    */
    // @ApiMember(Description="The ID of the file to use for voicemail greeting")
    public voicemailGreetingId: string;

    /**
    * The endpoint's data
    */
    // @ApiMember(Description="The endpoint's data")
    public data: Struct;

    /**
    * The email address for email endpoints
    */
    // @ApiMember(Description="The email address for email endpoints")
    public emailAddress: string;

    /**
    * The first name of the user (for user endpoints)
    */
    // @ApiMember(Description="The first name of the user (for user endpoints)")
    public userFirstName: string;

    /**
    * The last name of the user (for user endpoints)
    */
    // @ApiMember(Description="The last name of the user (for user endpoints)")
    public userLastName: string;

    /**
    * The URL of an image for this user's avatar
    */
    // @ApiMember(Description="The URL of an image for this user's avatar")
    public avatarUrl: string;

    /**
    * Does this user have manager role?
    */
    // @ApiMember(Description="Does this user have manager role?")
    public managerRole: UserManagerRoles;

    /**
    * The list of dashboard permissions for when the manager role is custom
    */
    // @ApiMember(Description="The list of dashboard permissions for when the manager role is custom")
    public dashboardPermissions: DashboardPermissions[];

    /**
    * The type of visibility this user has to their own fields
    */
    // @ApiMember(Description="The type of visibility this user has to their own fields")
    public myFieldPermissions: UserDataFieldModes;

    /**
    * The type of visibility this user has to customer fields
    */
    // @ApiMember(Description="The type of visibility this user has to customer fields")
    public customerFieldPermissions: UserDataFieldModes;

    /**
    * The type of visibility this user has to other user fields
    */
    // @ApiMember(Description="The type of visibility this user has to other user fields")
    public otherUserFieldPermissions: UserDataFieldModes;

    /**
    * The type of visibility this user has to other endpoint fields
    */
    // @ApiMember(Description="The type of visibility this user has to other endpoint fields")
    public otherEndpointFieldPermissions: UserDataFieldModes;

    /**
    * The name of this endpoint (for bots etc.)
    */
    // @ApiMember(Description="The name of this endpoint (for bots etc.)")
    public name: string;

    /**
    * The list of tags for this endpoint
    */
    // @ApiMember(Description="The list of tags for this endpoint")
    public tags: Tag[];

    /**
    * The list of action URLs
    */
    // @ApiMember(Description="The list of action URLs")
    public actionUrls: EndpointActionUrl[];

    /**
    * The list of members in this team
    */
    // @ApiMember(Description="The list of members in this team")
    public teamMemberIds: string[];

    /**
    * Visibility of this user/team in contact lists
    */
    // @ApiMember(Description="Visibility of this user/team in contact lists")
    public contactListVisibility: CustomerVisibility;

    /**
    * The list of contacts personal to this user
    */
    // @ApiMember(Description="The list of contacts personal to this user")
    public contacts: EndpointContact[];

    /**
    * The documo ID for this number
    */
    // @ApiMember(Description="The documo ID for this number")
    public documoId: string;

    /**
    * Integration data for this endpoint
    */
    // @ApiMember(Description="Integration data for this endpoint")
    public integrationData: EntityIntegrationData;

    /**
    * Settings for third party phone system
    */
    // @ApiMember(Description="Settings for third party phone system")
    public thirdPartyPhoneSystemSettings: ThirdPartyPhoneSystemSettings;

    /**
    * Should this user override the parent customer's app settings
    */
    // @ApiMember(Description="Should this user override the parent customer's app settings")
    public overrideAppSettings: boolean;

    /**
    * App / Portal settings for this user
    */
    // @ApiMember(Description="App / Portal settings for this user")
    public appSettings: AppSettings;

    /**
    * Configuration for the AI assistant
    */
    // @ApiMember(Description="Configuration for the AI assistant")
    public assistantSettings: AssistantSettings;

    public constructor(init?: Partial<EndpointInfo>) { super(init); (Object as any).assign(this, init); }
}

export class FindDuplicatesResponse
{
    public duplicates: FindDuplicateEntry[];

    public constructor(init?: Partial<FindDuplicatesResponse>) { (Object as any).assign(this, init); }
}

export class SyncPhoneNumbersResponse
{
    public newPhoneNumbers: EndpointInfo[];
    public updatedPhoneNumbers: EndpointInfo[];

    public constructor(init?: Partial<SyncPhoneNumbersResponse>) { (Object as any).assign(this, init); }
}

export class GetUniqueEndpointValueResponse
{
    public value: string;

    public constructor(init?: Partial<GetUniqueEndpointValueResponse>) { (Object as any).assign(this, init); }
}

export class ListAutoCompleteValuesResponse
{
    public values: AutoCompleteValue[];

    public constructor(init?: Partial<ListAutoCompleteValuesResponse>) { (Object as any).assign(this, init); }
}

export class GetValuesResponse
{
    public fieldValues: FieldValue[];

    public constructor(init?: Partial<GetValuesResponse>) { (Object as any).assign(this, init); }
}

export class FlowInfo extends EntityInfo
{
    /**
    * The name of this flow
    */
    // @ApiMember(Description="The name of this flow")
    public name: string;

    /**
    * The roles that this flow has
    */
    // @ApiMember(Description="The roles that this flow has")
    public roles: FlowRoles[];

    /**
    * What this flow does
    */
    // @ApiMember(Description="What this flow does")
    public description: string;

    /**
    * Any notes for this flow
    */
    // @ApiMember(Description="Any notes for this flow")
    public notes: string;

    /**
    * The ID of the account associated with the flow
    */
    // @ApiMember(Description="The ID of the account associated with the flow")
    public accountId: string;

    /**
    * The name of the account associated with the flow
    */
    // @ApiMember(Description="The name of the account associated with the flow")
    public accountName: string;

    /**
    * The ID of the customer this flow is associated with
    */
    // @ApiMember(Description="The ID of the customer this flow is associated with")
    public customerId: string;

    /**
    * The name of the customer this flow is associated with
    */
    // @ApiMember(Description="The name of the customer this flow is associated with")
    public customerName: string;

    /**
    * The breadcrumb to the flow for this endpoint
    */
    // @ApiMember(Description="The breadcrumb to the flow for this endpoint")
    public customerBreadcrumb: CustomerBreadcrumb[];

    /**
    * The nodes in this flow
    */
    // @ApiMember(Description="The nodes in this flow")
    public nodes: FlowNode[];

    /**
    * The flow's parameters
    */
    // @ApiMember(Description="The flow's parameters")
    public parameters: FlowParameter[];

    /**
    * The flow's exits
    */
    // @ApiMember(Description="The flow's exits")
    public exits: FlowExit[];

    /**
    * The UI data for the flow
    */
    // @ApiMember(Description="The UI data for the flow")
    public ui: FlowUI;

    /**
    * The list of tags for this flow
    */
    // @ApiMember(Description="The list of tags for this flow")
    public tags: Tag[];

    /**
    * The number of nodes in this flow
    */
    // @ApiMember(Description="The number of nodes in this flow")
    public nodeCount: number;

    public constructor(init?: Partial<FlowInfo>) { super(init); (Object as any).assign(this, init); }
}

export class PackageInfo
{
    public flows: FlowInfo[];
    public customerFields: CustomerDataField[];
    public endpointFields: EndpointDataField[];
    public systemFields: SystemSettingsField[];

    public constructor(init?: Partial<PackageInfo>) { (Object as any).assign(this, init); }
}

export class GetParameterFieldNamesResponse
{
    public customer: GetParameterField[];
    public system: GetParameterField[];
    public session: GetParameterField[];
    public endpoint: GetParameterField[];
    public user: GetParameterField[];

    public constructor(init?: Partial<GetParameterFieldNamesResponse>) { (Object as any).assign(this, init); }
}

export class HealthStatus
{
    public message: string;

    public constructor(init?: Partial<HealthStatus>) { (Object as any).assign(this, init); }
}

export class ImportSpreadsheetResult
{
    public updatedCustomers: CustomerInfo[];
    public newCustomers: CustomerInfo[];
    public updatedPhoneNumbers: EndpointInfo[];
    public newPhoneNumbers: EndpointInfo[];
    public updatedUsers: EndpointInfo[];
    public newUsers: EndpointInfo[];
    public errors: string[];
    public changesApplied: boolean;

    public constructor(init?: Partial<ImportSpreadsheetResult>) { (Object as any).assign(this, init); }
}

export class ListCrmCustomersResponse
{
    public customers: CrmCustomerInfo[];

    public constructor(init?: Partial<ListCrmCustomersResponse>) { (Object as any).assign(this, init); }
}

export class ListCrmContactsResponse
{
    public contacts: CrmContactInfo[];

    public constructor(init?: Partial<ListCrmContactsResponse>) { (Object as any).assign(this, init); }
}

export class GetIntegrationLogResponse
{
    public log: string;

    public constructor(init?: Partial<GetIntegrationLogResponse>) { (Object as any).assign(this, init); }
}

export class SyncIntegrationResult
{
    public log: string;

    public constructor(init?: Partial<SyncIntegrationResult>) { (Object as any).assign(this, init); }
}

export class IntegrationInfo extends EntityInfo
{
    /**
    * The ID of the account associated with this integration
    */
    // @ApiMember(Description="The ID of the account associated with this integration")
    public accountId: string;

    /**
    * The ID of the customer this integration is associated with
    */
    // @ApiMember(Description="The ID of the customer this integration is associated with")
    public customerId: string;

    /**
    * The name of the customer this integration is associated with
    */
    // @ApiMember(Description="The name of the customer this integration is associated with")
    public customerName: string;

    /**
    * The date the integration was sync'd last
    */
    // @ApiMember(Description="The date the integration was sync'd last")
    public dateLastSync: string;

    /**
    * The breadcrumb to the customer for this integration
    */
    // @ApiMember(Description="The breadcrumb to the customer for this integration")
    public customerBreadcrumb: CustomerBreadcrumb[];

    /**
    * The name of the integration (e.g. HostedSuite Dallas)
    */
    // @ApiMember(Description="The name of the integration (e.g. HostedSuite Dallas)")
    public name: string;

    /**
    * Automatically create new customers / users when sync'ing with CRM?
    */
    // @ApiMember(Description="Automatically create new customers / users when sync'ing with CRM?")
    public automaticallyCreateCustomers: boolean;

    /**
    * The type of integration
    */
    // @ApiMember(Description="The type of integration")
    public type: IntegrationTypes;

    /**
    * The status of the integration
    */
    // @ApiMember(Description="The status of the integration")
    public status: IntegrationStatuses;

    /**
    * The status of the integration
    */
    // @ApiMember(Description="The status of the integration")
    public statusMessage: string;

    /**
    * The settings type for this integration
    */
    // @ApiMember(Description="The settings type for this integration")
    public settingsDataType: DataType;

    /**
    * The settings for this integration
    */
    // @ApiMember(Description="The settings for this integration")
    public settings: Struct;

    /**
    * The features supported by this integration
    */
    // @ApiMember(Description="The features supported by this integration")
    public features: IntegrationFeatures[];

    /**
    * Is this integration authorized (OAuth)?
    */
    // @ApiMember(Description="Is this integration authorized (OAuth)?")
    public isAuthorized: boolean;

    public constructor(init?: Partial<IntegrationInfo>) { super(init); (Object as any).assign(this, init); }
}

export class ListConversationsResponse
{
    public conversations: ConversationInfo[];

    public constructor(init?: Partial<ListConversationsResponse>) { (Object as any).assign(this, init); }
}

export class NotificationInfo extends EntityInfo
{
    /**
    * The account ID this endpoint is associated with
    */
    // @ApiMember(Description="The account ID this endpoint is associated with")
    public accountId: string;

    /**
    * The name of the account this endpoint is associated with
    */
    // @ApiMember(Description="The name of the account this endpoint is associated with")
    public accountName: string;

    /**
    * The ID of the customer this endpoint is associated with
    */
    // @ApiMember(Description="The ID of the customer this endpoint is associated with")
    public customerId: string;

    /**
    * The name of the customer this endpoint is associated with
    */
    // @ApiMember(Description="The name of the customer this endpoint is associated with")
    public customerName: string;

    /**
    * The breadcrumb to the customer for this endpoint
    */
    // @ApiMember(Description="The breadcrumb to the customer for this endpoint")
    public customerBreadcrumb: CustomerBreadcrumb[];

    /**
    * The type of notification
    */
    // @ApiMember(Description="The type of notification")
    public type: NotificationTypes;

    /**
    * The subject of the notification
    */
    // @ApiMember(Description="The subject of the notification")
    public subject: string;

    /**
    * The body of the notification
    */
    // @ApiMember(Description="The body of the notification")
    public body: string;

    /**
    * The recipients of this notification
    */
    // @ApiMember(Description="The recipients of this notification")
    public recipients: NotificationRecipientInfo[];

    /**
    * The list of attachments
    */
    // @ApiMember(Description="The list of attachments")
    public attachments: FileInfo[];

    /**
    * The original from for the session
    */
    // @ApiMember(Description="The original from for the session")
    public from: string;

    /**
    * The original To for the session
    */
    // @ApiMember(Description="The original To for the session")
    public to: string;

    /**
    * Was there an error?
    */
    // @ApiMember(Description="Was there an error?")
    public error: boolean;

    /**
    * The error message
    */
    // @ApiMember(Description="The error message")
    public errorMessage: string;

    public constructor(init?: Partial<NotificationInfo>) { super(init); (Object as any).assign(this, init); }
}

export class SessionInfo extends EntityInfo
{
    /**
    * The state of the session
    */
    // @ApiMember(Description="The state of the session")
    public dialState: SessionDialState;

    /**
    * The call state of the session
    */
    // @ApiMember(Description="The call state of the session")
    public callState: SessionCallState;

    /**
    * The queue state of the session
    */
    // @ApiMember(Description="The queue state of the session")
    public queueState: SessionQueueStates;

    /**
    * The ID of the account associated with the flow
    */
    // @ApiMember(Description="The ID of the account associated with the flow")
    public accountId: string;

    /**
    * The name of the account associated with the session
    */
    // @ApiMember(Description="The name of the account associated with the session")
    public accountName: string;

    /**
    * The ID of the customer this session is associated with
    */
    // @ApiMember(Description="The ID of the customer this session is associated with")
    public customerId: string;

    /**
    * The customer breadcrumb this session is associated with
    */
    // @ApiMember(Description="The customer breadcrumb this session is associated with")
    public customerBreadcrumb: CustomerBreadcrumb[];

    /**
    * The name of the customer this session is associated with
    */
    // @ApiMember(Description="The name of the customer this session is associated with")
    public customerName: string;

    /**
    * The ID of the endpoint associated with this session
    */
    // @ApiMember(Description="The ID of the endpoint associated with this session")
    public endpointId: string;

    /**
    * The name of the endpoint associated with this session
    */
    // @ApiMember(Description="The name of the endpoint associated with this session")
    public endpointName: string;

    /**
    * The date the call completed
    */
    // @ApiMember(Description="The date the call completed")
    public dateCompleted: string;

    /**
    * The destination of the session (e.g. what was entered into the Dial box)
    */
    // @ApiMember(Description="The destination of the session (e.g. what was entered into the Dial box)")
    public destination: string;

    /**
    * The to address if any
    */
    // @ApiMember(Description="The to address if any")
    public toAddress: string;

    /**
    * The from address if any
    */
    // @ApiMember(Description="The from address if any")
    public fromAddress: string;

    /**
    * The from name if any
    */
    // @ApiMember(Description="The from name if any")
    public fromName: string;

    /**
    * Answered by name (if any)
    */
    // @ApiMember(Description="Answered by name (if any)")
    public answeredByName: string;

    /**
    * The ID of the queue member assigned to this call
    */
    // @ApiMember(Description="The ID of the queue member assigned to this call")
    public queueMemberId: string;

    /**
    * The flow channel
    */
    // @ApiMember(Description="The flow channel")
    public channel: FlowChannels;

    /**
    * Has the session ended
    */
    // @ApiMember(Description="Has the session ended")
    public ended: boolean;

    /**
    * The outcome of the call
    */
    // @ApiMember(Description="The outcome of the call")
    public outcome: string;

    /**
    * The twilio Call SID of this session
    */
    // @ApiMember(Description="The twilio Call SID of this session")
    public callSid: string;

    /**
    * Any console data for this session
    */
    // @ApiMember(Description="Any console data for this session")
    public consoleData: string;

    /**
    * The name of the hold queue for this call
    */
    // @ApiMember(Description="The name of the hold queue for this call")
    public holdQueueName: string;

    /**
    * The user ID who put this call on hold
    */
    // @ApiMember(Description="The user ID who put this call on hold")
    public heldByUserId: string;

    /**
    * The reason for the hold
    */
    // @ApiMember(Description="The reason for the hold")
    public holdReason: SessionHoldReasons;

    /**
    * The SID of the conference if in a conference call
    */
    // @ApiMember(Description="The SID of the conference if in a conference call")
    public conferenceSid: string;

    /**
    * The display name for this session
    */
    // @ApiMember(Description="The display name for this session")
    public displayName: string;

    /**
    * The log entries for this session
    */
    // @ApiMember(Description="The log entries for this session")
    public log: SessionLogInfo[];

    /**
    * The members of this session
    */
    // @ApiMember(Description="The members of this session")
    public members: SessionMemberInfo[];

    /**
    * The callback number (typically used for SIP to User calls)
    */
    // @ApiMember(Description="The callback number (typically used for SIP to User calls)")
    public callbackNumber: string;

    /**
    * The ID of the endpoint that answered
    */
    // @ApiMember(Description="The ID of the endpoint that answered")
    public answeredById: string;

    /**
    * Is this session incoming or outgoing?
    */
    // @ApiMember(Description="Is this session incoming or outgoing?")
    public direction: SessionDirections;

    /**
    * The phone number that this session is coming from (used with SMS chats)
    */
    // @ApiMember(Description="The phone number that this session is coming from (used with SMS chats)")
    public fromPhoneNumber: string;

    /**
    * The Call SID of the most recently added conference participant
    */
    // @ApiMember(Description="The Call SID of the most recently added conference participant")
    public mostRecentParticipantCallSid: string;

    /**
    * Was this session missed?
    */
    // @ApiMember(Description="Was this session missed?")
    public wasMissed: boolean;

    /**
    * The ring queue that the call is currently in
    */
    // @ApiMember(Description="The ring queue that the call is currently in")
    public ringQueueId: string;

    public constructor(init?: Partial<SessionInfo>) { super(init); (Object as any).assign(this, init); }
}

export class AppHomeInformation
{
    public isHostedSuiteCustomer: boolean;
    public conversations: ConversationInfo[];
    public calls: SessionInfo[];
    public callMetrics: AppHomeCallMetric[];
    public voicemail: FileInfo[];
    public forms: HostedSuiteCompletedForm[];

    public constructor(init?: Partial<AppHomeInformation>) { (Object as any).assign(this, init); }
}

export class AppGetActiveSessionsResult
{
    public sessions: SessionInfo[];

    public constructor(init?: Partial<AppGetActiveSessionsResult>) { (Object as any).assign(this, init); }
}

export class AppScheduleInformation
{
    public timeZoneId: string;
    public customerStates: string[];

    public constructor(init?: Partial<AppScheduleInformation>) { (Object as any).assign(this, init); }
}

export class AppSearchPhoneNumbersResponse
{
    public numbers: AppSearchPhoneNumberInfo[];

    public constructor(init?: Partial<AppSearchPhoneNumbersResponse>) { (Object as any).assign(this, init); }
}

export class AppSystemSettingsInfo
{
    public endpointFields: EndpointDataField[];
    /**
    * Is this customer a HostedSuite customer?
    */
    // @ApiMember(Description="Is this customer a HostedSuite customer?")
    public isHostedSuiteCustomer: boolean;

    /**
    * The customer's time zone
    */
    // @ApiMember(Description="The customer's time zone")
    public customerTimeZoneId: string;

    public constructor(init?: Partial<AppSystemSettingsInfo>) { (Object as any).assign(this, init); }
}

export class AppGetLogoResponse
{
    public logoUri: string;

    public constructor(init?: Partial<AppGetLogoResponse>) { (Object as any).assign(this, init); }
}

export class SystemInfo
{
    public version: string;
    public twilioFallbackBaseUrl: string;
    public twilioCallbackUrl: string;
    public twilioCallbackBaseUrl: string;
    public sipOriginationUrl: string;
    public isDevEnvironment: boolean;

    public constructor(init?: Partial<SystemInfo>) { (Object as any).assign(this, init); }
}

export class ReportInfo
{
    public id: string;
    public name: string;
    public status: ReportStatuses;
    public statusMessage: string;
    public dateCreated: string;
    public dateUpdated: string;
    public downloadLink: string;
    public jobId: string;
    public emailAddressToNotify: string;
    public server: string;

    public constructor(init?: Partial<ReportInfo>) { (Object as any).assign(this, init); }
}

export class ListReportsResponse
{
    public reports: ReportInfo[];

    public constructor(init?: Partial<ListReportsResponse>) { (Object as any).assign(this, init); }
}

export class GetPresenceStatusResponse
{
    public statuses: { [index: string]: PresenceStatuses; };

    public constructor(init?: Partial<GetPresenceStatusResponse>) { (Object as any).assign(this, init); }
}

export class GetActiveSessionsResponse
{
    public sessions: SessionInfo[];

    public constructor(init?: Partial<GetActiveSessionsResponse>) { (Object as any).assign(this, init); }
}

export class GetMySessionsResponse
{
    public sessions: SessionInfo[];

    public constructor(init?: Partial<GetMySessionsResponse>) { (Object as any).assign(this, init); }
}

export class GetChatTranscriptResponse
{
    public messages: ChatTranscriptMessage[];

    public constructor(init?: Partial<GetChatTranscriptResponse>) { (Object as any).assign(this, init); }
}

export class AddressInfo
{
    public id: string;
    public friendlyName: string;
    public emergencyEnabled: boolean;
    public customerName: string;
    public street: string;
    public city: string;
    public region: string;
    public postalCode: string;
    public isoCountry: string;

    public constructor(init?: Partial<AddressInfo>) { (Object as any).assign(this, init); }
}

export class ListAddressesResponse
{
    public addresses: AddressInfo[];

    public constructor(init?: Partial<ListAddressesResponse>) { (Object as any).assign(this, init); }
}

export class GetDialingPermissionsResponse
{
    public permissions: DialingPermissionInfo[];

    public constructor(init?: Partial<GetDialingPermissionsResponse>) { (Object as any).assign(this, init); }
}

export class SystemSettingsInfo extends EntityInfo
{
    /**
    * Use to override the system time for testing
    */
    // @ApiMember(Description="Use to override the system time for testing")
    public overrideSystemTime: boolean;

    /**
    * The system override time zone
    */
    // @ApiMember(Description="The system override time zone")
    public overrideSystemTimeZoneId: string;

    /**
    * The system override date/time
    */
    // @ApiMember(Description="The system override date/time")
    public overrideSystemDateTime: string;

    /**
    * The list of system settings fields
    */
    // @ApiMember(Description="The list of system settings fields")
    public systemFields: SystemSettingsField[];

    /**
    * The list of customer metadata fields
    */
    // @ApiMember(Description="The list of customer metadata fields")
    public customerFields: CustomerDataField[];

    /**
    * The list of endpoint metadata fields
    */
    // @ApiMember(Description="The list of endpoint metadata fields")
    public endpointFields: EndpointDataField[];

    /**
    * The list of email accounts
    */
    // @ApiMember(Description="The list of email accounts")
    public emailAccounts: EmailAccount[];

    /**
    * The list of tags in the system
    */
    // @ApiMember(Description="The list of tags in the system")
    public tags: Tag[];

    /**
    * Documentation for the account
    */
    // @ApiMember(Description="Documentation for the account")
    public documentation: string;

    /**
    * The number of days recordings are retained
    */
    // @ApiMember(Description="The number of days recordings are retained")
    public recordingRetentionDays: number;

    /**
    * Enable the SIP refer beta
    */
    // @ApiMember(Description="Enable the SIP refer beta")
    public enableSipRefer: boolean;

    /**
    * The number of seconds after which we automatically logoff a not ready agent
    */
    // @ApiMember(Description="The number of seconds after which we automatically logoff a not ready agent")
    public autoAgentLogoffSeconds: number;

    /**
    * The default SIP region for new devices
    */
    // @ApiMember(Description="The default SIP region for new devices")
    public defaultSipRegion?: TwilioSipRegions;

    public constructor(init?: Partial<SystemSettingsInfo>) { super(init); (Object as any).assign(this, init); }
}

export class GlobalSettingsInfo
{

    public constructor(init?: Partial<GlobalSettingsInfo>) { (Object as any).assign(this, init); }
}

export class TrustProfile
{
    public customerProfileSid: string;
    public cnamProductSid: string;
    public shakenStirProductSid: string;

    public constructor(init?: Partial<TrustProfile>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public profileUrl: string;

    // @DataMember(Order=9)
    public roles: string[];

    // @DataMember(Order=10)
    public permissions: string[];

    // @DataMember(Order=11)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=12)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

// @Route("/accounts/{accountId}/tokens", "POST")
export class RegenerateAccountTokens implements IReturn<RegenerateAccountTokensResponse>
{
    public accountId: string;

    public constructor(init?: Partial<RegenerateAccountTokens>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RegenerateAccountTokens'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new RegenerateAccountTokensResponse(); }
}

// @Route("/accounts/{accountId}/check", "POST")
export class CheckAccount implements IReturn<CheckAccountResponse>, IPost
{
    public accountId: string;

    public constructor(init?: Partial<CheckAccount>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CheckAccount'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CheckAccountResponse(); }
}

/**
* Deletes the specified account and everything in it. Can only be performed by a System Administrator
*/
// @Route("/accounts/{accountId}", "DELETE")
// @Api(Description="Deletes the specified account and everything in it. Can only be performed by a System Administrator")
export class DeleteAccount implements IReturnVoid, IDelete
{
    /**
    * The ID of the account to delete
    */
    // @ApiMember(Description="The ID of the account to delete")
    public accountId: string;

    public constructor(init?: Partial<DeleteAccount>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteAccount'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

/**
* Get the specified account details
*/
// @Route("/accounts/{accountId}", "GET")
// @Api(Description="Get the specified account details")
export class GetAccount implements IReturn<AccountInfo>, IGet
{
    /**
    * The ID of the account you want to update
    */
    // @ApiMember(Description="The ID of the account you want to update")
    public accountId: string;

    public constructor(init?: Partial<GetAccount>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAccount'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AccountInfo(); }
}

/**
* Update the specified account details
*/
// @Route("/accounts/{accountId}", "PATCH")
// @Api(Description="Update the specified account details")
export class PatchAccount implements IReturn<AccountInfo>, IPatch
{
    /**
    * The ID of the account you want to update
    */
    // @ApiMember(Description="The ID of the account you want to update")
    public accountId: string;

    /**
    * The new name for the account
    */
    // @ApiMember(Description="The new name for the account")
    public name: string;

    /**
    * The max number of phone numbers this account can have
    */
    // @ApiMember(Description="The max number of phone numbers this account can have")
    public maxPhoneNumbers?: number;

    /**
    * The ID of the file to use for a logo on the dashboard
    */
    // @ApiMember(Description="The ID of the file to use for a logo on the dashboard")
    public logoId: string;

    /**
    * Updated billing settings for this account
    */
    // @ApiMember(Description="Updated billing settings for this account")
    public billingSettings: BillingSettings;

    public constructor(init?: Partial<PatchAccount>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatchAccount'; }
    public getMethod() { return 'PATCH'; }
    public createResponse() { return new AccountInfo(); }
}

/**
* Retrieve a list of accounts
*/
// @Route("/accounts", "GET")
// @Api(Description="Retrieve a list of accounts")
export class ListAccounts extends ListRequest<AccountInfo> implements IReturn<ListResponse<AccountInfo>>
{
    /**
    * Filter the results by name
    */
    // @ApiMember(Description="Filter the results by name")
    public nameFilter: string;

    public constructor(init?: Partial<ListAccounts>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'ListAccounts'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ListResponse<AccountInfo>(); }
}

/**
* Creates a new account
*/
// @Route("/accounts", "POST")
// @Api(Description="Creates a new account")
export class NewAccount implements IReturn<AccountInfo>, IPost
{
    /**
    * The name of the new account
    */
    // @ApiMember(Description="The name of the new account")
    public name: string;

    /**
    * The Twilio Account SID (for BYOA)
    */
    // @ApiMember(Description="The Twilio Account SID (for BYOA)")
    public twilioAccountSid: string;

    /**
    * The Twilio Auth Token (for BYOA)
    */
    // @ApiMember(Description="The Twilio Auth Token (for BYOA)")
    public twilioAuthToken: string;

    public constructor(init?: Partial<NewAccount>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NewAccount'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AccountInfo(); }
}

// @Route("/app/activity/{notificationId}", "DELETE")
export class DismissActivity implements IReturnVoid, IDelete
{
    /**
    * The ID of the notification to delete
    */
    // @ApiMember(Description="The ID of the notification to delete")
    public notificationId: string;

    public constructor(init?: Partial<DismissActivity>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DismissActivity'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

/**
* Gets activity for the specified date range. Date range must be a week or less
*/
// @Route("/app/activity", "GET")
// @Api(Description="Gets activity for the specified date range. Date range must be a week or less")
export class GetActivity extends ListRequest<NotificationInfo> implements IReturn<ListResponse<NotificationInfo>>
{

    public constructor(init?: Partial<GetActivity>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetActivity'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ListResponse<NotificationInfo>(); }
}

/**
* Gets the contacts associated with the authenticated user
*/
// @Route("/app/contacts", "GET")
// @Api(Description="Gets the contacts associated with the authenticated user")
export class GetMyContacts implements IReturn<GetMyContactsResponse>
{

    public constructor(init?: Partial<GetMyContacts>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMyContacts'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetMyContactsResponse(); }
}

/**
* Ensures that the specified app user endpoint has the action URL mentioned
*/
// @Route("/app/ensure-action-url", "POST")
// @Api(Description="Ensures that the specified app user endpoint has the action URL mentioned")
export class EnsureActionUrl implements IReturnVoid, IPost
{
    /**
    * The URL to use
    */
    // @ApiMember(Description="The URL to use")
    public url: string;

    /**
    * Clear existing action URLs first
    */
    // @ApiMember(Description="Clear existing action URLs first")
    public clearExisting: boolean;

    /**
    * The HTTP method to use
    */
    // @ApiMember(Description="The HTTP method to use")
    public method: ActionUrlHttpMethods;

    public constructor(init?: Partial<EnsureActionUrl>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EnsureActionUrl'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* Create a new file and return the info
*/
// @Route("/app/files", "POST")
// @Api(Description="Create a new file and return the info")
export class UploadFile implements IReturn<FileInfo>, IPost
{
    /**
    * The file data
    */
    // @ApiMember(Description="The file data")
    public data: string;

    /**
    * The file name
    */
    // @ApiMember(Description="The file name")
    public fileName: string;

    /**
    * The file's content type
    */
    // @ApiMember(Description="The file's content type")
    public contentType: string;

    /**
    * Convert the file to WAV to be safe?
    */
    // @ApiMember(Description="Convert the file to WAV to be safe?")
    public convertToWav: boolean;

    public constructor(init?: Partial<UploadFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UploadFile'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new FileInfo(); }
}

/**
* Get the specified file's URI
*/
// @Route("/app/file/{fileId}", "GET")
// @Api(Description="Get the specified file's URI")
export class GetFileInfo implements IReturn<GetFileInfoResponse>, IPost
{
    /**
    * The ID of the file
    */
    // @ApiMember(Description="The ID of the file")
    public fileId: string;

    public constructor(init?: Partial<GetFileInfo>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFileInfo'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetFileInfoResponse(); }
}

/**
* Get all configurable settings objects for the authenticated user
*/
// @Route("/app/settings-objects", "GET")
// @Api(Description="Get all configurable settings objects for the authenticated user")
export class ListSettingsObjects implements IReturn<ListSettingsObjectsResponse>, IGet
{

    public constructor(init?: Partial<ListSettingsObjects>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ListSettingsObjects'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ListSettingsObjectsResponse(); }
}

/**
* Update the specified settings object
*/
// @Route("/app/settings-objects/{id}", "PATCH")
// @Api(Description="Update the specified settings object")
export class PatchSettingsObject implements IReturn<SettingsObject>, IPatch
{
    /**
    * The ID of the settings object
    */
    // @ApiMember(Description="The ID of the settings object")
    public id: string;

    /**
    * The data values to set
    */
    // @ApiMember(Description="The data values to set")
    public data: Struct;

    public constructor(init?: Partial<PatchSettingsObject>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatchSettingsObject'; }
    public getMethod() { return 'PATCH'; }
    public createResponse() { return new SettingsObject(); }
}

/**
* Gets the files associated with the authenticated user
*/
// @Route("/app/files", "GET")
// @Api(Description="Gets the files associated with the authenticated user")
export class GetMyFiles implements IReturn<GetMyFilesResponse>, IPost
{
    /**
    * Filter by type
    */
    // @ApiMember(Description="Filter by type")
    public fileTypes: FileTypes[];

    public constructor(init?: Partial<GetMyFiles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMyFiles'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetMyFilesResponse(); }
}

/**
* Create a new device token if it doesn't exist already
*/
// @Route("/app/tokens", "POST")
// @Api(Description="Create a new device token if it doesn't exist already")
export class NewDeviceToken implements IReturn<DeviceTokenInfo>, IPost
{
    /**
    * The type of device
    */
    // @ApiMember(Description="The type of device")
    public deviceType: DeviceTypes;

    /**
    * The device token to save
    */
    // @ApiMember(Description="The device token to save")
    public token: string;

    public constructor(init?: Partial<NewDeviceToken>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NewDeviceToken'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DeviceTokenInfo(); }
}

/**
* Delete the specified token
*/
// @Route("/app/tokens", "DELETE")
// @Api(Description="Delete the specified token")
export class DeleteDeviceToken implements IReturnVoid, IDelete
{
    /**
    * The token to delete
    */
    // @ApiMember(Description="The token to delete")
    public token: string;

    public constructor(init?: Partial<DeleteDeviceToken>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteDeviceToken'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

/**
* Gets the config for the app including webRTC token
*/
// @Route("/app/config", "GET")
// @Api(Description="Gets the config for the app including webRTC token")
export class GetAppConfig implements IReturn<AppConfig>, IGet
{
    /**
    * The type of device you are requesting config for
    */
    // @ApiMember(Description="The type of device you are requesting config for")
    public deviceType: DeviceTypes;

    /**
    * Use a specific push credential SID
    */
    // @ApiMember(Description="Use a specific push credential SID")
    public pushCredentialSid: string;

    /**
    * Use a specific application SID
    */
    // @ApiMember(Description="Use a specific application SID")
    public applicationSid: string;

    /**
    * Is this device operating in a sandbox environment? IOS only.
    */
    // @ApiMember(Description="Is this device operating in a sandbox environment? IOS only.")
    public sandbox: boolean;

    public constructor(init?: Partial<GetAppConfig>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAppConfig'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AppConfig(); }
}

/**
* Updates the user's info
*/
// @Route("/app/user-info", "PATCH")
// @Api(Description="Updates the user's info")
export class UpdateUserInfo implements IReturn<AppUserInfo>, IPatch
{
    /**
    * The new first name
    */
    // @ApiMember(Description="The new first name")
    public firstName: string;

    /**
    * The new last name
    */
    // @ApiMember(Description="The new last name")
    public lastName: string;

    public constructor(init?: Partial<UpdateUserInfo>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateUserInfo'; }
    public getMethod() { return 'PATCH'; }
    public createResponse() { return new AppUserInfo(); }
}

// @Route("/auth/sign-in", "GET")
export class SignInWithToken
{
    public token: string;
    public redirectUri: string;

    public constructor(init?: Partial<SignInWithToken>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SignInWithToken'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

// @Route("/auth/token", "GET")
export class RequestAuthToken implements IReturn<RequestAuthTokenResult>
{
    public userEmailAddress: string;
    public userId: string;

    public constructor(init?: Partial<RequestAuthToken>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RequestAuthToken'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new RequestAuthTokenResult(); }
}

/**
* Get a twilio token for client SDKs
*/
// @Route("/admin/token", "GET")
// @Api(Description="Get a twilio token for client SDKs")
export class GetTwilioToken implements IReturn<GetTwilioTokenResponse>, IGet
{
    /**
    * The account ID associated with the token
    */
    // @ApiMember(Description="The account ID associated with the token")
    public accountId: string;

    public constructor(init?: Partial<GetTwilioToken>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTwilioToken'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetTwilioTokenResponse(); }
}

/**
* Gets the current authenticated status or null
*/
// @Route("/auth/status", "GET")
// @Api(Description="Gets the current authenticated status or null")
export class GetAuthStatus implements IReturn<UserInfo>, IGet
{

    public constructor(init?: Partial<GetAuthStatus>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAuthStatus'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UserInfo(); }
}

/**
* Update the authenticated user's password
*/
// @Route("/my-password", "POST")
// @Api(Description="Update the authenticated user's password")
export class ChangeMyPassword implements IReturnVoid, IPost
{
    /**
    * The new password for this user
    */
    // @ApiMember(Description="The new password for this user")
    public newPassword: string;

    public constructor(init?: Partial<ChangeMyPassword>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ChangeMyPassword'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* Get the billing totals for the specified date range. Please note that we do not support getting billing at the hourly time granularity.
*/
// @Route("/billing", "GET")
// @Api(Description="Get the billing totals for the specified date range. Please note that we do not support getting billing at the hourly time granularity.")
export class GetBilling implements IReturn<BillingInfo>
{
    /**
    * Your Evo Voice Account ID
    */
    // @ApiMember(Description="Your Evo Voice Account ID", IsRequired=true)
    public accountId: string;

    /**
    * The start of the date range that you want to query for (we will only use the date portion, so it will include the entire day even if you specify a time as well)
    */
    // @ApiMember(Description="The start of the date range that you want to query for (we will only use the date portion, so it will include the entire day even if you specify a time as well)", IsRequired=true)
    public startDate: string;

    /**
    * The end of the date range you want to query for (we will include this entire day in the billing)
    */
    // @ApiMember(Description="The end of the date range you want to query for (we will include this entire day in the billing)", IsRequired=true)
    public endDate: string;

    /**
    * Specify this to filter to a specific customer.
    */
    // @ApiMember(Description="Specify this to filter to a specific customer.")
    public customerId: string;

    public constructor(init?: Partial<GetBilling>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBilling'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BillingInfo(); }
}

/**
* Report/create a new billable
*/
// @Route("/billables", "POST")
// @Api(Description="Report/create a new billable")
export class NewBillable implements IReturnVoid, IPost
{
    /**
    * The account to associate the billable with
    */
    // @ApiMember(Description="The account to associate the billable with")
    public accountId: string;

    /**
    * The customer to associate the billable with
    */
    // @ApiMember(Description="The customer to associate the billable with")
    public customerId: string;

    /**
    * The session ID to associate the billable with. You must specify either this or (AccountId + CustomerID)
    */
    // @ApiMember(Description="The session ID to associate the billable with. You must specify either this or (AccountId + CustomerID)")
    public sessionId: string;

    /**
    * The call SID to report
    */
    // @ApiMember(Description="The call SID to report")
    public callSid: string;

    public constructor(init?: Partial<NewBillable>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NewBillable'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* Change the customer's routing between production and staging
*/
// @Route("/customers/{customerId}/staging", "POST")
// @Api(Description="Change the customer's routing between production and staging")
export class SetCustomerStagingMode implements IReturn<CustomerInfo>
{
    /**
    * The ID of the customer you want to update
    */
    // @ApiMember(Description="The ID of the customer you want to update")
    public customerId: string;

    /**
    * Should this customer's phone numbers route to the staging server
    */
    // @ApiMember(Description="Should this customer's phone numbers route to the staging server")
    public isStaging: boolean;

    public constructor(init?: Partial<SetCustomerStagingMode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetCustomerStagingMode'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CustomerInfo(); }
}

/**
* Creates a new customer
*/
// @Route("/customers", "POST")
// @Api(Description="Creates a new customer")
export class NewCustomer implements IReturn<CustomerInfo>, IPost
{
    /**
    * The account ID to associate this customer with
    */
    // @ApiMember(Description="The account ID to associate this customer with")
    public accountId: string;

    /**
    * The name of the customer
    */
    // @ApiMember(Description="The name of the customer")
    public name: string;

    /**
    * The parent customer for this customer
    */
    // @ApiMember(Description="The parent customer for this customer")
    public parentCustomerId: string;

    /**
    * The reference ID for this customer (e.g. in a third party system)
    */
    // @ApiMember(Description="The reference ID for this customer (e.g. in a third party system)")
    public referenceId: string;

    /**
    * Data values for this customer
    */
    // @ApiMember(Description="Data values for this customer")
    public data: Struct;

    public constructor(init?: Partial<NewCustomer>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NewCustomer'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CustomerInfo(); }
}

/**
* Retrieve customers
*/
// @Route("/customers", "GET")
// @Api(Description="Retrieve customers")
export class ListCustomers extends ListRequest<CustomerInfo> implements IReturn<ListResponse<CustomerInfo>>
{
    /**
    * Filter by accounts
    */
    // @ApiMember(Description="Filter by accounts")
    public accountIds: string[];

    /**
    * Filter by name
    */
    // @ApiMember(Description="Filter by name")
    public nameFilter: string;

    /**
    * The IDs of the parent customers you want to filter by
    */
    // @ApiMember(Description="The IDs of the parent customers you want to filter by")
    public parentCustomerIds: string[];

    /**
    * If you want a shall parent customer filter (e.g. no deep children)
    */
    // @ApiMember(Description="If you want a shall parent customer filter (e.g. no deep children)")
    public shallowParent: boolean;

    /**
    * The list of tag IDs to filter by (must contain all)
    */
    // @ApiMember(Description="The list of tag IDs to filter by (must contain all)")
    public tagIds: string[];

    public constructor(init?: Partial<ListCustomers>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'ListCustomers'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ListResponse<CustomerInfo>(); }
}

/**
* Test the customer's schedule
*/
// @Route("/customers/{customerId}/test-schedule", "POST")
// @Route("/endpoints/{endpointId}/test-schedule", "POST")
// @Api(Description="Test the customer's schedule")
export class TestSchedule implements IReturn<TestScheduleResponse>
{
    /**
    * The ID of the customer whose schedule you want to test
    */
    // @ApiMember(Description="The ID of the customer whose schedule you want to test")
    public customerId: string;

    /**
    * The ID of the endpoint whose schedule you want to test.
    */
    // @ApiMember(Description="The ID of the endpoint whose schedule you want to test.")
    public endpointId: string;

    /**
    * The schedule to use (leave null to use the current saved schedule)
    */
    // @ApiMember(Description="The schedule to use (leave null to use the current saved schedule)")
    public schedule: Schedule;

    /**
    * The ISO string of the date and time you want to test
    */
    // @ApiMember(Description="The ISO string of the date and time you want to test")
    public dateTime: string;

    public constructor(init?: Partial<TestSchedule>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TestSchedule'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TestScheduleResponse(); }
}

/**
* Get the inherited schedule for this customer
*/
// @Route("/customers/{customerId}/inherited-schedule", "GET")
// @Route("/endpoints/{endpointId}/inherited-schedule", "GET")
// @Api(Description="Get the inherited schedule for this customer")
export class GetInheritedSchedule implements IReturn<Schedule>
{
    /**
    * The customer ID whose inherited schedule you want to retrieve
    */
    // @ApiMember(Description="The customer ID whose inherited schedule you want to retrieve")
    public customerId: string;

    /**
    * The endpoint ID whose inherited schedule you want to retrieve (use this OR customer id
    */
    // @ApiMember(Description="The endpoint ID whose inherited schedule you want to retrieve (use this OR customer id")
    public endpointId: string;

    public constructor(init?: Partial<GetInheritedSchedule>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetInheritedSchedule'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Schedule(); }
}

/**
* Update a specific customer
*/
// @Route("/customers/{customerId}", "PATCH")
// @Api(Description="Update a specific customer")
export class PatchCustomer implements IReturn<CustomerInfo>, IPatch
{
    /**
    * The ID of the customer you want to update
    */
    // @ApiMember(Description="The ID of the customer you want to update")
    public customerId: string;

    /**
    * The name for the customer
    */
    // @ApiMember(Description="The name for the customer")
    public name: string;

    /**
    * The parent customer for this customer
    */
    // @ApiMember(Description="The parent customer for this customer")
    public parentCustomerId: string;

    /**
    * The reference ID for this customer (e.g. in a third party system)
    */
    // @ApiMember(Description="The reference ID for this customer (e.g. in a third party system)")
    public referenceId: string;

    /**
    * Data values for this customer
    */
    // @ApiMember(Description="Data values for this customer")
    public data: Struct;

    /**
    * The list of tag IDs for this customer
    */
    // @ApiMember(Description="The list of tag IDs for this customer")
    public tagIds: string[];

    /**
    * This customer's schedule
    */
    // @ApiMember(Description="This customer's schedule")
    public schedule: Schedule;

    /**
    * Override this customer's billing settings? Otherwise inherits from parent
    */
    // @ApiMember(Description="Override this customer's billing settings? Otherwise inherits from parent")
    public overrideBillingSettings?: boolean;

    /**
    * Updated billing settings for this customer
    */
    // @ApiMember(Description="Updated billing settings for this customer")
    public billingSettings: BillingSettings;

    /**
    * Should this customer override the parent customer's app settings
    */
    // @ApiMember(Description="Should this customer override the parent customer's app settings")
    public overrideAppSettings?: boolean;

    /**
    * App / Portal settings for this customer
    */
    // @ApiMember(Description="App / Portal settings for this customer")
    public appSettings: AppSettings;

    public constructor(init?: Partial<PatchCustomer>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatchCustomer'; }
    public getMethod() { return 'PATCH'; }
    public createResponse() { return new CustomerInfo(); }
}

/**
* Get a specific customer
*/
// @Route("/customers/{customerId}", "GET")
// @Api(Description="Get a specific customer")
export class GetCustomer implements IReturn<CustomerInfo>, IGet
{
    /**
    * The ID of the customer you want to get
    */
    // @ApiMember(Description="The ID of the customer you want to get")
    public customerId: string;

    public constructor(init?: Partial<GetCustomer>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCustomer'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CustomerInfo(); }
}

/**
* Delete the specified customer
*/
// @Route("/customers/{customerId}", "DELETE")
// @Api(Description="Delete the specified customer")
export class DeleteCustomer implements IReturnVoid, IDelete
{
    /**
    * The ID of the customer to delete
    */
    // @ApiMember(Description="The ID of the customer to delete")
    public customerId: string;

    public constructor(init?: Partial<DeleteCustomer>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteCustomer'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

/**
* Returns the call center dashboard data
*/
// @Route("/dashboard/call-center", "GET")
// @Api(Description="Returns the call center dashboard data")
export class GetCallCenterDashboard implements IReturn<CallCenterDashboard>, IPost
{
    /**
    * The account ID to retrieve for
    */
    // @ApiMember(Description="The account ID to retrieve for")
    public accountId: string;

    public constructor(init?: Partial<GetCallCenterDashboard>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCallCenterDashboard'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CallCenterDashboard(); }
}

/**
* Gets all activecalls
*/
// @Route("/calls/active", "GET")
// @Api(Description="Gets all activecalls")
export class GetActiveCalls implements IReturn<GetActiveCallsResponse>, IGet
{
    /**
    * The account ID to retrieve for
    */
    // @ApiMember(Description="The account ID to retrieve for")
    public accountId: string;

    public constructor(init?: Partial<GetActiveCalls>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetActiveCalls'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetActiveCallsResponse(); }
}

/**
* Gets alerts filtered by date
*/
// @Route("/alerts", "GET")
// @Api(Description="Gets alerts filtered by date")
export class ListAlerts implements IReturn<AlertInfo[]>, IGet
{
    /**
    * The account ID you are listing for
    */
    // @ApiMember(Description="The account ID you are listing for")
    public accountId: string;

    /**
    * The start date to retrieve alerts for (YYYY-MM-DD)
    */
    // @ApiMember(Description="The start date to retrieve alerts for (YYYY-MM-DD)")
    public startDate: string;

    /**
    * The end date to retrieve alerts for (YYYY-MM-DD)
    */
    // @ApiMember(Description="The end date to retrieve alerts for (YYYY-MM-DD)")
    public endDate: string;

    public constructor(init?: Partial<ListAlerts>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ListAlerts'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<AlertInfo>(); }
}

/**
* Retrieve all calls
*/
// @Route("/billing/calls", "GET")
// @Api(Description="Retrieve all calls")
export class ListCalls implements IReturn<CallInfo[]>
{
    /**
    * The account ID you are listing for
    */
    // @ApiMember(Description="The account ID you are listing for")
    public accountId: string;

    /**
    * The start date to retrieve calls for (YYYY-MM-DD)
    */
    // @ApiMember(Description="The start date to retrieve calls for (YYYY-MM-DD)")
    public startDate: string;

    /**
    * The end date to retrieve calls for (YYYY-MM-DD)
    */
    // @ApiMember(Description="The end date to retrieve calls for (YYYY-MM-DD)")
    public endDate: string;

    public constructor(init?: Partial<ListCalls>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ListCalls'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<CallInfo>(); }
}

/**
* Gets all usage records for specified date range
*/
// @Route("/billing/usage", "GET")
// @Api(Description="Gets all usage records for specified date range")
export class ListUsageRecords implements IReturn<UsageRecordInfo[]>, IGet
{
    /**
    * The account ID you are listing for
    */
    // @ApiMember(Description="The account ID you are listing for")
    public accountId: string;

    /**
    * The start date to retrieve usage records for (YYYY-MM-DD)
    */
    // @ApiMember(Description="The start date to retrieve usage records for (YYYY-MM-DD)")
    public startDate: string;

    /**
    * The end date to retrieve usage records for (YYYY-MM-DD)
    */
    // @ApiMember(Description="The end date to retrieve usage records for (YYYY-MM-DD)")
    public endDate: string;

    public constructor(init?: Partial<ListUsageRecords>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ListUsageRecords'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<UsageRecordInfo>(); }
}

/**
* Verifies the caller ID for the specified phone number
*/
// @Route("/endpoints/verify-caller-id", "POST")
// @Api(Description="Verifies the caller ID for the specified phone number")
export class VerifyCallerId implements IReturn<EndpointInfo>
{
    public endpointId: string;

    public constructor(init?: Partial<VerifyCallerId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'VerifyCallerId'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EndpointInfo(); }
}

// @Route("/endpoints/app/config", "GET")
export class GetEndpointAppConfig implements IReturn<AppConfig>
{
    /**
    * The endpoint whose config you want to get (this must be a User)
    */
    // @ApiMember(Description="The endpoint whose config you want to get (this must be a User)")
    public endpointId: string;

    /**
    * The endpoint email address whose config you want to get (this must be a User) - EndpointId takes priority over this
    */
    // @ApiMember(Description="The endpoint email address whose config you want to get (this must be a User) - EndpointId takes priority over this")
    public endpointEmailAddress: string;

    /**
    * The type of device you are requesting config for
    */
    // @ApiMember(Description="The type of device you are requesting config for")
    public deviceType: DeviceTypes;

    /**
    * Use a specific push credential SID
    */
    // @ApiMember(Description="Use a specific push credential SID")
    public pushCredentialSid: string;

    /**
    * Use a specific application SID
    */
    // @ApiMember(Description="Use a specific application SID")
    public applicationSid: string;

    /**
    * Is this device operating in a sandbox environment? IOS only.
    */
    // @ApiMember(Description="Is this device operating in a sandbox environment? IOS only.")
    public sandbox: boolean;

    public constructor(init?: Partial<GetEndpointAppConfig>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEndpointAppConfig'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AppConfig(); }
}

/**
* Upload a new avatar for this endpoint. Use HTTP Files for the image
*/
// @Route("/endpoints/{endpointId}/avatar", "POST")
// @Api(Description="Upload a new avatar for this endpoint. Use HTTP Files for the image")
export class NewUserAvatar implements IReturn<EndpointInfo>
{
    /**
    * The ID of the endpoint
    */
    // @ApiMember(Description="The ID of the endpoint")
    public endpointId: string;

    public constructor(init?: Partial<NewUserAvatar>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NewUserAvatar'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EndpointInfo(); }
}

/**
* Deletes the avatar for this endpoint.
*/
// @Route("/endpoints/{endpointId}/avatar", "DELETE")
// @Api(Description="Deletes the avatar for this endpoint.")
export class DeleteUserAvatar implements IReturn<EndpointInfo>
{
    /**
    * The ID of the endpoint
    */
    // @ApiMember(Description="The ID of the endpoint")
    public endpointId: string;

    public constructor(init?: Partial<DeleteUserAvatar>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteUserAvatar'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new EndpointInfo(); }
}

// @Route("/endpoints/duplicates")
export class FindDuplicates implements IReturn<FindDuplicatesResponse>
{
    /**
    * The account ID to check
    */
    // @ApiMember(Description="The account ID to check")
    public accountId: string;

    /**
    * The field name to search for
    */
    // @ApiMember(Description="The field name to search for")
    public endpointFieldName: string;

    public constructor(init?: Partial<FindDuplicates>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FindDuplicates'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FindDuplicatesResponse(); }
}

/**
* Set the specified phone number's fax mode
*/
// @Route("/phone-numbers/{endpointId}/fax", "PATCH")
// @Api(Description="Set the specified phone number's fax mode")
export class SetPhoneNumberIsFax implements IReturn<EndpointInfo>, IPatch
{
    /**
    * The ID of the endpoint
    */
    // @ApiMember(Description="The ID of the endpoint")
    public endpointId: string;

    /**
    * Whether it's a fax or not?
    */
    // @ApiMember(Description="Whether it's a fax or not?")
    public isFax: boolean;

    public constructor(init?: Partial<SetPhoneNumberIsFax>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetPhoneNumberIsFax'; }
    public getMethod() { return 'PATCH'; }
    public createResponse() { return new EndpointInfo(); }
}

/**
* Syncs list of phone numbers with Twilio detecting any newly ported numbers, status changes, etc.
*/
// @Route("/phone-numbers/sync", "POST")
// @Api(Description="Syncs list of phone numbers with Twilio detecting any newly ported numbers, status changes, etc.")
export class SyncPhoneNumbers implements IReturn<SyncPhoneNumbersResponse>, IPost
{
    /**
    * The account ID you want to sync
    */
    // @ApiMember(Description="The account ID you want to sync")
    public accountId: string;

    public constructor(init?: Partial<SyncPhoneNumbers>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SyncPhoneNumbers'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SyncPhoneNumbersResponse(); }
}

/**
* DESCRIPTION
*/
// @Route("/endpoints/sip/{endpointId}/password", "PATCH")
// @Api(Description="DESCRIPTION")
export class RegenerateSipPassword implements IReturn<EndpointInfo>, IPatch
{
    /**
    * The ID of the endpoint whose SIP password you want regenerated
    */
    // @ApiMember(Description="The ID of the endpoint whose SIP password you want regenerated")
    public endpointId: string;

    public constructor(init?: Partial<RegenerateSipPassword>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RegenerateSipPassword'; }
    public getMethod() { return 'PATCH'; }
    public createResponse() { return new EndpointInfo(); }
}

/**
* Deletes the specified endpoint
*/
// @Route("/endpoints/{endpointId}", "DELETE")
// @Api(Description="Deletes the specified endpoint")
export class DeleteEndpoint implements IReturnVoid, IDelete
{
    /**
    * The ID of the endpoint to delete
    */
    // @ApiMember(Description="The ID of the endpoint to delete")
    public endpointId: string;

    public constructor(init?: Partial<DeleteEndpoint>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteEndpoint'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

/**
* Create a new Team
*/
// @Route("/endpoints/team", "POST")
// @Api(Description="Create a new Team")
export class NewTeamEndpoint implements IReturn<EndpointInfo>, IPost
{
    /**
    * The account to associate with this new endpoint
    */
    // @ApiMember(Description="The account to associate with this new endpoint")
    public accountId: string;

    /**
    * The Customer this contact is associated with
    */
    // @ApiMember(Description="The Customer this contact is associated with")
    public customerId: string;

    /**
    * The name for the team
    */
    // @ApiMember(Description="The name for the team")
    public name: string;

    /**
    * The list of tags to associate with the new endpoint
    */
    // @ApiMember(Description="The list of tags to associate with the new endpoint")
    public tagIds: string[];

    /**
    * Data values for this endpoint
    */
    // @ApiMember(Description="Data values for this endpoint")
    public data: Struct;

    /**
    * The ID of the users who are in this team
    */
    // @ApiMember(Description="The ID of the users who are in this team")
    public teamMemberIds: string[];

    public constructor(init?: Partial<NewTeamEndpoint>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NewTeamEndpoint'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EndpointInfo(); }
}

/**
* Create a new AI Assistant
*/
// @Route("/endpoints/assistant", "POST")
// @Api(Description="Create a new AI Assistant")
export class NewAssistantEndpoint implements IReturn<EndpointInfo>, IPost
{
    /**
    * The account to associate with this new endpoint
    */
    // @ApiMember(Description="The account to associate with this new endpoint")
    public accountId: string;

    /**
    * The Customer this contact is associated with
    */
    // @ApiMember(Description="The Customer this contact is associated with")
    public customerId: string;

    /**
    * The name for the team
    */
    // @ApiMember(Description="The name for the team")
    public name: string;

    /**
    * The list of tags to associate with the new endpoint
    */
    // @ApiMember(Description="The list of tags to associate with the new endpoint")
    public tagIds: string[];

    /**
    * Data values for this endpoint
    */
    // @ApiMember(Description="Data values for this endpoint")
    public data: Struct;

    public constructor(init?: Partial<NewAssistantEndpoint>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NewAssistantEndpoint'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EndpointInfo(); }
}

/**
* Create a new email address endpoint
*/
// @Route("/endpoints/email", "POST")
// @Api(Description="Create a new email address endpoint")
export class NewEmailEndpoint implements IReturn<EndpointInfo>, IPost
{
    /**
    * The ID of the account to associate this console with
    */
    // @ApiMember(Description="The ID of the account to associate this console with")
    public accountId: string;

    /**
    * The Customer this contact is associated with
    */
    // @ApiMember(Description="The Customer this contact is associated with")
    public customerId: string;

    /**
    * The prefix to use for this email endpoint (e.g. fax, support, reception) The email address will be unique but you can specify a word for a prefix. Must start with a letter and only contain letters, digits, and .
    */
    // @ApiMember(Description="The prefix to use for this email endpoint (e.g. fax, support, reception) The email address will be unique but you can specify a word for a prefix. Must start with a letter and only contain letters, digits, and .")
    public prefix: string;

    /**
    * The list of tags to associate with the new endpoint
    */
    // @ApiMember(Description="The list of tags to associate with the new endpoint")
    public tagIds: string[];

    /**
    * Data values for this endpoint
    */
    // @ApiMember(Description="Data values for this endpoint")
    public data: Struct;

    /**
    * Specify the flow to use
    */
    // @ApiMember(Description="Specify the flow to use")
    public flowId: string;

    /**
    * The flow params
    */
    // @ApiMember(Description="The flow params")
    public flowParams: Struct;

    public constructor(init?: Partial<NewEmailEndpoint>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NewEmailEndpoint'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EndpointInfo(); }
}

/**
* Create a new app
*/
// @Route("/endpoints/users", "POST")
// @Api(Description="Create a new app")
export class NewUserEndpoint implements IReturn<EndpointInfo>, IPost
{
    /**
    * The ID of the account to associate this console with
    */
    // @ApiMember(Description="The ID of the account to associate this console with")
    public accountId: string;

    /**
    * The Customer this contact is associated with
    */
    // @ApiMember(Description="The Customer this contact is associated with")
    public customerId: string;

    /**
    * The third party reference ID for the endpoint
    */
    // @ApiMember(Description="The third party reference ID for the endpoint")
    public referenceId: string;

    /**
    * The user mode for this user
    */
    // @ApiMember(Description="The user mode for this user")
    public userMode: UserModes;

    /**
    * The email address associated with the contact for login.
    */
    // @ApiMember(Description="The email address associated with the contact for login.")
    public emailAddress: string;

    /**
    * The password to assign this app
    */
    // @ApiMember(Description="The password to assign this app")
    public password: string;

    /**
    * The first name of the user
    */
    // @ApiMember(Description="The first name of the user")
    public firstName: string;

    /**
    * The last name of the user
    */
    // @ApiMember(Description="The last name of the user")
    public lastName: string;

    /**
    * The list of tags to associate with the new endpoint
    */
    // @ApiMember(Description="The list of tags to associate with the new endpoint")
    public tagIds: string[];

    /**
    * Data values for this endpoint
    */
    // @ApiMember(Description="Data values for this endpoint")
    public data: Struct;

    /**
    * Specify the flow to use
    */
    // @ApiMember(Description="Specify the flow to use")
    public flowId: string;

    /**
    * The flow params
    */
    // @ApiMember(Description="The flow params")
    public flowParams: Struct;

    public constructor(init?: Partial<NewUserEndpoint>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NewUserEndpoint'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EndpointInfo(); }
}

/**
* Buys the specified phone number
*/
// @Route("/endpoints/phone-numbers", "POST")
// @Api(Description="Buys the specified phone number")
export class BuyPhoneNumber implements IReturn<EndpointInfo>, IPost
{
    /**
    * The ID of the account to associate the number with
    */
    // @ApiMember(Description="The ID of the account to associate the number with")
    public accountId: string;

    /**
    * The ID of the customer to associate this number with
    */
    // @ApiMember(Description="The ID of the customer to associate this number with")
    public customerId: string;

    /**
    * The number to buy/create
    */
    // @ApiMember(Description="The number to buy/create")
    public number: string;

    /**
    * Is this number virtual? If so we will not buy but just create an entry
    */
    // @ApiMember(Description="Is this number virtual? If so we will not buy but just create an entry")
    public isVirtualPhoneNumber: boolean;

    /**
    * Is this a fax number?
    */
    // @ApiMember(Description="Is this a fax number?")
    public isFaxNumber: boolean;

    /**
    * The address SID to associate with the phone number
    */
    // @ApiMember(Description="The address SID to associate with the phone number")
    public addressSid: string;

    /**
    * The list of tags to associate with the new endpoint
    */
    // @ApiMember(Description="The list of tags to associate with the new endpoint")
    public tagIds: string[];

    /**
    * Data values for this endpoint
    */
    // @ApiMember(Description="Data values for this endpoint")
    public data: Struct;

    /**
    * Specify the flow to use
    */
    // @ApiMember(Description="Specify the flow to use")
    public flowId: string;

    /**
    * The flow params
    */
    // @ApiMember(Description="The flow params")
    public flowParams: Struct;

    public constructor(init?: Partial<BuyPhoneNumber>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BuyPhoneNumber'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EndpointInfo(); }
}

/**
* Update the specified endpoint
*/
// @Route("/endpoints/{endpointId}", "PATCH")
// @Api(Description="Update the specified endpoint")
export class PatchEndpoint implements IReturn<EndpointInfo>, IPatch
{
    /**
    * The ID of the endpoint to update
    */
    // @ApiMember(Description="The ID of the endpoint to update")
    public endpointId: string;

    /**
    * The ID of the customer to associate this endpoint with
    */
    // @ApiMember(Description="The ID of the customer to associate this endpoint with")
    public customerId: string;

    /**
    * The third party reference ID for the endpoint
    */
    // @ApiMember(Description="The third party reference ID for the endpoint")
    public referenceId: string;

    /**
    * The ID of the flow to use for voice sessions
    */
    // @ApiMember(Description="The ID of the flow to use for voice sessions")
    public flowId: string;

    /**
    * Params to use for the voice flow
    */
    // @ApiMember(Description="Params to use for the voice flow")
    public flowParams: Struct;

    /**
    * Whether to use a single flow always or use scheduled flow system
    */
    // @ApiMember(Description="Whether to use a single flow always or use scheduled flow system")
    public flowSchedule?: EndpointFlowSchedules;

    /**
    * This endpoint's schedule
    */
    // @ApiMember(Description="This endpoint's schedule")
    public schedule: Schedule;

    /**
    * The list of scheduled flows when using scheduling
    */
    // @ApiMember(Description="The list of scheduled flows when using scheduling")
    public scheduledFlows: ScheduledFlow[];

    /**
    * Is this a partial flow param update?
    */
    // @ApiMember(Description="Is this a partial flow param update?")
    public isPartialFlowUpdate: boolean;

    /**
    * The list of tag IDs for this endpoint
    */
    // @ApiMember(Description="The list of tag IDs for this endpoint")
    public tagIds: string[];

    /**
    * Whether we look up caller ID or not
    */
    // @ApiMember(Description="Whether we look up caller ID or not")
    public enableCallerIdLookup?: boolean;

    /**
    * Enroll this number in a 10DLC messaging campaign?
    */
    // @ApiMember(Description="Enroll this number in a 10DLC messaging campaign?")
    public enrollIn10DlcService?: boolean;

    /**
    * Leave 10DLC campaign
    */
    // @ApiMember(Description="Leave 10DLC campaign")
    public leave10DlcService?: boolean;

    /**
    * The caller ID Name (CNAM) for the phone number
    */
    // @ApiMember(Description="The caller ID Name (CNAM) for the phone number")
    public callerIdName: string;

    /**
    * The new password for the app
    */
    // @ApiMember(Description="The new password for the app")
    public userPassword: string;

    /**
    * Is this user SIP based or soft phone
    */
    // @ApiMember(Description="Is this user SIP based or soft phone")
    public userMode?: UserModes;

    /**
    * The Twilio Region for the SIP endpoint
    */
    // @ApiMember(Description="The Twilio Region for the SIP endpoint")
    public sipRegion?: TwilioSipRegions;

    /**
    * Data values for this endpoint
    */
    // @ApiMember(Description="Data values for this endpoint")
    public data: Struct;

    /**
    * Set this to true to prevent Evo Voice from overriding the 10DLC / SMS settings for this number
    */
    // @ApiMember(Description="Set this to true to prevent Evo Voice from overriding the 10DLC / SMS settings for this number")
    public useExternal10DlcCampaign?: boolean;

    /**
    * Disable SMS
    */
    // @ApiMember(Description="Disable SMS")
    public disableSms?: boolean;

    /**
    * The address SID associated with the phone number
    */
    // @ApiMember(Description="The address SID associated with the phone number")
    public addressSid: string;

    /**
    * Unlock the phone number (for use with BYOA phone numbers)
    */
    // @ApiMember(Description="Unlock the phone number (for use with BYOA phone numbers)")
    public unlockPhoneNumber?: boolean;

    /**
    * The email address for this soft phone user. Will be used for login
    */
    // @ApiMember(Description="The email address for this soft phone user. Will be used for login")
    public userEmailAddress: string;

    /**
    * The first name of the user (for user endpoints)
    */
    // @ApiMember(Description="The first name of the user (for user endpoints)")
    public userFirstName: string;

    /**
    * The last name of the user (for user endpoints)
    */
    // @ApiMember(Description="The last name of the user (for user endpoints)")
    public userLastName: string;

    /**
    * Does this user have manager role?
    */
    // @ApiMember(Description="Does this user have manager role?")
    public managerRole: UserManagerRoles;

    /**
    * The list of dashboard permissions for when the manager role is custom
    */
    // @ApiMember(Description="The list of dashboard permissions for when the manager role is custom")
    public dashboardPermissions: DashboardPermissions[];

    /**
    * The type of visibility this user has to customer fields
    */
    // @ApiMember(Description="The type of visibility this user has to customer fields")
    public myFieldPermissions: UserDataFieldModes;

    /**
    * The type of visibility this user has to customer fields
    */
    // @ApiMember(Description="The type of visibility this user has to customer fields")
    public customerFieldPermissions: UserDataFieldModes;

    /**
    * The type of visibility this user has to other user fields
    */
    // @ApiMember(Description="The type of visibility this user has to other user fields")
    public otherUserFieldPermissions: UserDataFieldModes;

    /**
    * The type of visibility this user has to other endpoint fields
    */
    // @ApiMember(Description="The type of visibility this user has to other endpoint fields")
    public otherEndpointFieldPermissions: UserDataFieldModes;

    /**
    * The visibility of this user/team in contact lists
    */
    // @ApiMember(Description="The visibility of this user/team in contact lists")
    public contactListVisibility: CustomerVisibility;

    /**
    * The list of action URLs
    */
    // @ApiMember(Description="The list of action URLs")
    public actionUrls: EndpointActionUrl[];

    /**
    * The type of update to perform to action urls if specified
    */
    // @ApiMember(Description="The type of update to perform to action urls if specified")
    public actionUrlsMode: ListUpdateModes;

    /**
    * The name of the endpoint
    */
    // @ApiMember(Description="The name of the endpoint")
    public name: string;

    /**
    * Force update the Twilio URLs for this number
    */
    // @ApiMember(Description="Force update the Twilio URLs for this number")
    public forceUpdateUrls: boolean;

    /**
    * The list of members in this team
    */
    // @ApiMember(Description="The list of members in this team")
    public teamMemberIds: string[];

    /**
    * The list of contacts personal to this user
    */
    // @ApiMember(Description="The list of contacts personal to this user")
    public contacts: EndpointContact[];

    /**
    * Is emergency calling enabled on this number?
    */
    // @ApiMember(Description="Is emergency calling enabled on this number?")
    public enableEmergencyCalling?: boolean;

    /**
    * The SID of the emergency address for this number
    */
    // @ApiMember(Description="The SID of the emergency address for this number")
    public emergencyAddressSid: string;

    /**
    * The ID of the phone number to use for emergency dialing
    */
    // @ApiMember(Description="The ID of the phone number to use for emergency dialing")
    public emergencyPhoneNumberId: string;

    /**
    * Settings for third party phone system
    */
    // @ApiMember(Description="Settings for third party phone system")
    public thirdPartyPhoneSystemSettings: ThirdPartyPhoneSystemSettings;

    /**
    * Should this user override the parent customer's app settings
    */
    // @ApiMember(Description="Should this user override the parent customer's app settings")
    public overrideAppSettings?: boolean;

    /**
    * App / Portal settings for this user
    */
    // @ApiMember(Description="App / Portal settings for this user")
    public appSettings: AppSettings;

    /**
    * The settings for AI assistant
    */
    // @ApiMember(Description="The settings for AI assistant")
    public assistantSettings: AssistantSettings;

    public constructor(init?: Partial<PatchEndpoint>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatchEndpoint'; }
    public getMethod() { return 'PATCH'; }
    public createResponse() { return new EndpointInfo(); }
}

/**
* Get the specified endpoint
*/
// @Route("/endpoints/{endpointId}", "GET")
// @Api(Description="Get the specified endpoint")
export class GetEndpoint implements IReturn<EndpointInfo>, IGet
{
    /**
    * The ID of the endpoint to update
    */
    // @ApiMember(Description="The ID of the endpoint to update")
    public endpointId: string;

    public constructor(init?: Partial<GetEndpoint>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEndpoint'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EndpointInfo(); }
}

/**
* Search for phone numbers
*/
// @Route("/endpoints/phone-numbers/search", "GET")
// @Api(Description="Search for phone numbers")
export class SearchPhoneNumbers implements IReturn<PhoneNumberInfo[]>, IGet
{
    /**
    * The account you are searching for
    */
    // @ApiMember(Description="The account you are searching for")
    public accountId: string;

    /**
    * The area code
    */
    // @ApiMember(Description="The area code")
    public areaCode: string;

    /**
    * The country code you are searching for
    */
    // @ApiMember(Description="The country code you are searching for")
    public countryCode: string;

    /**
    * The postal code you are searching for
    */
    // @ApiMember(Description="The postal code you are searching for")
    public postalCode: string;

    /**
    * The distance from the specified postal code (default is 100)
    */
    // @ApiMember(Description="The distance from the specified postal code (default is 100)")
    public distance?: number;

    /**
    * Search for fax numbers
    */
    // @ApiMember(Description="Search for fax numbers")
    public isFaxNumber: boolean;

    /**
    * Contains specific digits
    */
    // @ApiMember(Description="Contains specific digits")
    public contains: string;

    public constructor(init?: Partial<SearchPhoneNumbers>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SearchPhoneNumbers'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<PhoneNumberInfo>(); }
}

/**
* Lists all endpoints
*/
// @Route("/endpoints", "GET")
// @Api(Description="Lists all endpoints")
export class ListEndpoints extends ListRequest<EndpointInfo> implements IReturn<ListResponse<EndpointInfo>>
{
    /**
    * The IDs of the account whose endpoints you want to retrieve
    */
    // @ApiMember(Description="The IDs of the account whose endpoints you want to retrieve")
    public accountIds: string[];

    /**
    * The IDs of the customers whose endpoints you want to retrieve
    */
    // @ApiMember(Description="The IDs of the customers whose endpoints you want to retrieve")
    public customerIds: string[];

    /**
    * The third party IDs of endpoints you want to retrieve
    */
    // @ApiMember(Description="The third party IDs of endpoints you want to retrieve")
    public referenceIds: string[];

    /**
    * If you want a shall parent customer filter (e.g. no deep children)
    */
    // @ApiMember(Description="If you want a shall parent customer filter (e.g. no deep children)")
    public shallowParent: boolean;

    /**
    * The IDs of the flows whose endpoints you want to retrieve
    */
    // @ApiMember(Description="The IDs of the flows whose endpoints you want to retrieve")
    public flowIds: string[];

    /**
    * The state where the specified flow IDs should be
    */
    // @ApiMember(Description="The state where the specified flow IDs should be")
    public flowState: string;

    /**
    * The list of tag IDs to filter by (must contain all)
    */
    // @ApiMember(Description="The list of tag IDs to filter by (must contain all)")
    public tagIds: string[];

    /**
    * Filter by name
    */
    // @ApiMember(Description="Filter by name")
    public nameFilter: string;

    /**
    * Filter by phone number
    */
    // @ApiMember(Description="Filter by phone number")
    public phoneNumberFilter: string;

    /**
    * Filter by type
    */
    // @ApiMember(Description="Filter by type")
    public type?: EndpointTypes;

    /**
    * Filter by types
    */
    // @ApiMember(Description="Filter by types")
    public types: EndpointTypes[];

    /**
    * Filter by user mode
    */
    // @ApiMember(Description="Filter by user mode")
    public userMode?: UserModes;

    /**
    * Filters for any endpoint data fields. Format for each entry should be 'FieldName=Value'. We do not support numeric or boolean currently
    */
    // @ApiMember(Description="Filters for any endpoint data fields. Format for each entry should be 'FieldName=Value'. We do not support numeric or boolean currently")
    public dataFilters: string[];

    /**
    * Filter by SIP user name
    */
    // @ApiMember(Description="Filter by SIP user name")
    public sipUserName: string;

    /**
    * Filter by flow parameters (this must be a JSON struct)
    */
    // @ApiMember(Description="Filter by flow parameters (this must be a JSON struct)")
    public flowParametersFilter: string;

    public constructor(init?: Partial<ListEndpoints>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'ListEndpoints'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ListResponse<EndpointInfo>(); }
}

/**
* Get a unique number string value for an endpoint field
*/
// @Route("/values/endpoint/unique", "GET")
// @Api(Description="Get a unique number string value for an endpoint field")
export class GetUniqueEndpointValue implements IReturn<GetUniqueEndpointValueResponse>
{
    /**
    * The account ID you are interested in
    */
    // @ApiMember(Description="The account ID you are interested in")
    public accountId: string;

    /**
    * The endpoint field you want a unique value for
    */
    // @ApiMember(Description="The endpoint field you want a unique value for")
    public fieldName: string;

    /**
    * The prefix to use for the value
    */
    // @ApiMember(Description="The prefix to use for the value")
    public prefix: string;

    /**
    * The length of the value
    */
    // @ApiMember(Description="The length of the value")
    public length: number;

    public constructor(init?: Partial<GetUniqueEndpointValue>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUniqueEndpointValue'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetUniqueEndpointValueResponse(); }
}

// @Route("/ui/autocomplete/values")
export class ListAutoCompleteValues implements IReturn<ListAutoCompleteValuesResponse>
{
    /**
    * Account ID
    */
    // @ApiMember(Description="Account ID")
    public accountId: string;

    /**
    * Filter by customer ID
    */
    // @ApiMember(Description="Filter by customer ID")
    public customerId: string;

    /**
    * The type of value
    */
    // @ApiMember(Description="The type of value")
    public type: ValueTypes;

    /**
    * The query text to search for
    */
    // @ApiMember(Description="The query text to search for")
    public query: string;

    /**
    * Type specific filter
    */
    // @ApiMember(Description="Type specific filter")
    public typeSpecificFilter: string;

    /**
    * The value to search for (will only return one result)
    */
    // @ApiMember(Description="The value to search for (will only return one result)")
    public value: string;

    /**
    * Should the results be sent back in plain text or with embedded HTML?
    */
    // @ApiMember(Description="Should the results be sent back in plain text or with embedded HTML?")
    public plainText: boolean;

    /**
    * Include full info?
    */
    // @ApiMember(Description="Include full info?")
    public fullInfo: boolean;

    /**
    * List all values, only use for small lists.
    */
    // @ApiMember(Description="List all values, only use for small lists.")
    public all: boolean;

    public constructor(init?: Partial<ListAutoCompleteValues>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ListAutoCompleteValues'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ListAutoCompleteValuesResponse(); }
}

/**
* Get the values for tags
*/
// @Route("/values/tag", "GET")
// @Api(Description="Get the values for tags")
export class GetTagFieldValues implements IReturn<GetValuesResponse>
{
    public accountId: string;

    public constructor(init?: Partial<GetTagFieldValues>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTagFieldValues'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetValuesResponse(); }
}

/**
* Get the values for value source (used in SetXXX nodes)
*/
// @Route("/values/value-sources", "GET")
// @Api(Description="Get the values for value source (used in SetXXX nodes)")
export class GetValueSourceFieldValues implements IReturn<GetValuesResponse>
{
    public accountId: string;
    public param: string;

    public constructor(init?: Partial<GetValueSourceFieldValues>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetValueSourceFieldValues'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetValuesResponse(); }
}

/**
* Get country code values
*/
// @Route("/values/country-code")
// @Api(Description="Get country code values")
export class GetCountryCodeFieldValues implements IReturn<GetValuesResponse>
{
    public accountId: string;

    public constructor(init?: Partial<GetCountryCodeFieldValues>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCountryCodeFieldValues'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetValuesResponse(); }
}

/**
* Get the values for email accounts
*/
// @Route("/values/email-account", "GET")
// @Api(Description="Get the values for email accounts")
export class GetEmailAccountFieldValues implements IReturn<GetValuesResponse>
{
    public accountId: string;

    public constructor(init?: Partial<GetEmailAccountFieldValues>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEmailAccountFieldValues'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetValuesResponse(); }
}

/**
* Get values for customer fields
*/
// @Route("/values/customer", "GET")
// @Api(Description="Get values for customer fields")
export class GetCustomerFieldValues implements IReturn<GetValuesResponse>
{
    public accountId: string;

    public constructor(init?: Partial<GetCustomerFieldValues>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCustomerFieldValues'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetValuesResponse(); }
}

// @Route("/values/endpoint-fields")
export class GetEndpointFieldsValues implements IReturn<GetValuesResponse>
{
    public accountId: string;

    public constructor(init?: Partial<GetEndpointFieldsValues>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEndpointFieldsValues'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetValuesResponse(); }
}

/**
* Get the values available for an autopilot question type
*/
// @Route("/values/twilio-auto-pilot-types", "GET")
// @Api(Description="Get the values available for an autopilot question type")
export class GetTwilioAutoPilotTypeValues implements IReturn<GetValuesResponse>
{
    public accountId: string;

    public constructor(init?: Partial<GetTwilioAutoPilotTypeValues>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTwilioAutoPilotTypeValues'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetValuesResponse(); }
}

/**
* Get the values for audio files
*/
// @Route("/values/audio-file", "GET")
// @Api(Description="Get the values for audio files")
export class GetAudioFileFieldValues implements IReturn<GetValuesResponse>
{
    public accountId: string;

    public constructor(init?: Partial<GetAudioFileFieldValues>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAudioFileFieldValues'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetValuesResponse(); }
}

// @Route("/values/file")
export class GetFileFieldValues implements IReturn<GetValuesResponse>
{
    public accountId: string;

    public constructor(init?: Partial<GetFileFieldValues>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFileFieldValues'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetValuesResponse(); }
}

/**
* Get values for endpoint fields
*/
// @Route("/values/endpoint", "GET")
// @Api(Description="Get values for endpoint fields")
export class GetEndpointFieldValues implements IReturn<GetValuesResponse>
{
    public accountId: string;

    public constructor(init?: Partial<GetEndpointFieldValues>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEndpointFieldValues'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetValuesResponse(); }
}

/**
* Get values for phone number fields
*/
// @Route("/values/phone-number", "GET")
// @Api(Description="Get values for phone number fields")
export class GetPhoneNumberFieldValues implements IReturn<GetValuesResponse>
{
    public accountId: string;

    public constructor(init?: Partial<GetPhoneNumberFieldValues>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPhoneNumberFieldValues'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetValuesResponse(); }
}

/**
* Get values for fax number fields
*/
// @Route("/values/fax-number", "GET")
// @Api(Description="Get values for fax number fields")
export class GetFaxNumberFieldValues implements IReturn<GetValuesResponse>
{
    public accountId: string;

    public constructor(init?: Partial<GetFaxNumberFieldValues>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFaxNumberFieldValues'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetValuesResponse(); }
}

/**
* Get the values for time zone fields
*/
// @Route("/values/time-zone", "GET")
// @Api(Description="Get the values for time zone fields")
export class GetTimeZoneFieldValues implements IReturn<GetValuesResponse>
{
    public accountId: string;

    public constructor(init?: Partial<GetTimeZoneFieldValues>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTimeZoneFieldValues'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetValuesResponse(); }
}

/**
* Get the custom values for user fields
*/
// @Route("/values/user", "GET")
// @Api(Description="Get the custom values for user fields")
export class GetUserFieldValues implements IReturn<GetValuesResponse>
{
    public accountId: string;
    public plainText: boolean;
    public fullInfo: boolean;

    public constructor(init?: Partial<GetUserFieldValues>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUserFieldValues'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetValuesResponse(); }
}

/**
* Query for files
*/
// @Route("/files", "GET")
// @Api(Description="Query for files")
export class ListFiles extends ListRequest<FileInfo> implements IReturn<ListResponse<FileInfo>>
{
    /**
    * Filter by account id
    */
    // @ApiMember(Description="Filter by account id")
    public accountIds: string[];

    /**
    * Filter by customer id
    */
    // @ApiMember(Description="Filter by customer id")
    public customerIds: string[];

    /**
    * Filter by specific session
    */
    // @ApiMember(Description="Filter by specific session")
    public sessionId: string;

    /**
    * Filter by file name (contains filter)
    */
    // @ApiMember(Description="Filter by file name (contains filter)")
    public fileName: string;

    /**
    * Filter by content type (contains filter)
    */
    // @ApiMember(Description="Filter by content type (contains filter)")
    public contentType: string;

    /**
    * Filter by type
    */
    // @ApiMember(Description="Filter by type")
    public type?: FileTypes;

    /**
    * Filter by date created (start of range)
    */
    // @ApiMember(Description="Filter by date created (start of range)")
    public dateCreatedStart: string;

    /**
    * Filter by date created (end of range)
    */
    // @ApiMember(Description="Filter by date created (end of range)")
    public dateCreatedEnd: string;

    /**
    * Filter by agent ID
    */
    // @ApiMember(Description="Filter by agent ID")
    public userId: string;

    /**
    * Search transcription
    */
    // @ApiMember(Description="Search transcription")
    public transcriptionContains: string;

    public constructor(init?: Partial<ListFiles>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'ListFiles'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ListResponse<FileInfo>(); }
}

/**
* Updates metadata for the specific file
*/
// @Route("/files/{fileId}", "PATCH")
// @Api(Description="Updates metadata for the specific file")
export class PatchFile implements IReturn<FileInfo>, IPatch
{
    /**
    * The ID of the file
    */
    // @ApiMember(Description="The ID of the file")
    public fileId: string;

    /**
    * The new file name for the file
    */
    // @ApiMember(Description="The new file name for the file")
    public fileName: string;

    /**
    * The customer ID to associate with this file
    */
    // @ApiMember(Description="The customer ID to associate with this file")
    public customerId: string;

    /**
    * The ID of the user to associate with this file
    */
    // @ApiMember(Description="The ID of the user to associate with this file")
    public userId: string;

    public constructor(init?: Partial<PatchFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatchFile'; }
    public getMethod() { return 'PATCH'; }
    public createResponse() { return new FileInfo(); }
}

/**
* Get the specific file
*/
// @Route("/files/{fileId}", "GET")
// @Api(Description="Get the specific file")
export class GetFile implements IReturn<FileInfo>, IGet
{
    /**
    * The ID of the file
    */
    // @ApiMember(Description="The ID of the file")
    public fileId: string;

    public constructor(init?: Partial<GetFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFile'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FileInfo(); }
}

/**
* Delete the specified file
*/
// @Route("/files/{fileId}", "DELETE")
// @Api(Description="Delete the specified file")
export class DeleteFile implements IReturnVoid, IDelete
{
    /**
    * The ID of the file to delete
    */
    // @ApiMember(Description="The ID of the file to delete")
    public fileId: string;

    public constructor(init?: Partial<DeleteFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteFile'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

/**
* Creates a new file. You can either upload directly or via form files multipart
*/
// @Route("/files", "POST")
// @Api(Description="Creates a new file. You can either upload directly or via form files multipart")
export class NewFile implements IReturn<FileInfo>
{
    /**
    * The name of the file
    */
    // @ApiMember(Description="The name of the file")
    public fileName: string;

    /**
    * The account ID for the file
    */
    // @ApiMember(Description="The account ID for the file")
    public accountId: string;

    /**
    * The customer ID for the file
    */
    // @ApiMember(Description="The customer ID for the file")
    public customerId: string;

    public constructor(init?: Partial<NewFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NewFile'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new FileInfo(); }
}

/**
* Export the specified flows into a package
*/
// @Route("/packages", "GET")
// @Api(Description="Export the specified flows into a package")
export class ExportPackage implements IReturn<PackageInfo>
{
    /**
    * The ID of the account
    */
    // @ApiMember(Description="The ID of the account")
    public accountId: string;

    /**
    * The IDs of the flows to export
    */
    // @ApiMember(Description="The IDs of the flows to export")
    public flowIds: string[];

    /**
    * Include all custom fields
    */
    // @ApiMember(Description="Include all custom fields")
    public includeAllCustomFields: boolean;

    public constructor(init?: Partial<ExportPackage>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ExportPackage'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PackageInfo(); }
}

/**
* Import the specified package
*/
// @Route("/packages", "POST")
// @Api(Description="Import the specified package")
export class ImportPackage implements IReturnVoid
{
    /**
    * The account you want to import the package into
    */
    // @ApiMember(Description="The account you want to import the package into")
    public accountId: string;

    /**
    * The package you want to import (should be from a previous export)
    */
    // @ApiMember(Description="The package you want to import (should be from a previous export)")
    public package: PackageInfo;

    public constructor(init?: Partial<ImportPackage>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImportPackage'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* Retrieves a list of URLs for all of the nodes available
*/
// @Route("/flows/available-nodes", "GET")
// @Api(Description="Retrieves a list of URLs for all of the nodes available")
export class ListAvailableNodes implements IReturn<FlowNodeSpec[]>, IGet
{
    /**
    * The ID of the flow you are getting these for
    */
    // @ApiMember(Description="The ID of the flow you are getting these for")
    public flowId: string;

    public constructor(init?: Partial<ListAvailableNodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ListAvailableNodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<FlowNodeSpec>(); }
}

/**
* Gets the possible field names for a specified value source
*/
// @Route("/flows/parameters", "GET")
// @Api(Description="Gets the possible field names for a specified value source")
export class GetParameterFieldNames implements IReturn<GetParameterFieldNamesResponse>, IGet
{
    /**
    * The account ID associated with the flow
    */
    // @ApiMember(Description="The account ID associated with the flow")
    public accountId: string;

    /**
    * Exclude built in fields like customer name
    */
    // @ApiMember(Description="Exclude built in fields like customer name")
    public excludeBuiltInFields: boolean;

    public constructor(init?: Partial<GetParameterFieldNames>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetParameterFieldNames'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetParameterFieldNamesResponse(); }
}

/**
* Create a new flow
*/
// @Route("/flows", "POST")
// @Api(Description="Create a new flow")
export class NewFlow implements IReturn<FlowInfo>, IPost
{
    /**
    * The account ID to associate with the flow
    */
    // @ApiMember(Description="The account ID to associate with the flow")
    public accountId: string;

    /**
    * The ID of the customer to associate with the flow
    */
    // @ApiMember(Description="The ID of the customer to associate with the flow")
    public customerId: string;

    /**
    * The name of the flow
    */
    // @ApiMember(Description="The name of the flow")
    public name: string;

    /**
    * The roles that this flow has
    */
    // @ApiMember(Description="The roles that this flow has")
    public roles: FlowRoles[];

    /**
    * The list of nodes for this flow
    */
    // @ApiMember(Description="The list of nodes for this flow")
    public nodes: FlowNode[];

    public constructor(init?: Partial<NewFlow>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NewFlow'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new FlowInfo(); }
}

/**
* Get the specified flow
*/
// @Route("/flows/{flowId}", "GET")
// @Api(Description="Get the specified flow")
export class GetFlow implements IReturn<FlowInfo>, IGet
{
    /**
    * the ID of the flow to retrieve
    */
    // @ApiMember(Description="the ID of the flow to retrieve")
    public flowId: string;

    public constructor(init?: Partial<GetFlow>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFlow'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FlowInfo(); }
}

/**
* Update the specified flow
*/
// @Route("/flows/{flowId}", "PATCH")
// @Api(Description="Update the specified flow")
export class PatchFlow implements IReturn<FlowInfo>, IPatch
{
    /**
    * the ID of the flow to update
    */
    // @ApiMember(Description="the ID of the flow to update")
    public flowId: string;

    /**
    * The ID of the customer to associate with the flow
    */
    // @ApiMember(Description="The ID of the customer to associate with the flow")
    public customerId: string;

    /**
    * The new name for the flow
    */
    // @ApiMember(Description="The new name for the flow")
    public name: string;

    /**
    * The roles that this flow has
    */
    // @ApiMember(Description="The roles that this flow has")
    public roles: FlowRoles[];

    /**
    * What this flow does
    */
    // @ApiMember(Description="What this flow does")
    public description: string;

    /**
    * Any notes for this flow
    */
    // @ApiMember(Description="Any notes for this flow")
    public notes: string;

    /**
    * The list of nodes for this flow
    */
    // @ApiMember(Description="The list of nodes for this flow")
    public nodes: FlowNode[];

    /**
    * The list of flow exits
    */
    // @ApiMember(Description="The list of flow exits")
    public exits: FlowExit[];

    /**
    * The parameters for this flow
    */
    // @ApiMember(Description="The parameters for this flow")
    public parameters: FlowParameter[];

    /**
    * The time zone ID for this flow
    */
    // @ApiMember(Description="The time zone ID for this flow")
    public timeZoneId: string;

    /**
    * The UI data for the flow
    */
    // @ApiMember(Description="The UI data for the flow")
    public ui: FlowUI;

    /**
    * The list of tag IDs for this flow
    */
    // @ApiMember(Description="The list of tag IDs for this flow")
    public tagIds: string[];

    public constructor(init?: Partial<PatchFlow>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatchFlow'; }
    public getMethod() { return 'PATCH'; }
    public createResponse() { return new FlowInfo(); }
}

/**
* Lists all the flows
*/
// @Route("/flows", "GET")
// @Api(Description="Lists all the flows")
export class ListFlows extends ListRequest<FlowInfo> implements IReturn<ListResponse<FlowInfo>>
{
    /**
    * Filter by accounts
    */
    // @ApiMember(Description="Filter by accounts")
    public accountIds: string[];

    /**
    * The IDs of the customers whose flows you want to retrieve
    */
    // @ApiMember(Description="The IDs of the customers whose flows you want to retrieve")
    public customerIds: string[];

    /**
    * Filter by name
    */
    // @ApiMember(Description="Filter by name")
    public nameFilter: string;

    /**
    * The list of tag IDs to filter by (must contain all)
    */
    // @ApiMember(Description="The list of tag IDs to filter by (must contain all)")
    public tagIds: string[];

    /**
    * List flows by specific role
    */
    // @ApiMember(Description="List flows by specific role")
    public role?: FlowRoles;

    /**
    * Exclude nodes
    */
    // @ApiMember(Description="Exclude nodes")
    public includeNodes: boolean;

    /**
    * Node Type Filter
    */
    // @ApiMember(Description="Node Type Filter")
    public nodeTypeFilter: string;

    public constructor(init?: Partial<ListFlows>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'ListFlows'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ListResponse<FlowInfo>(); }
}

/**
* Make a copy of the flow
*/
// @Route("/flows/{flowId}/copy", "POST")
// @Api(Description="Make a copy of the flow")
export class CopyFlow implements IReturn<FlowInfo>, IPost
{
    /**
    * The ID of the flow to copy
    */
    // @ApiMember(Description="The ID of the flow to copy")
    public flowId: string;

    public constructor(init?: Partial<CopyFlow>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CopyFlow'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new FlowInfo(); }
}

/**
* Deletes the specified flow
*/
// @Route("/flows/{flowId}", "DELETE")
// @Api(Description="Deletes the specified flow")
export class DeleteFlow implements IReturnVoid, IDelete
{
    /**
    * The ID of the flow to delete
    */
    // @ApiMember(Description="The ID of the flow to delete")
    public flowId: string;

    public constructor(init?: Partial<DeleteFlow>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteFlow'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

/**
* Create a new outgoing call and process it with the specified flow. This can only be used for voice sessions. If no caller ID is specified, we will use a random one.
*/
// @Route("/sessions/outgoing", "POST")
// @Api(Description="Create a new outgoing call and process it with the specified flow. This can only be used for voice sessions. If no caller ID is specified, we will use a random one.")
export class NewOutgoingSession implements IReturnVoid, IPost
{
    /**
    * The ID of the flow to use. This flow will be executed as soon as the call is answered.
    */
    // @ApiMember(Description="The ID of the flow to use. This flow will be executed as soon as the call is answered.")
    public flowId: string;

    /**
    * The E164 number to dial, e.g. +18144043093
    */
    // @ApiMember(Description="The E164 number to dial, e.g. +18144043093")
    public numberToDial: string;

    /**
    * The Caller ID to use. This must be the E164 number of an owned phone number
    */
    // @ApiMember(Description="The Caller ID to use. This must be the E164 number of an owned phone number")
    public callerId: string;

    public constructor(init?: Partial<NewOutgoingSession>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NewOutgoingSession'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/sessions/outgoing/callback")
export class NewOutgoingSessionCallback extends TwilioRequestBase implements IReturn<string>
{
    public flowId: string;
    public endpointId: string;

    public constructor(init?: Partial<NewOutgoingSessionCallback>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'NewOutgoingSessionCallback'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return ''; }
}

// @Route("/flows/message")
export class NewFlowMessage implements IReturnVoid
{
    /**
    * The ID of the endpoint that received the message
    */
    // @ApiMember(Description="The ID of the endpoint that received the message")
    public endpointId: string;

    /**
    * The session values
    */
    // @ApiMember(Description="The session values")
    public sessionParameters: Struct;

    /**
    * The from address
    */
    // @ApiMember(Description="The from address")
    public fromAddress: string;

    /**
    * The to address
    */
    // @ApiMember(Description="The to address")
    public toAddress: string;

    /**
    * The identity of the sender
    */
    // @ApiMember(Description="The identity of the sender")
    public identity: string;

    /**
    * The display name of the sender (will default to identity if not specified
    */
    // @ApiMember(Description="The display name of the sender (will default to identity if not specified")
    public displayName: string;

    /**
    * The URL to call when replies need to be sent back
    */
    // @ApiMember(Description="The URL to call when replies need to be sent back")
    public messageUrl: string;

    public constructor(init?: Partial<NewFlowMessage>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NewFlowMessage'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* Restarts the current flow
*/
// @Route("/flows/restart")
// @Api(Description="Restarts the current flow")
export class RestartFlow implements IReturnVoid
{
    /**
    * The ID of the session
    */
    // @ApiMember(Description="The ID of the session")
    public sessionId: string;

    public constructor(init?: Partial<RestartFlow>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RestartFlow'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/health/web", "GET")
export class HealthCheckWeb implements IReturn<HealthStatus>
{
    public token: string;

    public constructor(init?: Partial<HealthCheckWeb>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HealthCheckWeb'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new HealthStatus(); }
}

// @Route("/health/db", "GET")
export class HealthCheckDb implements IReturn<HealthStatus>
{
    public token: string;

    public constructor(init?: Partial<HealthCheckDb>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HealthCheckDb'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new HealthStatus(); }
}

// @Route("/health/twilio", "GET")
export class HealthCheckTwilio implements IReturn<HealthStatus>
{
    public token: string;

    public constructor(init?: Partial<HealthCheckTwilio>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HealthCheckTwilio'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new HealthStatus(); }
}

// @Route("/health/documo", "GET")
export class HealthCheckDocumo implements IReturn<HealthStatus>
{
    public token: string;

    public constructor(init?: Partial<HealthCheckDocumo>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HealthCheckDocumo'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new HealthStatus(); }
}

/**
* Bulk import customers/phone numbers/users with validation of everythin
*/
// @Route("/import/spreadsheet", "POST")
// @Api(Description="Bulk import customers/phone numbers/users with validation of everythin")
export class ImportSpreadsheet implements IReturn<ImportSpreadsheetResult>, IPost
{
    /**
    * The account ID for this import
    */
    // @ApiMember(Description="The account ID for this import")
    public accountId: string;

    /**
    * Set to true to actually apply the changes. Otherwise just a preview is returned
    */
    // @ApiMember(Description="Set to true to actually apply the changes. Otherwise just a preview is returned")
    public applyChanges: boolean;

    public constructor(init?: Partial<ImportSpreadsheet>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImportSpreadsheet'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ImportSpreadsheetResult(); }
}

// @Route("/integrations/{integrationId}/authorize", "GET")
export class AuthorizeIntegration
{
    public integrationId: string;

    public constructor(init?: Partial<AuthorizeIntegration>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AuthorizeIntegration'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

// @Route("/integrations/authorize/callback", "GET")
export class AuthorizeCallback
{
    public state: string;

    public constructor(init?: Partial<AuthorizeCallback>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AuthorizeCallback'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

// @Route("/integrations/{integrationId}/customers")
export class ListCrmCustomers implements IReturn<ListCrmCustomersResponse>
{
    public integrationId: string;
    public query: string;

    public constructor(init?: Partial<ListCrmCustomers>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ListCrmCustomers'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ListCrmCustomersResponse(); }
}

// @Route("/integrations/{integrationId}/contacts")
export class ListCrmContacts implements IReturn<ListCrmContactsResponse>
{
    public integrationId: string;
    public customerId: string;
    public query: string;

    public constructor(init?: Partial<ListCrmContacts>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ListCrmContacts'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ListCrmContactsResponse(); }
}

// @Route("/integrations/{integrationId}/customers/{customerId}/map", "POST")
export class MapCrmCustomer implements IReturnVoid
{
    public integrationId: string;
    public customerId: string;
    public mapsToId: string;

    public constructor(init?: Partial<MapCrmCustomer>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MapCrmCustomer'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/integrations/{integrationId}/users/{userId}/map", "POST")
export class MapCrmUser implements IReturnVoid
{
    public integrationId: string;
    public userId: string;
    public mapsToId: string;

    public constructor(init?: Partial<MapCrmUser>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MapCrmUser'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/integrations/{integrationId}/log")
export class GetIntegrationLog implements IReturn<GetIntegrationLogResponse>
{
    public integrationId: string;

    public constructor(init?: Partial<GetIntegrationLog>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetIntegrationLog'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetIntegrationLogResponse(); }
}

// @Route("/integrations/{integrationId}/sync", "POST")
export class SyncIntegration implements IReturn<SyncIntegrationResult>
{
    public integrationId: string;

    public constructor(init?: Partial<SyncIntegration>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SyncIntegration'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SyncIntegrationResult(); }
}

/**
* Creates a new integration
*/
// @Route("/integrations", "POST")
// @Api(Description="Creates a new integration")
export class NewIntegration implements IReturn<IntegrationInfo>, IPost
{
    /**
    * The account ID to associate this integration with
    */
    // @ApiMember(Description="The account ID to associate this integration with")
    public accountId: string;

    /**
    * The type of integration
    */
    // @ApiMember(Description="The type of integration")
    public type: IntegrationTypes;

    /**
    * The name of the integration
    */
    // @ApiMember(Description="The name of the integration")
    public name: string;

    /**
    * The parent customer for this integration
    */
    // @ApiMember(Description="The parent customer for this integration")
    public customerId: string;

    public constructor(init?: Partial<NewIntegration>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NewIntegration'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new IntegrationInfo(); }
}

/**
* Retrieve integrations
*/
// @Route("/integrations", "GET")
// @Api(Description="Retrieve integrations")
export class ListIntegrations extends ListRequest<IntegrationInfo> implements IReturn<ListResponse<IntegrationInfo>>
{
    /**
    * Filter by accounts
    */
    // @ApiMember(Description="Filter by accounts")
    public accountIds: string[];

    /**
    * Filter by name
    */
    // @ApiMember(Description="Filter by name")
    public nameFilter: string;

    /**
    * The IDs of the parent customers you want to filter by
    */
    // @ApiMember(Description="The IDs of the parent customers you want to filter by")
    public customerIds: string[];

    /**
    * If you want a shall customer integration filter (e.g. no deep children)
    */
    // @ApiMember(Description="If you want a shall customer integration filter (e.g. no deep children)")
    public shallowParent: boolean;

    public constructor(init?: Partial<ListIntegrations>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'ListIntegrations'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ListResponse<IntegrationInfo>(); }
}

/**
* Update a specific integration
*/
// @Route("/integrations/{integrationId}", "PATCH")
// @Api(Description="Update a specific integration")
export class PatchIntegration implements IReturn<IntegrationInfo>, IPatch
{
    /**
    * The ID of the integration you want to update
    */
    // @ApiMember(Description="The ID of the integration you want to update")
    public integrationId: string;

    /**
    * The name for the integration
    */
    // @ApiMember(Description="The name for the integration")
    public name: string;

    /**
    * The parent customer for this integration
    */
    // @ApiMember(Description="The parent customer for this integration")
    public customerId: string;

    /**
    * Automatically create new customers / users when sync'ing with CRM?
    */
    // @ApiMember(Description="Automatically create new customers / users when sync'ing with CRM?")
    public automaticallyCreateCustomers?: boolean;

    /**
    * Settings values for this integration
    */
    // @ApiMember(Description="Settings values for this integration")
    public settings: Struct;

    public constructor(init?: Partial<PatchIntegration>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatchIntegration'; }
    public getMethod() { return 'PATCH'; }
    public createResponse() { return new IntegrationInfo(); }
}

/**
* Get a specific integration
*/
// @Route("/integrations/{integrationId}", "GET")
// @Api(Description="Get a specific integration")
export class GetIntegration implements IReturn<IntegrationInfo>, IGet
{
    /**
    * The ID of the integration you want to get
    */
    // @ApiMember(Description="The ID of the integration you want to get")
    public integrationId: string;

    public constructor(init?: Partial<GetIntegration>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetIntegration'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new IntegrationInfo(); }
}

/**
* Delete the specified integration
*/
// @Route("/integrations/{integrationId}", "DELETE")
// @Api(Description="Delete the specified integration")
export class DeleteIntegration implements IReturnVoid, IDelete
{
    /**
    * The ID of the integration to delete
    */
    // @ApiMember(Description="The ID of the integration to delete")
    public integrationId: string;

    public constructor(init?: Partial<DeleteIntegration>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteIntegration'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/conversations/{conversationId}", "DELETE")
export class DeleteConversation implements IReturnVoid
{
    public conversationId: string;

    public constructor(init?: Partial<DeleteConversation>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteConversation'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/messages/read", "POST")
export class MarkMessagesRead implements IReturnVoid
{
    /**
    * The account ID of the messages to mark read
    */
    // @ApiMember(Description="The account ID of the messages to mark read")
    public accountId: string;

    /**
    * The IDs of the messages to mark read
    */
    // @ApiMember(Description="The IDs of the messages to mark read")
    public messageIds: string[];

    /**
    * The date after which messages should be marked read
    */
    // @ApiMember(Description="The date after which messages should be marked read")
    public afterDate: string;

    public constructor(init?: Partial<MarkMessagesRead>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MarkMessagesRead'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/conversations", "GET")
export class ListConversations implements IReturn<ListConversationsResponse>
{
    /**
    * The IDs of the account whose conversations you want to retrieve
    */
    // @ApiMember(Description="The IDs of the account whose conversations you want to retrieve")
    public accountIds: string[];

    /**
    * The list of endpoint IDs whose conversations you want to retrieve
    */
    // @ApiMember(Description="The list of endpoint IDs whose conversations you want to retrieve")
    public endpointIds: string[];

    /**
    * The list of customer IDs whose conversations you want to retrieve
    */
    // @ApiMember(Description="The list of customer IDs whose conversations you want to retrieve")
    public customerIds: string[];

    /**
    * The start date for the conversations you want to retrieve
    */
    // @ApiMember(Description="The start date for the conversations you want to retrieve")
    public afterDate: string;

    public constructor(init?: Partial<ListConversations>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ListConversations'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ListConversationsResponse(); }
}

// @Route("/messages", "GET")
export class ListMessages extends ListRequest<MessageInfo> implements IReturn<ListResponse<MessageInfo>>
{
    /**
    * The IDs of the account whose messages you want to retrieve
    */
    // @ApiMember(Description="The IDs of the account whose messages you want to retrieve")
    public accountIds: string[];

    /**
    * The IDs of the customers whose messages you want to retrieve
    */
    // @ApiMember(Description="The IDs of the customers whose messages you want to retrieve")
    public customerIds: string[];

    /**
    * The IDs of the endpoints whose messages you want to retrieve
    */
    // @ApiMember(Description="The IDs of the endpoints whose messages you want to retrieve")
    public endpointIds: string[];

    /**
    * The ID of the conversations whose messages you want to retrieve
    */
    // @ApiMember(Description="The ID of the conversations whose messages you want to retrieve")
    public conversationIds: string[];

    /**
    * The date after which messages should be retrieved
    */
    // @ApiMember(Description="The date after which messages should be retrieved")
    public afterDate: string;

    public constructor(init?: Partial<ListMessages>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'ListMessages'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ListResponse<MessageInfo>(); }
}

// @Route("/notifications/{notificationId}/resend", "POST")
export class ResendNotification implements IReturn<NotificationInfo>
{
    /**
    * The ID of the notification to resend
    */
    // @ApiMember(Description="The ID of the notification to resend")
    public notificationId: string;

    public constructor(init?: Partial<ResendNotification>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ResendNotification'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new NotificationInfo(); }
}

/**
* Lists all endpoints
*/
// @Route("/notifications", "GET")
// @Api(Description="Lists all endpoints")
export class ListNotifications extends ListRequest<NotificationInfo> implements IReturn<ListResponse<NotificationInfo>>
{
    /**
    * The IDs of the account whose notifications you want to retrieve
    */
    // @ApiMember(Description="The IDs of the account whose notifications you want to retrieve")
    public accountIds: string[];

    /**
    * The IDs of the customers whose notifications you want to retrieve
    */
    // @ApiMember(Description="The IDs of the customers whose notifications you want to retrieve")
    public customerIds: string[];

    /**
    * Filter by From
    */
    // @ApiMember(Description="Filter by From")
    public fromFilter: string;

    /**
    * Filter by To
    */
    // @ApiMember(Description="Filter by To")
    public toFilter: string;

    /**
    * Filter by Subject
    */
    // @ApiMember(Description="Filter by Subject")
    public subjectFilter: string;

    /**
    * The start date to retrieve usage records for (YYYY-MM-DD)
    */
    // @ApiMember(Description="The start date to retrieve usage records for (YYYY-MM-DD)")
    public startDate: string;

    /**
    * The end date to retrieve usage records for (YYYY-MM-DD)
    */
    // @ApiMember(Description="The end date to retrieve usage records for (YYYY-MM-DD)")
    public endDate: string;

    /**
    * Filter by type
    */
    // @ApiMember(Description="Filter by type")
    public type?: NotificationTypes;

    public constructor(init?: Partial<ListNotifications>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'ListNotifications'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ListResponse<NotificationInfo>(); }
}

// @Route("/not-used")
export class NotUsedRequest
{
    public pushNotification: PushNotification;
    public notifications: NotificationInfo[];
    public nodeParameter: NodeParameter;

    public constructor(init?: Partial<NotUsedRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NotUsedRequest'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* Submits a registration for the current customer's 10DLC
*/
// @Route("/portal/10dlc", "POST")
// @Api(Description="Submits a registration for the current customer's 10DLC")
export class AppRegister10Dlc implements IReturnVoid
{
    public accountId: string;
    public customerId: string;
    public usOrCanada: boolean;
    public taxId: string;
    public taxIdValue: string;
    public businessName: string;
    public firstName: string;
    public lastName: string;
    public email: string;
    public country: string;
    public phoneNumber: string;
    public addressStreet1: string;
    public addressStreet2: string;
    public addressCity: string;
    public addressState: string;
    public addressPostalCode: string;
    public description: string;
    public acceptTerms: boolean;
    public representative: string;
    public websiteUrl: string;
    public businessIndustry: string;
    public businessRegistrationNumber: string;
    public businessRegistrationIDType: string;
    public businessType: string;
    public businesTitle: string;
    public brandName: string;
    public brandType: string;
    public companyType: string;
    public politicalOrganization: string;
    public mobilePhoneNumber: string;
    public stockExchange: string;
    public stockTicker: string;
    public campaignVerifyToken: string;
    public campaign: boolean;
    public campaignDescription: string;
    public sampleMessage1: string;
    public sampleMessage2: string;
    public sampleMessage3: string;
    public sampleMessage4: string;
    public sampleMessage5: string;
    public consentMessage: string;
    public optKeywords: string;
    public optMessage: string;
    public campaignUseCases: string;
    public embeddedLinks: boolean;
    public includePhonenumbers: boolean;
    public loanArrangement: boolean;
    public vertical: string;
    public jobPosition: string;
    public ctia: boolean;
    public businessRegions: string[];
    public brandMobilePhoneNumber: string;

    public constructor(init?: Partial<AppRegister10Dlc>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AppRegister10Dlc'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/portal/fax", "POST")
export class AppSendFax implements IReturnVoid
{
    public accountId: string;
    public customerId: string;
    public endpointId: string;
    public destination: string;
    public notificationEmail: string;

    public constructor(init?: Partial<AppSendFax>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AppSendFax'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/portal/fax/callback")
export class AppSendFaxCallback implements IReturnVoid
{
    public sessionId: string;
    public destination: string;
    public notificationEmail: string;
    public status: string;

    public constructor(init?: Partial<AppSendFaxCallback>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AppSendFaxCallback'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/portal/call-history", "GET")
export class AppGetCallHistory extends ListRequest<SessionInfo> implements IReturn<ListResponse<SessionInfo>>
{
    public accountId: string;
    public customerId: string;

    public constructor(init?: Partial<AppGetCallHistory>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'AppGetCallHistory'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ListResponse<SessionInfo>(); }
}

// @Route("/portal/home", "GET")
export class AppGetHomeInformation implements IReturn<AppHomeInformation>
{
    public accountId: string;
    public customerId: string;
    public fromDate: string;

    public constructor(init?: Partial<AppGetHomeInformation>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AppGetHomeInformation'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AppHomeInformation(); }
}

/**
* Updates metadata for the specific file
*/
// @Route("/portal/files/{fileId}", "PATCH")
// @Api(Description="Updates metadata for the specific file")
export class AppPatchFile implements IReturn<FileInfo>, IPatch
{
    public accountId: string;
    public fileId: string;
    public fileName: string;
    public customerId: string;
    public userId: string;

    public constructor(init?: Partial<AppPatchFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AppPatchFile'; }
    public getMethod() { return 'PATCH'; }
    public createResponse() { return new FileInfo(); }
}

/**
* Creates a new file. You can either upload directly or via form files multipart
*/
// @Route("/app/file", "POST")
// @Api(Description="Creates a new file. You can either upload directly or via form files multipart")
export class AppUploadFile implements IReturn<FileInfo>
{
    public fileName: string;
    public accountId: string;
    public customerId: string;

    public constructor(init?: Partial<AppUploadFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AppUploadFile'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new FileInfo(); }
}

// @Route("/portal/active-sessions", "GET")
export class AppGetActiveSessions implements IReturn<AppGetActiveSessionsResult>
{
    public accountId: string;
    public customerId: string;
    public startDate: string;

    public constructor(init?: Partial<AppGetActiveSessions>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AppGetActiveSessions'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AppGetActiveSessionsResult(); }
}

// @Route("/portal/activity", "GET")
export class AppGetActivity extends ListRequest<SessionInfo> implements IReturn<ListResponse<SessionInfo>>
{
    public accountId: string;
    public customerId: string;
    public fromAddressFilter: string;
    public toAddressFilter: string;
    public startDateTime: string;
    public endDateTime: string;

    public constructor(init?: Partial<AppGetActivity>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'AppGetActivity'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ListResponse<SessionInfo>(); }
}

// @Route("/portal/form-completions", "GET")
export class AppGetFormCompletions extends ListRequest<HostedSuiteCompletedForm> implements IReturn<ListResponse<HostedSuiteCompletedForm>>
{
    public accountId: string;
    public customerId: string;
    public emailSubjectFilter: string;

    public constructor(init?: Partial<AppGetFormCompletions>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'AppGetFormCompletions'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ListResponse<HostedSuiteCompletedForm>(); }
}

// @Route("/portal/schedule")
export class AppGetScheduleInformation implements IReturn<AppScheduleInformation>
{
    public accountId: string;
    public customerId: string;
    public endpointId: string;

    public constructor(init?: Partial<AppGetScheduleInformation>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AppGetScheduleInformation'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AppScheduleInformation(); }
}

// @Route("/portal/phone-numbers", "POST")
export class AppBuyPhoneNumber implements IReturn<EndpointInfo>
{
    public accountId: string;
    public customerId: string;
    public phoneNumberType: EndpointTypes;
    public phoneNumber: string;

    public constructor(init?: Partial<AppBuyPhoneNumber>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AppBuyPhoneNumber'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EndpointInfo(); }
}

// @Route("/portal/search-phone-numbers", "GET")
export class AppSearchPhoneNumbers implements IReturn<AppSearchPhoneNumbersResponse>
{
    public accountId: string;
    public customerId: string;
    public areaCode: string;
    public countryCode: string;
    public postalCode: string;
    public contains: string;
    public distance?: number;
    public phoneNumberType: EndpointTypes;

    public constructor(init?: Partial<AppSearchPhoneNumbers>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AppSearchPhoneNumbers'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AppSearchPhoneNumbersResponse(); }
}

// @Route("/portal/phone-number", "PATCH")
export class AppUpdatePhoneNumber implements IReturn<EndpointInfo>
{
    public phoneNumberId: string;
    public flowId: string;
    public data: Struct;
    public flowParams: Struct;
    public flowSchedule?: EndpointFlowSchedules;
    public schedule: Schedule;
    public name: string;
    public callerIdName: string;

    public constructor(init?: Partial<AppUpdatePhoneNumber>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AppUpdatePhoneNumber'; }
    public getMethod() { return 'PATCH'; }
    public createResponse() { return new EndpointInfo(); }
}

// @Route("/portal/assistants", "POST")
export class AppNewAssistant implements IReturn<EndpointInfo>
{
    public accountId: string;
    public customerId: string;
    public name: string;
    public settings: AssistantSettings;

    public constructor(init?: Partial<AppNewAssistant>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AppNewAssistant'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EndpointInfo(); }
}

// @Route("/portal/assistant", "PATCH")
export class AppUpdateAssistant implements IReturn<EndpointInfo>
{
    public assistantId: string;
    public name: string;
    public settings: AssistantSettings;

    public constructor(init?: Partial<AppUpdateAssistant>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AppUpdateAssistant'; }
    public getMethod() { return 'PATCH'; }
    public createResponse() { return new EndpointInfo(); }
}

// @Route("/portal/flows", "GET")
export class AppGetFlows extends ListRequest<FlowInfo> implements IReturn<ListResponse<FlowInfo>>
{
    public accountId: string;
    public customerId: string;
    public nameFilter: string;
    public role?: FlowRoles;

    public constructor(init?: Partial<AppGetFlows>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'AppGetFlows'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ListResponse<FlowInfo>(); }
}

// @Route("/portal/user/sip-credentials", "DELETE")
export class AppResetSipCredentials implements IReturn<EndpointInfo>
{
    public userId: string;

    public constructor(init?: Partial<AppResetSipCredentials>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AppResetSipCredentials'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new EndpointInfo(); }
}

// @Route("/portal/users", "POST")
export class AppNewUser implements IReturn<EndpointInfo>
{
    public accountId: string;
    public customerId: string;
    public referenceId: string;
    public userMode: UserModes;
    public data: Struct;
    public firstName: string;
    public lastName: string;
    public emailAddress: string;
    public password: string;
    public flowId: string;
    public flowParams: Struct;

    public constructor(init?: Partial<AppNewUser>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AppNewUser'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EndpointInfo(); }
}

// @Route("/portal/user", "PATCH")
export class AppUpdateUser implements IReturn<EndpointInfo>
{
    public userId: string;
    public firstName: string;
    public lastName: string;
    public emailAddress: string;
    public password: string;
    public data: Struct;
    public flowId: string;

    public constructor(init?: Partial<AppUpdateUser>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AppUpdateUser'; }
    public getMethod() { return 'PATCH'; }
    public createResponse() { return new EndpointInfo(); }
}

// @Route("/portal/system-settings", "PATCH")
export class AppUpdateSystemSettings implements IReturn<AppSystemSettingsInfo>
{
    public accountId: string;
    public customerId: string;
    public customerTimeZoneId: string;

    public constructor(init?: Partial<AppUpdateSystemSettings>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AppUpdateSystemSettings'; }
    public getMethod() { return 'PATCH'; }
    public createResponse() { return new AppSystemSettingsInfo(); }
}

// @Route("/portal/system-settings")
export class AppGetSystemSettings implements IReturn<AppSystemSettingsInfo>
{
    public accountId: string;
    public customerId: string;

    public constructor(init?: Partial<AppGetSystemSettings>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AppGetSystemSettings'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AppSystemSettingsInfo(); }
}

// @Route("/portal/logo", "GET")
export class AppGetLogo implements IReturn<AppGetLogoResponse>
{
    public accountId: string;

    public constructor(init?: Partial<AppGetLogo>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AppGetLogo'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AppGetLogoResponse(); }
}

// @Route("/portal/endpoints/{endpointId}", "DELETE")
export class AppDeleteEndpoint implements IReturnVoid
{
    public accountId: string;
    public customerId: string;
    public endpointId: string;

    public constructor(init?: Partial<AppDeleteEndpoint>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AppDeleteEndpoint'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/portal/files/{fileId}", "DELETE")
export class AppDeleteFile implements IReturnVoid
{
    public accountId: string;
    public customerId: string;
    public fileId: string;

    public constructor(init?: Partial<AppDeleteFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AppDeleteFile'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/portal/files", "GET")
export class AppGetFiles extends ListRequest<FileInfo> implements IReturn<ListResponse<FileInfo>>
{
    public accountId: string;
    public customerId: string;
    public dateCreatedStart: string;
    public dateCreatedEnd: string;
    public fileNameFilter: string;
    public type?: FileTypes;

    public constructor(init?: Partial<AppGetFiles>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'AppGetFiles'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ListResponse<FileInfo>(); }
}

// @Route("/portal/users", "GET")
export class AppGetUsers extends ListRequest<EndpointInfo> implements IReturn<ListResponse<EndpointInfo>>
{
    public accountId: string;
    public customerId: string;
    public userMode?: UserModes;
    public name: string;

    public constructor(init?: Partial<AppGetUsers>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'AppGetUsers'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ListResponse<EndpointInfo>(); }
}

// @Route("/portal/phone-numbers", "GET")
export class AppGetPhoneNumbers extends ListRequest<EndpointInfo> implements IReturn<ListResponse<EndpointInfo>>
{
    public accountId: string;
    public customerId: string;
    public phoneNumberFilter: string;
    public typeFilter?: EndpointTypes;

    public constructor(init?: Partial<AppGetPhoneNumbers>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'AppGetPhoneNumbers'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ListResponse<EndpointInfo>(); }
}

// @Route("/portal/assistants", "GET")
export class AppGetAssistants extends ListRequest<EndpointInfo> implements IReturn<ListResponse<EndpointInfo>>
{
    public accountId: string;
    public customerId: string;
    public nameFilter: string;

    public constructor(init?: Partial<AppGetAssistants>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'AppGetAssistants'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ListResponse<EndpointInfo>(); }
}

// @Route("/health")
export class HealthCheck
{

    public constructor(init?: Partial<HealthCheck>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HealthCheck'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

// @Route("/resend-notification")
export class ResendNotificationPublic
{
    public notificationId: string;

    public constructor(init?: Partial<ResendNotificationPublic>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ResendNotificationPublic'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

export class GetSystemInfo implements IReturn<SystemInfo>
{

    public constructor(init?: Partial<GetSystemInfo>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSystemInfo'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SystemInfo(); }
}

/**
* Sends a password recovery email to the email address on file
*/
// @Route("/auth/forgot-password", "POST")
// @Api(Description="Sends a password recovery email to the email address on file")
export class ForgotPassword implements IReturnVoid, IPost
{
    /**
    * The email address associated with the account you want to recover
    */
    // @ApiMember(Description="The email address associated with the account you want to recover")
    public emailAddress: string;

    public constructor(init?: Partial<ForgotPassword>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ForgotPassword'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* Reset an account's password using the token sent via email
*/
// @Route("/auth/recover", "POST")
// @Api(Description="Reset an account's password using the token sent via email")
export class RecoverPassword implements IReturnVoid, IPost
{
    /**
    * The email address being recovered
    */
    // @ApiMember(Description="The email address being recovered")
    public emailAddress: string;

    /**
    * The token sent via email
    */
    // @ApiMember(Description="The token sent via email")
    public token: string;

    /**
    * New Password
    */
    // @ApiMember(Description="New Password")
    public newPassword: string;

    public constructor(init?: Partial<RecoverPassword>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RecoverPassword'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* Lists the available countries for phone numbers
*/
// @Route("/system/countries")
// @Api(Description="Lists the available countries for phone numbers")
export class ListAvailableCountries implements IReturn<AvailableCountryInfo[]>
{

    public constructor(init?: Partial<ListAvailableCountries>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ListAvailableCountries'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<AvailableCountryInfo>(); }
}

/**
* Gets all of the system time zone IDs and names
*/
// @Route("/system/time-zones", "GET")
// @Api(Description="Gets all of the system time zone IDs and names")
export class ListSystemTimeZones implements IReturn<SystemTimeZoneInfo[]>, IGet
{

    public constructor(init?: Partial<ListSystemTimeZones>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ListSystemTimeZones'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<SystemTimeZoneInfo>(); }
}

/**
* Deletes the specified report. Will stop the report if it's being processed
*/
// @Route("/reports/{reportId}", "DELETE")
// @Api(Description="Deletes the specified report. Will stop the report if it's being processed")
export class DeleteReport implements IReturnVoid
{
    public reportId: string;

    public constructor(init?: Partial<DeleteReport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteReport'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

/**
* Returns all of the active/completed reports for the current user
*/
// @Route("/reports", "GET")
// @Api(Description="Returns all of the active/completed reports for the current user")
export class ListReports implements IReturn<ListReportsResponse>
{
    public accountId: string;

    public constructor(init?: Partial<ListReports>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ListReports'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ListReportsResponse(); }
}

/**
* Get the specified report as a download
*/
// @Route("/reports/{reportId}.xlsx", "GET")
// @Api(Description="Get the specified report as a download")
export class DownloadReport
{
    public reportId: string;

    public constructor(init?: Partial<DownloadReport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DownloadReport'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

// @Route("/reports/call-outcome")
export class CallOutcomeReport extends ReportRequest implements IReturn<ReportInfo>
{
    public startDate: string;
    public endDate: string;
    public timeZoneId: string;
    public customerId: string;
    public specificState: string;
    public includeArchivedSessions: boolean;

    public constructor(init?: Partial<CallOutcomeReport>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'CallOutcomeReport'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ReportInfo(); }
}

// @Route("/reports/metric")
export class CustomMetricReport extends ReportRequest implements IReturn<ReportInfo>
{
    public startDate: string;
    public endDate: string;
    public metricName: string;
    public timeZoneId: string;

    public constructor(init?: Partial<CustomMetricReport>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'CustomMetricReport'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ReportInfo(); }
}

// @Route("/reports/call-center-abandon")
export class CallCenterAbandonReport extends ReportRequest implements IReturn<ReportInfo>
{
    public startDate: string;
    public endDate: string;
    public timeZoneId: string;

    public constructor(init?: Partial<CallCenterAbandonReport>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'CallCenterAbandonReport'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ReportInfo(); }
}

// @Route("/reports/agent-state")
export class AgentStateReport extends ReportRequest implements IReturn<ReportInfo>
{
    public startDate: string;
    public endDate: string;
    public timeZoneId: string;

    public constructor(init?: Partial<AgentStateReport>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'AgentStateReport'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ReportInfo(); }
}

// @Route("/reports/cdr")
export class CdrReport extends ReportRequest implements IReturn<ReportInfo>
{
    public timeZoneId: string;
    public startDate: string;
    public endDate: string;
    public customerId: string;

    public constructor(init?: Partial<CdrReport>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'CdrReport'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ReportInfo(); }
}

// @Route("/reports/call-center")
export class CallCenterReport extends ReportRequest implements IReturn<ReportInfo>
{
    public startDate: string;
    public endDate: string;
    public customerId: string;

    public constructor(init?: Partial<CallCenterReport>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'CallCenterReport'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ReportInfo(); }
}

// @Route("/reports/call-center-detail")
export class CallCenterDetailReport extends ReportRequest implements IReturn<ReportInfo>
{
    public startDate: string;
    public endDate: string;
    public timeZoneId: string;
    public customerId: string;

    public constructor(init?: Partial<CallCenterDetailReport>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'CallCenterDetailReport'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ReportInfo(); }
}

// @Route("/reports/billing")
export class BillingReport extends ReportRequest implements IReturn<ReportInfo>
{
    public startDate: string;
    public endDate: string;

    public constructor(init?: Partial<BillingReport>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'BillingReport'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ReportInfo(); }
}

/**
* This report returns all of the endpoints broken down by type per tab
*/
// @Route("/reports/endpoints", "GET")
// @Api(Description="This report returns all of the endpoints broken down by type per tab")
export class EndpointsReport extends ReportRequest implements IReturn<ReportInfo>
{
    /**
    * The types of endpoints to return
    */
    // @ApiMember(Description="The types of endpoints to return")
    public types: EndpointTypes[];

    /**
    * Filter by specific customer
    */
    // @ApiMember(Description="Filter by specific customer")
    public customerId: string;

    public constructor(init?: Partial<EndpointsReport>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'EndpointsReport'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ReportInfo(); }
}

// @Route("/reports/sync-phone-numbers")
export class SyncPhoneNumbersReport extends ReportRequest implements IReturn<ReportInfo>
{
    public syncDocumoNumbers: boolean;

    public constructor(init?: Partial<SyncPhoneNumbersReport>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'SyncPhoneNumbersReport'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ReportInfo(); }
}

// @Route("/presence")
export class GetPresenceStatusRequest implements IReturn<GetPresenceStatusResponse>
{
    /**
    * The account to retrieve presence status for
    */
    // @ApiMember(Description="The account to retrieve presence status for")
    public accountId: string;

    /**
    * The extensions to retrieve presence status for
    */
    // @ApiMember(Description="The extensions to retrieve presence status for")
    public extensions: string[];

    public constructor(init?: Partial<GetPresenceStatusRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPresenceStatusRequest'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetPresenceStatusResponse(); }
}

/**
* Adds the specified log entry to the session log.
*/
// @Route("/sessions/{sessionId}/log", "POST")
// @Api(Description="Adds the specified log entry to the session log.")
export class AddToSessionLog implements IReturnVoid
{
    /**
    * The ID of the active session
    */
    // @ApiMember(Description="The ID of the active session")
    public sessionId: string;

    /**
    * The log text to add
    */
    // @ApiMember(Description="The log text to add")
    public logText: string;

    public constructor(init?: Partial<AddToSessionLog>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddToSessionLog'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* Converts the specified call into a conference call if necessary and dials the specified member.
*/
// @Route("/sessions/{sessionId}/conference/members", "POST")
// @Api(Description="Converts the specified call into a conference call if necessary and dials the specified member.")
export class AddConferenceMember implements IReturn<SessionInfo>
{
    /**
    * The ID of the active session
    */
    // @ApiMember(Description="The ID of the active session")
    public sessionId: string;

    /**
    * The sid of the active call that is on the voice device
    */
    // @ApiMember(Description="The sid of the active call that is on the voice device")
    public callSid: string;

    /**
    * The party to invite to the conference
    */
    // @ApiMember(Description="The party to invite to the conference")
    public destination: string;

    /**
    * The FROM (caller ID) to use
    */
    // @ApiMember(Description="The FROM (caller ID) to use")
    public fromNumber: string;

    public constructor(init?: Partial<AddConferenceMember>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddConferenceMember'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SessionInfo(); }
}

// @Route("/sessions/{sessionId}/conference/member/callback")
export class AddConferenceMemberCallback extends TwilioRequestBase implements IReturn<string>
{
    public sessionId: string;
    public destination: string;
    public fromNumber: string;

    public constructor(init?: Partial<AddConferenceMemberCallback>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'AddConferenceMemberCallback'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return ''; }
}

/**
* Clears out sessions.
*/
// @Route("/sessions", "DELETE")
// @Api(Description="Clears out sessions.")
export class DeleteSessions implements IReturnVoid, IDelete
{
    /**
    * The account ID you are clearing out
    */
    // @ApiMember(Description="The account ID you are clearing out")
    public accountId: string;

    /**
    * The start date to delete from in ISO8601
    */
    // @ApiMember(Description="The start date to delete from in ISO8601")
    public startDateTime: string;

    /**
    * The end date to delete from in ISO8601
    */
    // @ApiMember(Description="The end date to delete from in ISO8601")
    public endDateTime: string;

    public constructor(init?: Partial<DeleteSessions>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteSessions'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

/**
* Sets the authenticated user's agent state
*/
// @Route("/agent/state", "POST")
// @Api(Description="Sets the authenticated user's agent state")
export class SetAgentState implements IReturn<EndpointInfo>, IPost
{
    /**
    * The ID of the endpoint whose state to set. If not specified, uses the authenticated user
    */
    // @ApiMember(Description="The ID of the endpoint whose state to set. If not specified, uses the authenticated user")
    public endpointId: string;

    /**
    * The state to set
    */
    // @ApiMember(Description="The state to set")
    public state: AgentStates;

    public constructor(init?: Partial<SetAgentState>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetAgentState'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EndpointInfo(); }
}

/**
* Get your active sessions. Will eventually replace GetMySessions
*/
// @Route("/sessions/active", "GET")
// @Api(Description="Get your active sessions. Will eventually replace GetMySessions")
export class GetActiveSessions implements IReturn<GetActiveSessionsResponse>
{

    public constructor(init?: Partial<GetActiveSessions>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetActiveSessions'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetActiveSessionsResponse(); }
}

/**
* Get all your active sessions
*/
// @Route("/sessions/my", "GET")
// @Api(Description="Get all your active sessions")
export class GetMySessions implements IReturn<GetMySessionsResponse>, IGet
{

    public constructor(init?: Partial<GetMySessions>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMySessions'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetMySessionsResponse(); }
}

/**
* Gets the specified transcript
*/
// @Route("/sessions/{sessionId}/transcript", "GET")
// @Api(Description="Gets the specified transcript")
export class GetChatTranscript implements IReturn<GetChatTranscriptResponse>, IGet
{
    /**
    * the session whose transcript you want to get
    */
    // @ApiMember(Description="the session whose transcript you want to get")
    public sessionId: string;

    /**
    * Include previous messages from this chat party
    */
    // @ApiMember(Description="Include previous messages from this chat party")
    public includeHistorical?: boolean;

    /**
    * How far back to include in historical messages. Max 6 months in past
    */
    // @ApiMember(Description="How far back to include in historical messages. Max 6 months in past")
    public historicalCutOffDate: string;

    public constructor(init?: Partial<GetChatTranscript>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetChatTranscript'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetChatTranscriptResponse(); }
}

// @Route("/sessions/{sessionId}/messages", "POST")
// @Route("/messages")
export class SendMessage implements IReturn<MessageInfo>
{
    /**
    * The ID of the session you want to post a message to. Specify either this or EndpointId+ToAddress
    */
    // @ApiMember(Description="The ID of the session you want to post a message to. Specify either this or EndpointId+ToAddress")
    public sessionId: string;

    /**
    * The ID of the endpoint you want to post a message to. Typically the ID of a phone number endpoint. Not used if SessionId is specified
    */
    // @ApiMember(Description="The ID of the endpoint you want to post a message to. Typically the ID of a phone number endpoint. Not used if SessionId is specified")
    public endpointId: string;

    /**
    * The address of the party you want to send a message to, e.g. +15556667777. Not used if SessionId is specified
    */
    // @ApiMember(Description="The address of the party you want to send a message to, e.g. +15556667777. Not used if SessionId is specified")
    public toAddress: string;

    /**
    * The body of the message
    */
    // @ApiMember(Description="The body of the message")
    public body: string;

    public constructor(init?: Partial<SendMessage>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendMessage'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MessageInfo(); }
}

/**
* DESCRIPTION
*/
// @Route("/session/{sessionId}/members", "DELETE")
// @Api(Description="DESCRIPTION")
export class LeaveSession implements IReturnVoid, IDelete
{
    /**
    * The ID of the session you want to leave
    */
    // @ApiMember(Description="The ID of the session you want to leave")
    public sessionId: string;

    public constructor(init?: Partial<LeaveSession>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LeaveSession'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

/**
* Moves the specified session into a conference room by name
*/
// @Route("/sessions/{sessionId}/conference", "POST")
// @Api(Description="Moves the specified session into a conference room by name")
export class ConferenceSession implements IReturnVoid, IPost
{
    /**
    * The ID of the session that is being moved
    */
    // @ApiMember(Description="The ID of the session that is being moved")
    public sessionId: string;

    /**
    * The sid of the active call that is on the voice device
    */
    // @ApiMember(Description="The sid of the active call that is on the voice device")
    public callSid: string;

    /**
    * The name of the conference room
    */
    // @ApiMember(Description="The name of the conference room")
    public queueName: string;

    public constructor(init?: Partial<ConferenceSession>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConferenceSession'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* Create a new session
*/
// @Route("/sessions", "POST")
// @Api(Description="Create a new session")
export class NewSession implements IReturn<SessionInfo>, IPost
{
    /**
    * A display name for this session
    */
    // @ApiMember(Description="A display name for this session")
    public displayName: string;

    /**
    * The type of session
    */
    // @ApiMember(Description="The type of session")
    public channel: FlowChannels;

    /**
    * The session direction (defaults to Outgoing)
    */
    // @ApiMember(Description="The session direction (defaults to Outgoing)")
    public direction?: SessionDirections;

    /**
    * The current session call state (defaults to Connected)
    */
    // @ApiMember(Description="The current session call state (defaults to Connected)")
    public callState?: SessionCallState;

    /**
    * The From address for the session (defaults to user's email)
    */
    // @ApiMember(Description="The From address for the session (defaults to user's email)")
    public fromAddress: string;

    /**
    * The From name for the session
    */
    // @ApiMember(Description="The From name for the session")
    public fromName: string;

    /**
    * The To address for the session
    */
    // @ApiMember(Description="The To address for the session")
    public toAddress: string;

    /**
    * Parent session (e.g. for billing purposes)
    */
    // @ApiMember(Description="Parent session (e.g. for billing purposes)")
    public parentSessionId: string;

    /**
    * Specify a customer to associate with this session
    */
    // @ApiMember(Description="Specify a customer to associate with this session")
    public customerId: string;

    /**
    * Start a chat session with a specified user/team (e.g. user:userid or team:teamid)
    */
    // @ApiMember(Description="Start a chat session with a specified user/team (e.g. user:userid or team:teamid)")
    public withAddress: string;

    /**
    * Use the specified caller ID (if dialing out)
    */
    // @ApiMember(Description="Use the specified caller ID (if dialing out)")
    public fromPhoneNumber: string;

    /**
    * The dialed destination value
    */
    // @ApiMember(Description="The dialed destination value")
    public destination: string;

    /**
    * User email addresses to add as members
    */
    // @ApiMember(Description="User email addresses to add as members")
    public memberEmailAddresses: string[];

    /**
    * Start the session's flow?
    */
    // @ApiMember(Description="Start the session's flow?")
    public startSession: boolean;

    public constructor(init?: Partial<NewSession>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NewSession'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SessionInfo(); }
}

/**
* Redirect the session to a new destination
*/
// @Route("/sessions/{sessionId}/redirect", "POST")
// @Api(Description="Redirect the session to a new destination")
export class RedirectSession implements IReturnVoid, IPost
{
    public sessionId: string;
    public destination: string;
    public callerId: string;

    public constructor(init?: Partial<RedirectSession>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RedirectSession'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* Removes the specified member from the chat
*/
// @Route("/sessions/{sessionId}/members/{memberId}", "DELETE")
// @Api(Description="Removes the specified member from the chat")
export class DeleteChatMember implements IReturn<SessionInfo>, IDelete
{
    /**
    * The ID of the session
    */
    // @ApiMember(Description="The ID of the session")
    public sessionId: string;

    /**
    * The member to add. This can be a cell phone number, user ID (email)
    */
    // @ApiMember(Description="The member to add. This can be a cell phone number, user ID (email)")
    public memberToRemove: string;

    public constructor(init?: Partial<DeleteChatMember>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteChatMember'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new SessionInfo(); }
}

/**
* Add a member to the chat session
*/
// @Route("/sessions/{sessionId}/members", "POST")
// @Api(Description="Add a member to the chat session")
export class InviteChatMember implements IReturn<SessionInfo>, IPost
{
    /**
    * The ID of the session
    */
    // @ApiMember(Description="The ID of the session")
    public sessionId: string;

    /**
    * The member to add. This can be a cell phone number, user ID (email)
    */
    // @ApiMember(Description="The member to add. This can be a cell phone number, user ID (email)")
    public memberToAdd: string;

    /**
    * Outbound caller ID to use
    */
    // @ApiMember(Description="Outbound caller ID to use")
    public callerId: string;

    public constructor(init?: Partial<InviteChatMember>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InviteChatMember'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SessionInfo(); }
}

/**
* Ends the specified session
*/
// @Route("/sessions/{sessionId}", "DELETE")
// @Api(Description="Ends the specified session")
export class EndSession implements IReturnVoid, IDelete
{
    /**
    * The specified session ID
    */
    // @ApiMember(Description="The specified session ID")
    public sessionId: string;

    /**
    * The local call ID
    */
    // @ApiMember(Description="The local call ID")
    public callSid: string;

    public constructor(init?: Partial<EndSession>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EndSession'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

/**
* Start call recording for the specified session. If already started, this will not do anything
*/
// @Route("/sessions/{sessionId}/record", "POST")
// @Api(Description="Start call recording for the specified session. If already started, this will not do anything")
export class RecordSession implements IReturnVoid, IPost
{
    public sessionId: string;

    public constructor(init?: Partial<RecordSession>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RecordSession'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* Reject the specified session. Only valid on queue calls.
*/
// @Route("/sessions/{sessionId}/reject", "POST")
// @Api(Description="Reject the specified session. Only valid on queue calls.")
export class RejectSession implements IReturn<SessionInfo>, IPost
{
    /**
    * The ID of the session to reject
    */
    // @ApiMember(Description="The ID of the session to reject")
    public sessionId: string;

    public constructor(init?: Partial<RejectSession>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RejectSession'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SessionInfo(); }
}

// @Route("/sessions/{sessionId}", "PATCH")
export class PatchSession implements IReturn<SessionInfo>
{
    public sessionId: string;
    public consoleData: string;
    public callState?: SessionCallState;
    public holdReason?: SessionHoldReasons;
    public queueState?: SessionQueueStates;

    public constructor(init?: Partial<PatchSession>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatchSession'; }
    public getMethod() { return 'PATCH'; }
    public createResponse() { return new SessionInfo(); }
}

/**
* Sets the conference member's hold and or mute status.
*/
// @Route("/sessions/{sessionId}/conference/members/{callSid}", "PATCH")
// @Api(Description="Sets the conference member's hold and or mute status.")
export class UpdateConferenceMemberStatus implements IReturnVoid
{
    /**
    * The ID of the active session
    */
    // @ApiMember(Description="The ID of the active session")
    public sessionId: string;

    /**
    * The sid of the active call that is on the voice device
    */
    // @ApiMember(Description="The sid of the active call that is on the voice device")
    public callSid: string;

    /**
    * Hold?
    */
    // @ApiMember(Description="Hold?")
    public hold?: boolean;

    /**
    * Mute?
    */
    // @ApiMember(Description="Mute?")
    public mute?: boolean;

    public constructor(init?: Partial<UpdateConferenceMemberStatus>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateConferenceMemberStatus'; }
    public getMethod() { return 'PATCH'; }
    public createResponse() {}
}

/**
* Hold the specified session
*/
// @Route("/sessions/{sessionId}/hold", "POST")
// @Api(Description="Hold the specified session")
export class HoldSession implements IReturn<SessionInfo>, IPost
{
    /**
    * The local call SID
    */
    // @ApiMember(Description="The local call SID")
    public callSid: string;

    /**
    * The ID of the session to hold
    */
    // @ApiMember(Description="The ID of the session to hold")
    public sessionId: string;

    /**
    * The reason you are holding this call
    */
    // @ApiMember(Description="The reason you are holding this call")
    public reason: SessionHoldReasons;

    public constructor(init?: Partial<HoldSession>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HoldSession'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SessionInfo(); }
}

// @Route("/session/wait/callback")
export class SessionWaitCallback extends TwilioRequestBase implements IReturn<string>
{
    public holdMusicUrl: string;
    public sessionId: string;

    public constructor(init?: Partial<SessionWaitCallback>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'SessionWaitCallback'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return ''; }
}

// @Route("/session/hold/callback")
export class SessionHoldCallback extends TwilioRequestBase implements IReturn<string>
{
    public sessionId: string;
    public holdingCallSid: string;

    public constructor(init?: Partial<SessionHoldCallback>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'SessionHoldCallback'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return ''; }
}

/**
* Get the specified session
*/
// @Route("/sessions/{sessionId}", "GET")
// @Api(Description="Get the specified session")
export class GetSession implements IReturn<SessionInfo>, IGet
{
    /**
    * The ID of the session to retrieve
    */
    // @ApiMember(Description="The ID of the session to retrieve")
    public sessionId: string;

    public constructor(init?: Partial<GetSession>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSession'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SessionInfo(); }
}

/**
* Retrieve a list of sessions
*/
// @Route("/sessions", "GET")
// @Api(Description="Retrieve a list of sessions")
export class ListSessions extends ListRequest<SessionInfo> implements IReturn<ListResponse<SessionInfo>>
{
    /**
    * The account IDs for the sessions to retrieve
    */
    // @ApiMember(Description="The account IDs for the sessions to retrieve")
    public accountIds: string[];

    /**
    * Whether to search live or archived sessions
    */
    // @ApiMember(Description="Whether to search live or archived sessions")
    public searchArchive: boolean;

    /**
    * The start date to retrieve usage records for (YYYY-MM-DD)
    */
    // @ApiMember(Description="The start date to retrieve usage records for (YYYY-MM-DD)")
    public startDate: string;

    /**
    * The end date to retrieve usage records for (YYYY-MM-DD)
    */
    // @ApiMember(Description="The end date to retrieve usage records for (YYYY-MM-DD)")
    public endDate: string;

    /**
    * The IDs of the customers whose sessions you want to retrieve
    */
    // @ApiMember(Description="The IDs of the customers whose sessions you want to retrieve")
    public customerIds: string[];

    /**
    * The IDs of the endpoints whose sessions you want to retrieve
    */
    // @ApiMember(Description="The IDs of the endpoints whose sessions you want to retrieve")
    public endpointIds: string[];

    /**
    * Filter by the from  (this is a contains search)
    */
    // @ApiMember(Description="Filter by the from  (this is a contains search)")
    public from: string;

    /**
    * Filter by the to  (this is a contains search)
    */
    // @ApiMember(Description="Filter by the to  (this is a contains search)")
    public to: string;

    /**
    * Filter by text in the log
    */
    // @ApiMember(Description="Filter by text in the log")
    public log: string;

    /**
    * Filter by parent session ID
    */
    // @ApiMember(Description="Filter by parent session ID")
    public parentSessionId: string;

    public constructor(init?: Partial<ListSessions>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'ListSessions'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ListResponse<SessionInfo>(); }
}

// @Route("/settings/twilio/accounts/sync")
export class SyncTwilioAccounts implements IReturnVoid
{

    public constructor(init?: Partial<SyncTwilioAccounts>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SyncTwilioAccounts'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/addresses", "GET")
export class ListAddresses implements IReturn<ListAddressesResponse>
{
    /**
    * The account ID whose addresses you want
    */
    // @ApiMember(Description="The account ID whose addresses you want")
    public accountId: string;

    public constructor(init?: Partial<ListAddresses>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ListAddresses'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ListAddressesResponse(); }
}

/**
* Delete the specified address
*/
// @Route("/addresses/{addressId}", "DELETE")
// @Api(Description="Delete the specified address")
export class DeleteAddress implements IReturnVoid
{
    public accountId: string;
    /**
    * The ID of the address you want to delete
    */
    // @ApiMember(Description="The ID of the address you want to delete")
    public addressId: string;

    public constructor(init?: Partial<DeleteAddress>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteAddress'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

/**
* Update a business address to be used with e911
*/
// @Route("/addresses/{addressId}", "POST")
// @Api(Description="Update a business address to be used with e911")
export class PatchAddress implements IReturn<AddressInfo>
{
    public accountId: string;
    public addressId: string;
    public friendlyName: string;
    public emergencyEnabled: boolean;
    public customerName: string;
    public street: string;
    public city: string;
    public region: string;
    public postalCode: string;
    public isoCountry: string;

    public constructor(init?: Partial<PatchAddress>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatchAddress'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AddressInfo(); }
}

/**
* Create a new business address to be used with e911
*/
// @Route("/addresses", "POST")
// @Api(Description="Create a new business address to be used with e911")
export class NewAddress implements IReturn<AddressInfo>
{
    public accountId: string;
    public friendlyName: string;
    public emergencyEnabled: boolean;
    public customerName: string;
    public street: string;
    public city: string;
    public region: string;
    public postalCode: string;
    public isoCountry: string;

    public constructor(init?: Partial<NewAddress>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NewAddress'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AddressInfo(); }
}

/**
* Send a test email from the specified account
*/
// @Route("/settings/test-email", "POST")
// @Api(Description="Send a test email from the specified account")
export class SendTestEmail implements IReturnVoid, IPost
{
    /**
    * The email account to send from
    */
    // @ApiMember(Description="The email account to send from")
    public account: EmailAccount;

    /**
    * The user to send to
    */
    // @ApiMember(Description="The user to send to")
    public emailAddress: string;

    public constructor(init?: Partial<SendTestEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendTestEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* Get all of the country based dialing permissions for this account
*/
// @Route("/system/dialing-permissions", "GET")
// @Api(Description="Get all of the country based dialing permissions for this account")
export class GetDialingPermissions implements IReturn<GetDialingPermissionsResponse>, IGet
{
    /**
    * The account ID you want to retrieve for
    */
    // @ApiMember(Description="The account ID you want to retrieve for")
    public accountId: string;

    public constructor(init?: Partial<GetDialingPermissions>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDialingPermissions'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDialingPermissionsResponse(); }
}

/**
* Update the specified dialing permissions
*/
// @Route("/system/dialing-permissions/{isoCode}", "PATCH")
// @Api(Description="Update the specified dialing permissions")
export class PatchDialingPermissions implements IReturnVoid, IPatch
{
    /**
    * The account ID you are modifying
    */
    // @ApiMember(Description="The account ID you are modifying")
    public accountId: string;

    /**
    * The ISO code to patch
    */
    // @ApiMember(Description="The ISO code to patch")
    public isoCode: string;

    /**
    * If low risk numbers within this code are enabled
    */
    // @ApiMember(Description="If low risk numbers within this code are enabled")
    public lowRiskNumbersEnabled: boolean;

    /**
    * If high risk special numbers within this code are enabled
    */
    // @ApiMember(Description="If high risk special numbers within this code are enabled")
    public highRiskSpecialNumbersEnabled: boolean;

    /**
    * If high risk toll fraud within this code are enabled
    */
    // @ApiMember(Description="If high risk toll fraud within this code are enabled")
    public highRiskTollfraudNumbersEnabled: boolean;

    public constructor(init?: Partial<PatchDialingPermissions>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatchDialingPermissions'; }
    public getMethod() { return 'PATCH'; }
    public createResponse() {}
}

/**
* Gets the system settings
*/
// @Route("/system/settings", "GET")
// @Api(Description="Gets the system settings")
export class GetSystemSettings implements IReturn<SystemSettingsInfo>, IGet
{
    /**
    * The account ID whose system settings you want
    */
    // @ApiMember(Description="The account ID whose system settings you want")
    public accountId: string;

    public constructor(init?: Partial<GetSystemSettings>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSystemSettings'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SystemSettingsInfo(); }
}

/**
* Updates the system settings
*/
// @Route("/system/settings", "PATCHY")
// @Api(Description="Updates the system settings")
export class PatchSystemSettings implements IReturn<SystemSettingsInfo>, IPatch
{
    /**
    * Use to override the system time for testing
    */
    // @ApiMember(Description="Use to override the system time for testing")
    public overrideSystemTime?: boolean;

    /**
    * The system override time zone
    */
    // @ApiMember(Description="The system override time zone")
    public overrideSystemTimeZoneId: string;

    /**
    * The system override date/time
    */
    // @ApiMember(Description="The system override date/time")
    public overrideSystemDateTime: string;

    /**
    * The account ID of the system settings
    */
    // @ApiMember(Description="The account ID of the system settings")
    public accountId: string;

    /**
    * The list of system settings fields
    */
    // @ApiMember(Description="The list of system settings fields")
    public systemFields: SystemSettingsField[];

    /**
    * The list of customer fields
    */
    // @ApiMember(Description="The list of customer fields")
    public customerFields: CustomerDataField[];

    /**
    * The list of endpoint fields
    */
    // @ApiMember(Description="The list of endpoint fields")
    public endpointFields: EndpointDataField[];

    /**
    * The list of email accounts
    */
    // @ApiMember(Description="The list of email accounts")
    public emailAccounts: EmailAccount[];

    /**
    * The list of tags
    */
    // @ApiMember(Description="The list of tags")
    public tags: Tag[];

    /**
    * Documentation for the account
    */
    // @ApiMember(Description="Documentation for the account")
    public documentation: string;

    /**
    * The number of days recordings are retained
    */
    // @ApiMember(Description="The number of days recordings are retained")
    public recordingRetentionDays?: number;

    /**
    * Enable the SIP refer beta
    */
    // @ApiMember(Description="Enable the SIP refer beta")
    public enableSipRefer?: boolean;

    /**
    * The number of seconds after which we automatically logoff a not ready agent
    */
    // @ApiMember(Description="The number of seconds after which we automatically logoff a not ready agent")
    public autoAgentLogoffSeconds?: number;

    /**
    * The default SIP region for new devices
    */
    // @ApiMember(Description="The default SIP region for new devices")
    public defaultSipRegion?: TwilioSipRegions;

    public constructor(init?: Partial<PatchSystemSettings>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatchSystemSettings'; }
    public getMethod() { return 'PATCH'; }
    public createResponse() { return new SystemSettingsInfo(); }
}

// @Route("/global/settings", "GET")
export class GetGlobalSettings implements IReturn<GlobalSettingsInfo>
{

    public constructor(init?: Partial<GetGlobalSettings>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetGlobalSettings'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GlobalSettingsInfo(); }
}

// @Route("/system/server-events")
export class GetServerEventsInfo
{

    public constructor(init?: Partial<GetServerEventsInfo>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetServerEventsInfo'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

// @Route("/global/settings", "PATCH")
export class PatchGlobalSettings implements IReturn<GlobalSettingsInfo>
{

    public constructor(init?: Partial<PatchGlobalSettings>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatchGlobalSettings'; }
    public getMethod() { return 'PATCH'; }
    public createResponse() { return new GlobalSettingsInfo(); }
}

export class ListLogEntries extends ListRequest<LogEntryInfo> implements IReturn<ListResponse<LogEntryInfo>>
{
    /**
    * The IDs of the account whose log entries you want to retrieve
    */
    // @ApiMember(Description="The IDs of the account whose log entries you want to retrieve")
    public accountIds: string[];

    /**
    * The IDs of the customers whose log entries you want to retrieve
    */
    // @ApiMember(Description="The IDs of the customers whose log entries you want to retrieve")
    public customerIds: string[];

    /**
    * The start date to retrieve usage records for (YYYY-MM-DD)
    */
    // @ApiMember(Description="The start date to retrieve usage records for (YYYY-MM-DD)")
    public startDate: string;

    /**
    * The end date to retrieve logs for (YYYY-MM-DD)
    */
    // @ApiMember(Description="The end date to retrieve logs for (YYYY-MM-DD)")
    public endDate: string;

    /**
    * Search by description
    */
    // @ApiMember(Description="Search by description")
    public description: string;

    public constructor(init?: Partial<ListLogEntries>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'ListLogEntries'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ListResponse<LogEntryInfo>(); }
}

// @Route("/trust-profile")
export class GetTrustProfile implements IReturn<TrustProfile>
{
    public accountId: string;

    public constructor(init?: Partial<GetTrustProfile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTrustProfile'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TrustProfile(); }
}

// @Route("/trust-profile", "POST")
export class UpdateTrustProfile implements IReturn<TrustProfile>
{
    public accountId: string;
    public customerProfileSid: string;
    public cnamProductSid: string;
    public shakenStirProductSid: string;

    public constructor(init?: Partial<UpdateTrustProfile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateTrustProfile'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TrustProfile(); }
}

/**
* Ends the specified Twilio call
*/
// @Route("/twilio/calls/{callSid}", "DELETE")
// @Api(Description="Ends the specified Twilio call")
export class EndTwilioCall implements IReturnVoid, IDelete
{
    /**
    * The account ID that owns the call
    */
    // @ApiMember(Description="The account ID that owns the call")
    public accountId: string;

    /**
    * The call SID of the call
    */
    // @ApiMember(Description="The call SID of the call")
    public callSid: string;

    public constructor(init?: Partial<EndTwilioCall>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EndTwilioCall'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

/**
* Deletes the specified login
*/
// @Route("/users/{userId}", "DELETE")
// @Api(Description="Deletes the specified login")
export class DeleteUser implements IReturnVoid, IDelete
{
    /**
    * The ID of the login to delete
    */
    // @ApiMember(Description="The ID of the login to delete")
    public userId: string;

    public constructor(init?: Partial<DeleteUser>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteUser'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

/**
* Update the specified login
*/
// @Route("/users/{userId}", "PATCH")
// @Api(Description="Update the specified login")
export class PatchUser implements IReturn<UserInfo>, IPatch
{
    /**
    * The account IDs that this user has access to
    */
    // @ApiMember(Description="The account IDs that this user has access to")
    public accountIds: string[];

    /**
    * The ID of the user
    */
    // @ApiMember(Description="The ID of the user")
    public userId: string;

    /**
    * The user's first name
    */
    // @ApiMember(Description="The user's first name")
    public firstName: string;

    /**
    * The user's last name
    */
    // @ApiMember(Description="The user's last name")
    public lastName: string;

    /**
    * The new password for the user (leave null to not change)
    */
    // @ApiMember(Description="The new password for the user (leave null to not change)")
    public newPassword: string;

    /**
    * The roles to associate with the login. Can only specify if system admin
    */
    // @ApiMember(Description="The roles to associate with the login. Can only specify if system admin")
    public roles: string[];

    public constructor(init?: Partial<PatchUser>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatchUser'; }
    public getMethod() { return 'PATCH'; }
    public createResponse() { return new UserInfo(); }
}

/**
* Gets the users for the specified account
*/
// @Route("/users", "GET")
// @Api(Description="Gets the users for the specified account")
export class ListUsers extends ListRequest<UserInfo> implements IReturn<ListResponse<UserInfo>>
{
    /**
    * The account IDs whose users you want to retrieve
    */
    // @ApiMember(Description="The account IDs whose users you want to retrieve")
    public accountIds: string[];

    /**
    * Filter by email
    */
    // @ApiMember(Description="Filter by email")
    public emailFilter: string;

    public constructor(init?: Partial<ListUsers>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'ListUsers'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ListResponse<UserInfo>(); }
}

/**
* Creates a new login with the specified email address and password. Note: Any given email address can only exist once in the entire Evo Voice system.
*/
// @Route("/users", "POST")
// @Api(Description="Creates a new login with the specified email address and password. Note: Any given email address can only exist once in the entire Evo Voice system.")
export class NewUser implements IReturn<UserInfo>, IPost
{
    /**
    * The account ID you want to create this user under
    */
    // @ApiMember(Description="The account ID you want to create this user under")
    public accountId: string;

    /**
    * The email address of the new user
    */
    // @ApiMember(Description="The email address of the new user")
    public emailAddress: string;

    /**
    * The user's first name
    */
    // @ApiMember(Description="The user's first name")
    public firstName: string;

    /**
    * The user's last name
    */
    // @ApiMember(Description="The user's last name")
    public lastName: string;

    /**
    * The password for the new user
    */
    // @ApiMember(Description="The password for the new user")
    public password: string;

    public constructor(init?: Partial<NewUser>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NewUser'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UserInfo(); }
}

// @Route("/nodes/conference/status")
export class ConferenceNodeStatusCallback extends TwilioRequestBase implements IReturn<string>
{
    public sessionId: string;
    public started: string;
    public ended: string;

    public constructor(init?: Partial<ConferenceNodeStatusCallback>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'ConferenceNodeStatusCallback'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return ''; }
}

// @Route("/nodes/conference/wait")
export class ConferenceNodeWaitCallback extends TwilioRequestBase implements IReturn<string>
{
    public audioFile: string;

    public constructor(init?: Partial<ConferenceNodeWaitCallback>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'ConferenceNodeWaitCallback'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return ''; }
}

// @Route("/nodes/dial/ring/callback")
export class DialNodeRingCallback extends TwilioRequestBase implements IReturn<string>
{
    public sessionId: string;

    public constructor(init?: Partial<DialNodeRingCallback>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'DialNodeRingCallback'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return ''; }
}

// @Route("/nodes/dial/ring/answer")
export class DialNodeRingAnswerCallback extends TwilioRequestBase implements IReturn<string>
{
    public sessionId: string;
    public answeringEndpointId: string;

    public constructor(init?: Partial<DialNodeRingAnswerCallback>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'DialNodeRingAnswerCallback'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return ''; }
}

// @Route("/nodes/dial/ring/wait")
export class DialNodeRingWaitCallback extends TwilioRequestBase implements IReturn<string>
{
    public sessionId: string;

    public constructor(init?: Partial<DialNodeRingWaitCallback>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'DialNodeRingWaitCallback'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return ''; }
}

// @Route("/nodes/dial/answer/callback")
export class DialNodeAnswerCallback extends TwilioRequestBase implements IReturn<string>
{
    public sessionId: string;
    public answerUrl: string;

    public constructor(init?: Partial<DialNodeAnswerCallback>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'DialNodeAnswerCallback'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return ''; }
}

// @Route("/nodes/dial/sip/refer")
export class DialNodeSipReferCallback
{
    public sessionId: string;
    public referTransferTarget: string;
    public referToUrl: string;

    public constructor(init?: Partial<DialNodeSipReferCallback>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DialNodeSipReferCallback'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/nodes/dial/number/callback")
export class DialNodeNumberCallback extends TwilioRequestBase implements IReturn<string>
{
    public sessionId: string;
    public recordCall: boolean;
    public recordingReady: string;
    public recordedFile: string;
    public recordedFileUrl: string;
    public recordingSidOutput: string;

    public constructor(init?: Partial<DialNodeNumberCallback>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'DialNodeNumberCallback'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return ''; }
}

// @Route("/nodes/dial/client/callback")
export class DialNodeClientCallback extends TwilioRequestBase implements IReturn<string>
{
    public sessionId: string;
    public identity: string;
    public failed: string;
    public recordCall: boolean;
    public recordingReady: string;
    public recordedFile: string;
    public recordedFileUrl: string;
    public recordingSidOutput: string;

    public constructor(init?: Partial<DialNodeClientCallback>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'DialNodeClientCallback'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return ''; }
}

// @Route("/nodes/dial/callback")
export class DialNodeCallback extends TwilioRequestBase implements IReturn<string>
{
    public noAnswer: string;
    public hangup: string;
    public sessionId: string;
    public version: number;
    public failed: string;
    public busy: string;
    public usageMetricKey: string;
    public timeoutInSeconds: number;

    public constructor(init?: Partial<DialNodeCallback>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'DialNodeCallback'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return ''; }
}

// @Route("/nodes/enqueue/callback")
export class EnqueueNodeCallback extends TwilioRequestBase implements IReturn<string>
{
    public sessionId: string;

    public constructor(init?: Partial<EnqueueNodeCallback>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'EnqueueNodeCallback'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return ''; }
}

// @Route("/nodes/chat-events/new-message")
export class ChatEventsNodeNewMessageCallback
{
    public accountId: string;
    public endpointId: string;
    public sessionId: string;
    public body: string;
    public sender: string;
    public senderDisplayName: string;
    public attachmentUri: string;
    public attachmentContentType: string;
    public newMessage: string;
    public newMessageBody: string;
    public newMessageSender: string;

    public constructor(init?: Partial<ChatEventsNodeNewMessageCallback>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ChatEventsNodeNewMessageCallback'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/chat/endpoint/message")
export class SendEndpointMessage
{
    public sessionId: string;
    public endpointId: string;
    public body: string;
    public attachmentUri: string;
    public attachmentContentType: string;
    public sender: string;
    public displayName: string;

    public constructor(init?: Partial<SendEndpointMessage>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendEndpointMessage'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/nodes/chat-invite/timeout")
export class ChatInviteTimeoutCallback
{
    public sessionId: string;
    public noAnswer: string;

    public constructor(init?: Partial<ChatInviteTimeoutCallback>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ChatInviteTimeoutCallback'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/nodes/say/workflow")
export class SayNodeWorkflowCallback
{
    public done: string;

    public constructor(init?: Partial<SayNodeWorkflowCallback>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SayNodeWorkflowCallback'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/nodes/queue/wait-callback")
export class QueueNodeWaitCallback extends TwilioRequestBase implements IReturn<string>
{
    public waitMusic: QueueWaitMusic[];
    public queueName: string;
    public accountId: string;
    public sessionId: string;
    public recordingReady: string;
    public recordCall: boolean;
    public recordedFile: string;
    public recordedFileUrl: string;

    public constructor(init?: Partial<QueueNodeWaitCallback>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'QueueNodeWaitCallback'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return ''; }
}

// @Route("/nodes/queue/queue-callback")
export class QueueNodeCallback extends TwilioRequestBase implements IReturn<string>
{
    public accountId: string;
    public sessionId: string;
    public queueName: string;

    public constructor(init?: Partial<QueueNodeCallback>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'QueueNodeCallback'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return ''; }
}

// @Route("/nodes/live-answer/callback")
export class LiveAnswerCallback
{
    public flowId: string;
    public nodeId: string;
    public clientId: string;
    public conversationId: string;
    public aiMinutes: number;

    public constructor(init?: Partial<LiveAnswerCallback>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LiveAnswerCallback'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* Sign In
*/
// @Route("/auth", "OPTIONS,GET,POST,DELETE")
// @Route("/auth/{provider}", "OPTIONS,GET,POST,DELETE")
// @Api(Description="Sign In")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    /**
    * AuthProvider, e.g. credentials
    */
    // @DataMember(Order=1)
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe?: boolean;

    // @DataMember(Order=9)
    public errorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=17)
    public accessToken: string;

    // @DataMember(Order=18)
    public accessTokenSecret: string;

    // @DataMember(Order=19)
    public scope: string;

    // @DataMember(Order=20)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Authenticate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AuthenticateResponse(); }
}

